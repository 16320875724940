import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { RouterExtService } from '../../services/router-ext.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorService } from 'src/app/services/error.service';
import { s3Path } from 'src/environments/environment';

@Component({
  selector: 'app-cliente-details',
  templateUrl: './cliente-details.component.html'
})
export class ClienteDetailsComponent implements OnInit {

  currentJustify = 'start';
  client: any;
  editClientForm: FormGroup;
  canEdit = false;
  loadingUpdate = false;
  idClient;
  showImgBtn = false;
  auxFlag = true;
  @ViewChild('tabs') tbs: NgbTabset;
  @ViewChild('modalUploadLogo') public modalUploadLogo: ModalDirective;
  @ViewChild('modalPreview') public modalPreview: ModalDirective;
  logoToUpload: File = null;
  imgSrc = '../../../assets/img/no-image.png';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;

  constructor(private activatedRoute: ActivatedRoute, private clientService: ClientService, private routerExt: RouterExtService,
    private router: Router, private notifierService: NotifierService, private errService: ErrorService) {
    this.editClientForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      contact_name: new FormControl('', Validators.required),
      contact_email: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      timezone: new FormControl('', Validators.required),
      contact_phone: new FormControl('', Validators.required),
      contact_ext: new FormControl('', Validators.required),
      contact_cellphone: new FormControl('', Validators.required),
      primary_color: new FormControl('', Validators.required),
      secondary_color: new FormControl('', Validators.required),
      tertiary_color: new FormControl('', Validators.required),
      text_primary_color: new FormControl('', Validators.required),
      text_secondary_color: new FormControl('', Validators.required),
      text_tertiary_color: new FormControl('', Validators.required)
    });
    const id = this.activatedRoute.snapshot.paramMap.get('id_client');
    this.idClient = id;
    this.getClient(id);
    this.disableForm();
  }

  ngOnInit() {
    const prev = this.routerExt.getPreviousUrl();
    if (prev.indexOf('area') !== -1) {
      setTimeout( () => {
        this.tbs.select('areas');
      }, 20);
    } else if (prev.indexOf('cuestionario') !== -1) {
      setTimeout(() => {
        this.tbs.select('questions');
      }, 20);
    }
  }

  fileChanged(files: FileList) {
    this.logoToUpload = files.item(0);
  }

  backClient() {
    this.router.navigate(['/admin/clientes']);
  }

  setValues() {
    this.editClientForm.controls['name'].setValue(this.client.name);
    this.editClientForm.controls['description'].setValue(this.client.description);
    this.editClientForm.controls['contact_name'].setValue(this.client.contact_name);
    this.editClientForm.controls['contact_email'].setValue(this.client.contact_email);
    this.editClientForm.controls['address'].setValue(this.client.address);
    this.editClientForm.controls['timezone'].setValue(this.client.timezone);
    this.editClientForm.controls['contact_phone'].setValue(this.client.contact_phone);
    this.editClientForm.controls['contact_ext'].setValue(this.client.contact_ext);
    this.editClientForm.controls['contact_cellphone'].setValue(this.client.contact_cellphone);
    this.editClientForm.controls['primary_color'].setValue(this.client.primary_color);
    this.editClientForm.controls['secondary_color'].setValue(this.client.secondary_color);
    this.editClientForm.controls['tertiary_color'].setValue(this.client.tertiary_color);
    this.editClientForm.controls['text_primary_color'].setValue(this.client.text_primary_color);
    this.editClientForm.controls['text_secondary_color'].setValue(this.client.text_secondary_color);
    this.editClientForm.controls['text_tertiary_color'].setValue(this.client.text_tertiary_color);
  }

  showModalLogo() {
    this.modalUploadLogo.show();
  }

  async loadLogo() {
    const data = {
      type: this.logoToUpload.type.replace('image/','')
    }

    const res =  await this.clientService.askForSignedUrl(this.idClient, data);
    
    if (res.success) {

      const res2 = await this.clientService.putToSignedUrl(res.data.upload_url, this.logoToUpload);
      
      this.modalUploadLogo.hide();
      this.getClient(this.idClient);
    } else {
      this.notifierService.notify('error', 'Algo salió mal, intentalo más tarde');
    }
  }

  async getClient(id) {
    const res = await this.clientService.getById(id);
    if (res.success) {
      this.client = res.data.client;
      if (this.client.logo !== "0" && this.client.logo !== 0 && this.client.logo !== null) {
        this.imgSrc = `${s3Path}${this.client.logo}`;
      }
      this.setValues();
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  openPreview() {
    this.modalPreview.show();
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  disableForm() {
    this.editClientForm.controls['name'].disable();
    this.editClientForm.controls['description'].disable();
    this.editClientForm.controls['contact_name'].disable();
    this.editClientForm.controls['contact_email'].disable();
    this.editClientForm.controls['address'].disable();
    this.editClientForm.controls['timezone'].disable();
    this.editClientForm.controls['contact_phone'].disable();
    this.editClientForm.controls['contact_ext'].disable();
    this.editClientForm.controls['contact_cellphone'].disable();
    this.editClientForm.controls['primary_color'].disable();
    this.editClientForm.controls['secondary_color'].disable();
    this.editClientForm.controls['tertiary_color'].disable();
    this.editClientForm.controls['text_primary_color'].disable();
    this.editClientForm.controls['text_secondary_color'].disable();
    this.editClientForm.controls['text_tertiary_color'].disable();
  }

  enableForm() {
    this.editClientForm.controls['name'].enable();
    this.editClientForm.controls['description'].enable();
    this.editClientForm.controls['contact_name'].enable();
    this.editClientForm.controls['contact_email'].enable();
    this.editClientForm.controls['address'].enable();
    this.editClientForm.controls['timezone'].enable();
    this.editClientForm.controls['contact_phone'].enable();
    this.editClientForm.controls['contact_ext'].enable();
    this.editClientForm.controls['contact_cellphone'].enable();
    this.editClientForm.controls['primary_color'].enable();
    this.editClientForm.controls['secondary_color'].enable();
    this.editClientForm.controls['tertiary_color'].enable();
    this.editClientForm.controls['text_primary_color'].enable();
    this.editClientForm.controls['text_secondary_color'].enable();
    this.editClientForm.controls['text_tertiary_color'].enable();
  }

  imgChange() {
    this.showImgBtn = true;
    console.log('PerroEnter', this.auxFlag);
  }

  imgHideChange() {
    if (!this.auxFlag) {
      this.showImgBtn = false;
      console.log('PerroLeave', this.auxFlag);
    }
  }

  btnChange() {
    console.log('Btn Hover');
    this.auxFlag = true;
  }

  btnHideChange() {
    this.auxFlag = false;
  }

  async restartColors() {
    this.loadingUpdate = true;

    const data = {
      primary_color: '#0073E1',
      secondary_color: '#FFF9F5',
      tertiary_color: '#FFF9F5',
      text_primary_color: '#0073E1',
      text_secondary_color: '#000000',
      text_tertiary_color: '#000000',
    }

    const res = await this.clientService.patchById(this.idClient, data);
    this.loadingUpdate = false;
    if (res.success) {
      this.getClient(this.idClient);
      this.setValues();
      this.disableForm();
      this.canEdit = false;
      this.notifierService.notify('success', 'Datos actualizados');
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
  }

  async editClient() {
    this.loadingUpdate = true;
    
    const data = this.getPatchData();

    const res = await this.clientService.patchById(this.idClient, data);
    this.loadingUpdate = false;
    if (res.success) {
      this.getClient(this.idClient);
      this.setValues();
      this.disableForm();
      this.canEdit = false;
      this.notifierService.notify('success', 'Datos actualizados');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  cancelClientEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }

  getPatchData() {
    const data = {};

    if (this.editClientForm.controls['name'].value !== '' && 
        this.editClientForm.controls['name'].value !== this.client.name) {
      data['name'] = this.editClientForm.controls['name'].value;
    }
    if (this.editClientForm.controls['description'].value !== '' && 
        this.editClientForm.controls['description'].value !== this.client.description) {
      data['description'] = this.editClientForm.controls['description'].value;
    }
    if (this.editClientForm.controls['contact_name'].value !== '' && 
        this.editClientForm.controls['contact_name'].value !== this.client.contact_name) {
      data['contact_name'] = this.editClientForm.controls['contact_name'].value;
    }
    if (this.editClientForm.controls['contact_email'].value !== '' && 
        this.editClientForm.controls['contact_email'].value !== this.client.contact_email) {
      data['contact_email'] = this.editClientForm.controls['contact_email'].value;
    }
    if (this.editClientForm.controls['address'].value !== '' &&
      this.editClientForm.controls['address'].value !== this.client.address) {
      data['address'] = this.editClientForm.controls['address'].value;
    }
    if (this.editClientForm.controls['timezone'].value !== '' &&
      this.editClientForm.controls['timezone'].value !== this.client.timezone) {
      data['timezone'] = this.editClientForm.controls['timezone'].value;
    }
    if (this.editClientForm.controls['contact_phone'].value !== '' &&
      this.editClientForm.controls['contact_phone'].value !== this.client.contact_phone) {
      data['contact_phone'] = this.editClientForm.controls['contact_phone'].value;
    }
    if (this.editClientForm.controls['contact_ext'].value !== '' &&
      this.editClientForm.controls['contact_ext'].value !== this.client.contact_ext) {
      data['contact_ext'] = this.editClientForm.controls['contact_ext'].value;
    }
    if (this.editClientForm.controls['contact_cellphone'].value !== '' &&
      this.editClientForm.controls['contact_cellphone'].value !== this.client.contact_cellphone) {
      data['contact_cellphone'] = this.editClientForm.controls['contact_cellphone'].value;
    }
    if (this.editClientForm.controls['primary_color'].value !== '' &&
      this.editClientForm.controls['primary_color'].value !== this.client.primary_color) {
      data['primary_color'] = this.editClientForm.controls['primary_color'].value;
    }
    if (this.editClientForm.controls['secondary_color'].value !== '' &&
      this.editClientForm.controls['secondary_color'].value !== this.client.secondary_color) {
      data['secondary_color'] = this.editClientForm.controls['secondary_color'].value;
    }
    if (this.editClientForm.controls['tertiary_color'].value !== '' &&
      this.editClientForm.controls['tertiary_color'].value !== this.client.tertiary_color) {
      data['tertiary_color'] = this.editClientForm.controls['tertiary_color'].value;
    }
    if (this.editClientForm.controls['text_primary_color'].value !== '' &&
      this.editClientForm.controls['text_primary_color'].value !== this.client.text_primary_color) {
      data['text_primary_color'] = this.editClientForm.controls['text_primary_color'].value;
    }
    if (this.editClientForm.controls['text_secondary_color'].value !== '' &&
      this.editClientForm.controls['text_secondary_color'].value !== this.client.text_secondary_color) {
      data['text_secondary_color'] = this.editClientForm.controls['text_secondary_color'].value;
    }
    if (this.editClientForm.controls['text_tertiary_color'].value !== '' &&
      this.editClientForm.controls['text_tertiary_color'].value !== this.client.text_tertiary_color) {
      data['text_tertiary_color'] = this.editClientForm.controls['text_tertiary_color'].value;
    }

    return data;
  }

}
