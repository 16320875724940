import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-cliente',
  templateUrl: './clientes.component.html'
})
export class ClientesComponent implements OnInit {

  clients = [];
  showSpinner = false;
  newClientForm: FormGroup;
  @ViewChild('modalClientNew') public modalClientNew: ModalDirective;
  @ViewChild('modalClientDelete') public modalClientDelete: ModalDirective;
  deleteClient = {
    name: '',
    id: 0
  };
  loadingDelete = false;
  loadingNew = false;
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;
  search_text = "";
  filter_number = 1;

  constructor(private clientService: ClientService, private router: Router, private notifierService: NotifierService, private errService: ErrorService) {
    this.newClientForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      contact_name: new FormControl('', Validators.required),
      contact_email: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    sessionStorage.removeItem('search_employee');
    let searchObject = JSON.parse(sessionStorage.getItem('search_client'));
    if (searchObject !== null) {
      this.offset = parseInt(searchObject.offset);
      this.limit = parseInt(searchObject.limit);
      this.limitUp = parseInt(searchObject.lmt_up);
      this.limitDown = parseInt(searchObject.lmt_down);
      this.search_text = searchObject.search_text;
      this.filter_number = parseInt(searchObject.filter_number);
      this.makeSearch(2);
    } else {
      this.getClients(1);
    }
  }

  async getClients(type) {
    this.clients = [];
    this.showSpinner = true;
    const res = await this.clientService.getAll(this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.clients = res.data.clients;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getClientsByName(type) {
    this.clients = [];
    this.showSpinner = true;
    if (type === 3) {
      this.offset = 0;
    }
    const res = await this.clientService.getByName(this.search_text, this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.clients = res.data.clients;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
      if(type === 3){
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
        this.limitDown = 1;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  clientDetails(id) {
    sessionStorage.setItem('search_client', JSON.stringify({
      offset: this.offset,
      limit: this.limit,
      lmt_up: this.limitUp,
      lmt_down: this.limitDown,
      search_text: this.search_text,
      filter_number: this.filter_number
    }));
    this.router.navigate(['/admin/clientes/', id]);
  }

  openDeleteClientModal(id, name) {
    this.deleteClient.id = id;
    this.deleteClient.name = `${name}`;
    this.modalClientDelete.show();
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.makeSearch(1);
  }

  makeSearchText() {
    if (this.search_text !== "") {
      this.getClientsByName(3);
    } else {
      this.getClients(1);
    }
  }

  clearTextInput() {
    this.search_text = "";
    if (this.filter_number === 1) {
      this.getClients(1);
    }
  }

  nextPage() {
    this.offset += this.limit;
    this.makeSearch(2);
    this.limitDown += this.limit;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
  }

  previusPage() {
    this.offset -= this.limit;
    this.makeSearch(2);
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
  }

  makeSearch(type) {
    switch (this.filter_number) {
      case 1:
        this.getClients(type);
        break;
      case 2:
        this.getClientsByName(type);
        break;
    };
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  async removeClient() {
    this.loadingDelete = true;
    const res = await this.clientService.deleteById(this.deleteClient.id);
    if (res.success) {
      this.offset = 0;
      this.getClients(1);
      this.notifierService.notify('success', 'Cliente eliminado');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalClientDelete.hide();
    this.loadingDelete = false;
  }

  showNewClientModal() {
    this.clearValues();
    this.modalClientNew.show();
  }

  clearValues() {
    this.newClientForm.controls.name.setValue('');
    this.newClientForm.controls.description.setValue('');
    this.newClientForm.controls.contact_name.setValue('');
    this.newClientForm.controls.contact_email.setValue('');
  }

  async addClient() {
    this.loadingNew = true;

    const data = this.getPostData();
    
    const res = await this.clientService.post(data);
    if (res.success) {
      this.offset = 0;
      this.getClients(1);
      this.notifierService.notify('success', 'Cliente añadido');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalClientNew.hide();
    this.loadingNew = false;
  }

  getPostData() {
    const data = {
      name: this.newClientForm.controls['name'].value
    };

    if (this.newClientForm.controls['description'].value !== '') {
      data['description'] = this.newClientForm.controls['description'].value;
    }
    if (this.newClientForm.controls['contact_name'].value !== '') {
      data['contact_name'] = this.newClientForm.controls['contact_name'].value;
    }
    if (this.newClientForm.controls['contact_email'].value !== '') {
      data['contact_email'] = this.newClientForm.controls['contact_email'].value;
    }

    return data;
  }

}
