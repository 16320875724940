import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ApplicationBusinessService } from '../../services/application-business.service';
import { BusinessService } from '../../services/business.service';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { AuthService } from 'src/app/services/auth.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
  selector: 'app-application-business',
  templateUrl: './application-business.component.html'
})
export class ApplicationBusinessComponent implements OnInit {

  @Input() idQuestionnaireApplication;
  idClient;
  application_businesses = [];
  filtered_application_businesses = [];
  showSpinner = false;
  aux_user: any;
  isEmpty: boolean = false;
  newApplicationBusinessForm: FormGroup;
  @ViewChild('modalApplicationBusinessNew') public modalApplicationBusinessNew: ModalDirective;
  @ViewChild('modalApplicationBusinessDelete') public modalApplicationBusinessDelete: ModalDirective;
  deleteApplicationBusiness = {
    name: '',
    id: 0
  };
  loadingDelete = false;
  loadingNew = false;
  businesses = [];
  businesses_form = [];
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;

  constructor(private appBusinessService: ApplicationBusinessService, private router: Router, private notifierService: NotifierService,
              private businessService: BusinessService, private questionarioService: QuestionnaireService, private fb: FormBuilder,
              private authService: AuthService) {
    this.newApplicationBusinessForm = this.fb.group({
      app_businesses: this.fb.array([])
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.getApplicationBusinesses(1);
    this.setIdClient();
  }

  async getApplicationBusinesses(type) {
    this.application_businesses = [];
    this.showSpinner = true;
    const res = await this.appBusinessService.getByQuestionnaireApplication(this.idQuestionnaireApplication, 20, 0);
    this.showSpinner = false;
    if (res.success) {
      this.application_businesses = res.data.application_applicationbusinesses;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
      this.filtered_application_businesses = [];
      for (var j = this.limitDown - 1; j < this.limitUp; j++) {
        this.filtered_application_businesses.push(this.application_businesses[j]);
      }
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
  }

  async setIdClient() {
    const res = await this.questionarioService.getById(this.idQuestionnaireApplication);
    if (res.success) {
      this.idClient = res.data.questionnaire_application.client_id;
      this.getBusinesses();
    }
  }

  async getBusinesses() {
    const res = await this.businessService.getByClientCatalog(this.idClient);
    if (res.success) {
      this.businesses = res.data.businesses;
    }
  }

  openDeleteApplicationBusinessModal(id, name) {
    this.deleteApplicationBusiness.id = id;
    this.deleteApplicationBusiness.name = `${name}`;
    this.modalApplicationBusinessDelete.show();
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.limitUp = this.limit >= this.count ? this.count : this.limit;
    this.filtered_application_businesses = [];
    for (var j = this.limitDown - 1; j < this.limitUp; j++) {
      this.filtered_application_businesses.push(this.application_businesses[j]);
    }
  }

  nextPage() {
    this.offset += this.limit;
    this.limitDown += this.limit;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
    this.filtered_application_businesses = [];
    for (var j = this.limitDown - 1; j < this.limitUp; j++) {
      this.filtered_application_businesses.push(this.application_businesses[j]);
    }
  }

  previusPage() {
    this.offset -= this.limit;
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
    this.filtered_application_businesses = [];
    for (var j = this.limitDown - 1; j < this.limitUp; j++) {
      this.filtered_application_businesses.push(this.application_businesses[j]);
    }
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  async removeApplicationBusiness() {
    this.loadingDelete = true;
    const res = await this.appBusinessService.deleteById(this.deleteApplicationBusiness.id);
    if (res.success) {
      this.offset = 0;
      this.limitDown = 1;
      this.limitUp = this.limit 
      this.getApplicationBusinesses(1);
      this.notifierService.notify('success', 'Centros de trabajo de Aplicación eliminado');
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
    this.modalApplicationBusinessDelete.hide();
    this.loadingDelete = false;
  }

  showNewApplicationBusinessModal() {
    this.clearSelectBusinesses();
    this.businesses_form = this.getDiffBusinesses();
    if(this.businesses_form.length === 0){
      this.isEmpty = true;
    }
    this.businesses_form.forEach( () => {
      (<FormArray>this.newApplicationBusinessForm.controls['app_businesses']).push(new FormControl(false));
    });
    this.modalApplicationBusinessNew.show();
  }

  async addApplicationBusinesses() {
    this.loadingNew = true;
    let err = 0;

    const selectedOrderIds = this.newApplicationBusinessForm.value.app_businesses
      .map((v, i) => v ? this.businesses_form[i].id_business : null)
      .filter(v => v !== null);

    for (let selected of selectedOrderIds) {
      let data = {
        questionnaire_application_id: this.idQuestionnaireApplication,
        business_id: selected,
        target_answers: 0,
        current_answers: 0,
      };
      const res = await this.appBusinessService.post(data);
      if (!res.success) {
        err++;
      }
    }

    if (err === 0) {
      this.offset = 0;
      this.limitDown = 1;
      this.limitUp = this.limit;
      this.getApplicationBusinesses(1);
      this.notifierService.notify('success', 'Centros de trabajo de Aplicación añadido');
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }

    this.modalApplicationBusinessNew.hide();
    this.loadingNew = false;
  }

  clearSelectBusinesses() {
    while ((<FormArray>this.newApplicationBusinessForm.controls['app_businesses']).length !== 0) {
      (<FormArray>this.newApplicationBusinessForm.controls['app_businesses']).removeAt(0)
    }
  }

  getDiffBusinesses() {
    return this.businesses.filter((business) => {
      if (this.application_businesses.map((ap) => ap.business_id).indexOf(business.id_business) === -1) {
        return business;
      }
    });
  }

  clearValues() {
    this.newApplicationBusinessForm.controls.business_id.setValue('');
    this.newApplicationBusinessForm.controls.target_answers.setValue('');
    this.newApplicationBusinessForm.controls.current_answers.setValue('');
  }


}
