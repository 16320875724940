import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('cn035');
        const exp = localStorage.getItem('cn035_exp');
        let flag = 0;
        
        if (request.url.indexOf('/answer/') === -1 && request.url.indexOf('s3') === -1 ) {
            if (token && exp) {
                // console.log('Before if',flag)
                let now = new Date();
                let up_limit = new Date(parseInt(exp) * 1000);
                let down_limit = new Date(parseInt(exp) * 1000 - 600000);
                if (request.url.indexOf('reauthenticate') === -1 && flag === 0 &&
                    (up_limit > now && now >= down_limit)) {
                        flag = 1;
                        // console.log('Inside if', flag)
                        this.auth.reauth().subscribe( res => {
                            localStorage.setItem('cn035', res.data.token);
                            localStorage.setItem('cn035_exp', res.data.exp);
                            flag = 0;
                            // console.log('After if', flag)
                        });
                } else if (request.url.indexOf('login') === -1 && (now > up_limit)) {
                    this.router.navigate(['']);
                }
            }
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        } else if (request.url.indexOf('s3') === -1) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        return next.handle(request);
    }
}
