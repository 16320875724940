import { Routes } from '@angular/router';
import { WelcomeQuestionnaireComponent } from '../welcome-questionnaire/welcome-questionnaire.component';
import { SDQformComponent } from '../sdqform/sdqform.component';
import { ValidateCredentialesComponent } from '../validate-credentiales/validate-credentiales.component';
import { Q1Component } from '../q1/q1.component';
import { Q2Component } from '../q2/q2.component';
import { Q3Component } from '../q3/q3.component';
import { QuestaionnaireGuard } from '../../../guards/questionnaire.guard ';
import { ManualQuestionnaireComponent } from '../manual-questionnaire/manual-questionnaire.component';

export const QUESTIONNAIRE_ROUTES: Routes = [
    { path: '', component: WelcomeQuestionnaireComponent },
    { path: 'welcome', component: WelcomeQuestionnaireComponent },
    { path: 'manual', component: ManualQuestionnaireComponent },
    { path: 'access', component: ValidateCredentialesComponent },
    { path: 'data', component: SDQformComponent },
    { path: 'q1', component: Q1Component },
    { path: 'q2', component: Q2Component },
    { path: 'q3', component: Q3Component },
    { path: 'q4', component: Q1Component },
    { path: 'q5', component: Q1Component },
    { path: '**', component: WelcomeQuestionnaireComponent , canActivate: [QuestaionnaireGuard]  }
];
