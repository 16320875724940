import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AnswerService } from 'src/app/services/answer.service';
import { s3Path } from 'src/environments/environment';

@Component({
  selector: 'app-questonnaire-navbar',
  templateUrl: './questonnaire-navbar.component.html',
  styleUrls: ['./questonnaire-navbar.component.css']
})

export class QuestonnaireNavbarComponent implements OnInit {
  clientDataListener$: Observable<any>;
  loadingClientDataListener$ : Observable<any>;
  logoPath: string;
  navBg: string;
  isClientLogo: boolean;
  loadingDelay: boolean;
  @Input() set bgcolor(value: string) {
    this.navBg = value;
  };

  constructor(private answerService: AnswerService) {
    this.logoPath = 'assets/img/Cuentame_Blanco_horizontal.png';
    this.isClientLogo = false;
    this.loadingDelay = true;
  }

  ngOnInit() {
    this.clientDataListener$ = this.answerService.clientDataListener;
    this.clientDataListener$.subscribe(client => {
      if(client.logo) {
        this.logoPath = `${s3Path}${client.logo}`;
        this.isClientLogo = true;
      }
      if (client.primary_color) {
        this.navBg = client.primary_color;
      }
    })
    this.loadingClientDataListener$ = this.answerService.loadingClientDataListener;
    this.loadingClientDataListener$.subscribe((data) => {
      setTimeout(() => {
        this.loadingDelay = data;
      }, 700);
    });
  }

}
