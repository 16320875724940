import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class QuestaionnaireGuard implements CanActivate {
    constructor(private router: Router, public location: Location) { }

    canActivate() {
        let Urlobjects = this.location.path().split('/');
        if(Urlobjects.length == 3){
            sessionStorage.setItem('ApplicationUrl',JSON.stringify(Urlobjects[Urlobjects.length-1]));
            return true;
        }else{
            return false;
        }
    }
}
