import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient) { }

  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cn035')
      })
    };
  }

  getByClientCatalog(id) {
    return this.http.get(`${apiUrl}/business/client/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getByClient(id, limit, offset) {
    return this.http.get(`${apiUrl}/business/client/${id}?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getById(id) {
    return this.http.get(`${apiUrl}/business/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  patchById(id, data) {
    return this.http.patch(`${apiUrl}/business/${id}`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  deleteById(id) {
    return this.http.delete(`${apiUrl}/business/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  post(data) {
    return this.http.post(`${apiUrl}/business/`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }
}
