import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { RouterExtService } from 'src/app/services/router-ext.service';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { ErrorService } from 'src/app/services/error.service';
import { s3Path } from 'src/environments/environment';

@Component({
  selector: 'app-home-cliente',
  templateUrl: './home-cliente.component.html'
})
export class HomeClienteComponent implements OnInit {

  client: any;
  aux_user: any;
  editClientForm: FormGroup;
  canEdit = false;
  loadingUpdate = false;
  idClient;
  showImgBtn = false;
  auxFlag = true;
  @ViewChild('modalUploadLogo') public modalUploadLogo: ModalDirective;
  @ViewChild('modalPreview') public modalPreview: ModalDirective;
  logoToUpload: File = null;
  imgSrc = '../../../assets/img/no-image.png';

  constructor(private clientService: ClientService, private notifierService: NotifierService, private errService: ErrorService) { 
    this.editClientForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      contact_name: new FormControl('', Validators.required),
      contact_email: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      timezone: new FormControl('', Validators.required),
      contact_phone: new FormControl('', Validators.required),
      contact_ext: new FormControl('', Validators.required),
      contact_cellphone: new FormControl('', Validators.required),
      primary_color: new FormControl('', Validators.required),
      secondary_color: new FormControl('', Validators.required),
      tertiary_color: new FormControl('', Validators.required),
      text_primary_color: new FormControl('', Validators.required),
      text_secondary_color: new FormControl('', Validators.required),
      text_tertiary_color: new FormControl('', Validators.required)
    });
    this.getClient();
    this.disableForm();
  }

  ngOnInit() {
    sessionStorage.clear();
  }

  setValues() {
    this.editClientForm.controls['name'].setValue(this.client.name);
    this.editClientForm.controls['description'].setValue(this.client.description);
    this.editClientForm.controls['contact_name'].setValue(this.client.contact_name);
    this.editClientForm.controls['contact_email'].setValue(this.client.contact_email);
    this.editClientForm.controls['address'].setValue(this.client.address);
    this.editClientForm.controls['timezone'].setValue(this.client.timezone);
    this.editClientForm.controls['contact_phone'].setValue(this.client.contact_phone);
    this.editClientForm.controls['contact_ext'].setValue(this.client.contact_ext);
    this.editClientForm.controls['contact_cellphone'].setValue(this.client.contact_cellphone);
    this.editClientForm.controls['primary_color'].setValue(this.client.primary_color);
    this.editClientForm.controls['secondary_color'].setValue(this.client.secondary_color);
    this.editClientForm.controls['tertiary_color'].setValue(this.client.tertiary_color);
    this.editClientForm.controls['text_primary_color'].setValue(this.client.text_primary_color);
    this.editClientForm.controls['text_secondary_color'].setValue(this.client.text_secondary_color);
    this.editClientForm.controls['text_tertiary_color'].setValue(this.client.text_tertiary_color);
  }
  async getClient() {
    const res = await this.clientService.getMe();
    if (res.success) {
      this.client = res.data.client;
      if (this.client.logo !== "0" && this.client.logo !== 0 && this.client.logo !== null) {
        this.imgSrc = `${s3Path}${this.client.logo}`;
      }
      this.setValues();
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }
  openPreview() {
    this.modalPreview.show();
  }
  disableForm() {
    this.editClientForm.controls['name'].disable();
    this.editClientForm.controls['description'].disable();
    this.editClientForm.controls['contact_name'].disable();
    this.editClientForm.controls['contact_email'].disable();
    this.editClientForm.controls['address'].disable();
    this.editClientForm.controls['timezone'].disable();
    this.editClientForm.controls['contact_phone'].disable();
    this.editClientForm.controls['contact_ext'].disable();
    this.editClientForm.controls['contact_cellphone'].disable();
    this.editClientForm.controls['primary_color'].disable();
    this.editClientForm.controls['secondary_color'].disable();
    this.editClientForm.controls['tertiary_color'].disable();
    this.editClientForm.controls['text_primary_color'].disable();
    this.editClientForm.controls['text_secondary_color'].disable();
    this.editClientForm.controls['text_tertiary_color'].disable();
  }

}
