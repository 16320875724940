import { Component, OnInit } from '@angular/core';
import { RouterExtService } from '../../services/router-ext.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  usuario: any;
  canEdit = false;
  loadingUpdate = false;

  constructor(private router: Router, private routerExt: RouterExtService, private userService: UsuarioService,
    private notifierService: NotifierService, private authService: AuthService, private errService: ErrorService) {
    this.profileForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      password1: new FormControl(''),
      password2: new FormControl(''),
      client: new FormControl(''),
      user_type: new FormControl(''),
    });
  }

  ngOnInit() {
    this.disableForm();
    this.getUserMe();
  }

  backButton() {
    if (this.routerExt.getPreviousUrl().indexOf('perfil') !== -1) {
      if (this.authService.isAdmin()) {
        this.router.navigate(['/admin/clientes']);
      } else {
        this.router.navigate(['/client/home']);
      }
    } else {
      this.router.navigate([this.routerExt.getPreviousUrl()]);
    }
  }

  async getUserMe() {
    const res = await this.userService.getMe();
    if (res.success) {
      this.usuario = res.data.user;
      this.setValues();
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  setValues() {
    this.profileForm.controls['first_name'].setValue(this.usuario.first_name);
    this.profileForm.controls['last_name'].setValue(this.usuario.last_name);
    this.profileForm.controls['phone'].setValue(this.usuario.phone);
    this.profileForm.controls['email'].setValue(this.usuario.email);
    this.profileForm.controls['client'].setValue(this.usuario.client);
    this.profileForm.controls['user_type'].setValue(this.usuario.user_type);
    this.profileForm.controls['password1'].setValue('');
    this.profileForm.controls['password2'].setValue('');
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  disableForm() {
    this.profileForm.controls['first_name'].disable();
    this.profileForm.controls['last_name'].disable();
    this.profileForm.controls['phone'].disable();
    this.profileForm.controls['email'].disable();
    this.profileForm.controls['password1'].disable();
    this.profileForm.controls['password2'].disable();
    this.profileForm.controls['client'].disable();
    this.profileForm.controls['user_type'].disable();
  }

  enableForm() {
    this.profileForm.controls['first_name'].enable();
    this.profileForm.controls['last_name'].enable();
    this.profileForm.controls['phone'].enable();
    this.profileForm.controls['email'].enable();
    this.profileForm.controls['password1'].enable();
    this.profileForm.controls['password2'].enable();
  }

  async editMe() {
    this.loadingUpdate = true;
    const data = {
      first_name: this.profileForm.controls['first_name'].value,
      last_name: this.profileForm.controls['last_name'].value,
      phone: this.profileForm.controls['phone'].value,
      email: this.profileForm.controls['email'].value,
    };

    const pass = this.validatePassword();
    let err = 0;

    if (pass !== 2) {
      if (pass === 3) {
        data['password'] = this.profileForm.controls['password1'].value;
      } else {
        this.notifierService.notify('error', 'La contraseña no coincide');
        this.loadingUpdate = false;
        err = 1;
      }
    }

    if (err === 0) {
      const res = await this.userService.patchMe(data);
      this.loadingUpdate = false;
      if (res.success) {
        this.getUserMe();
        this.setValues();
        this.disableForm();
        this.canEdit = false;
        this.notifierService.notify('success', 'Datos actualizados');
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    }
  }

  validatePassword() {
    if (this.profileForm.controls['password1'].value === this.profileForm.controls['password2'].value) {
      if (this.profileForm.controls['password1'].value.length !== 0 && this.profileForm.controls['password2'].value.length !== 0) {
        return 3;
      } else {
        return 2;
      }
    } else {
      return 1;
    }
  }

  cancelEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }

}
