import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { AdminGuard } from './guards/admin.guard';
import { ADMIN_ROUTES } from './components/main/admin.routes';
import { ClientGuard } from './guards/client.guard';
import { CLIENT_ROUTES } from './components/main/client.routes';
import { HomeQuestionnaireComponent } from './components/questionnaires/home-questionnaire/home-questionnaire.component';
import { QUESTIONNAIRE_ROUTES } from './components/questionnaires/home-questionnaire/questionnaire.routes';
import { QuestaionnaireGuard } from './guards/questionnaire.guard ';


const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'questionnaire', component: HomeQuestionnaireComponent, children: QUESTIONNAIRE_ROUTES },
    { path: 'admin', component: MainComponent, canActivate: [AdminGuard], children: ADMIN_ROUTES },
    { path: 'client', component: MainComponent, canActivate: [ClientGuard], children: CLIENT_ROUTES },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];

export const APP_ROUTING = RouterModule.forRoot(routes);
