import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidevar',
  templateUrl: './sidevar.component.html'
})
export class SidevarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  emailUser = '';
  nameUser = '';
  isAdmin;

  constructor(private auth: AuthService, private router: Router) { }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  ngOnInit() {
    this.isAdmin = this.auth.isAdmin();
    const usr = this.auth.getUser();
    this.emailUser = usr.email;
    const aux_name = `${usr.first_name} ${usr.last_name}`;
    if (aux_name.length > 20) {
      this.nameUser = aux_name.slice(0, aux_name.lastIndexOf(' '));
    } else {
      this.nameUser = aux_name;
    }
  }

  logout() {
    this.auth.logout();
  }

  profile() {
    if (this.auth.isAdmin()) {
      this.router.navigate(['/admin/perfil']);
    } else {
      this.router.navigate(['/client/perfil']);
    }
  }

}
