import { Component, OnInit, Input } from '@angular/core';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { NotifierService } from 'angular-notifier';
import { QuestionnaireResultService } from '../../services/questionnaire-result.service';
import { ToExcelService } from '../../services/to-excel.service';
import { ApplicationAreaService } from 'src/app/services/application-area.service';
import { ApplicationBusinessService } from 'src/app/services/application-business.service';

@Component({
  selector: 'app-questionario-results',
  templateUrl: './questionario-results.component.html'
})
export class QuestionarioResultsComponent implements OnInit {

  @Input() idQuestionnaireApplication;
  q1ShowResult = false;
  q2ShowResult = false;
  q3ShowResult = false;
  q4ShowResult = false;
  q5ShowResult = false;
  loadingQresult = false;
  qSD: any;
  areaFilter = 'all';
  mainFilter = 'main';
  businessFilter = 'all';
  numQSD = 1;
  q1Results = [];
  q23Results = [];
  application_areas = [];
  application_businesses = [];
  qType = 0;
  showSpinner = true;
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;

  constructor(private questionnarieService: QuestionnaireService, private notifierService: NotifierService, private appAreaService: ApplicationAreaService,
              private questionnaireResultService: QuestionnaireResultService, private toExcelService: ToExcelService, private appBusinessService: ApplicationBusinessService) { }

  ngOnInit() {
    this.getQuestionnaireType(1);
  }

  async getQuestionnaireType(type) {
    const res = await this.questionnarieService.getById(this.idQuestionnaireApplication);
    if ( res.success ) {
      // Sociodemographic Data
      this.getSocioDemographicData();
      this.qType = res.data.questionnaire_application.questionnaire_id;
      switch (this.qType) {
        case 1:
          this.q1ShowResult = true;
          this.getQ1Results(type, 'q1');
          break;
        case 2:
          this.q2ShowResult = true;
          this.getApplicationAreas();
          this.getQResults();
          break;
        case 3:
          this.q3ShowResult = true;
          this.getApplicationAreas();
          this.getQResults();
          break;
        case 4:
          this.q4ShowResult = true;
          this.getQ1Results(type, 'q4');
          this.getApplicationAreas();
          this.getQResults();
          break;
        case 5:
          this.q5ShowResult = true;
          this.getQ1Results(type, 'q5');
          this.getApplicationAreas();
          this.getQResults();
          break;
        default:
          this.notifierService.notify('error', 'Algo salió mal 2');
      }
    } else {
      this.notifierService.notify('error', 'Algo salió mal 1');
    }
  }

  onTabChange(event) {
    if (event.nextId === 'ats') {
      this.getQ1Results(1, `q${this.qType}`);
    } 
    if (event.nextId === 'frps') {
      this.getApplicationAreas();
      this.getQResults();
    } 
  }

  async getSocioDemographicData() {
    const res = await this.questionnarieService.getSocioDemographics(this.idQuestionnaireApplication);
    if ( res.success ) {
      this.qSD = res.data.application_sociodemographic;
      this.getTotalQSD();
    } else {
      this.notifierService.notify('error', 'No se pudo obtener datos sociodemográficos');
    }
  }

  async getQ1Results(type, qType: string = 'q1') {
    const res = await this.questionnaireResultService.getQ1Results(this.idQuestionnaireApplication, this.limit, this.offset, qType);
    this.showSpinner = false;
    if ( res.success ) {
      this.q1Results = res.data.employees;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', 'No se encontraron resultados');
    }
  }

  async getApplicationAreas() {
    this.application_areas = [];
    const res = await this.appAreaService.getByQuestionnaireApplication(this.idQuestionnaireApplication, null, null);
    if (res.success) {
      this.application_areas = [{area_id: 'all', area: 'Todas'},...res.data.application_applicationareas];
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
  }

  async getQResults() {
    let urlString = '';
    if (this.mainFilter === 'areas') {
      urlString = 'areas';
    } else if (this.areaFilter === 'all') {
      urlString = `general/${this.mainFilter === 'main' ? '' : this.mainFilter}`;
    } else {
      urlString = `area/${this.areaFilter}/${this.mainFilter === 'main' ? '' : this.mainFilter}`;
    }
    this.loadingQresult = true;
    let qType = 2;
    if(this.q3ShowResult) {
      qType = 3
    }
    if(this.q4ShowResult) {
      qType = 4
    }
    if(this.q5ShowResult) {
      qType = 5
    }
    const res = await this.questionnaireResultService.getQResults(this.idQuestionnaireApplication, qType, urlString);
    if (res.success) {
      this.showSpinner = false;
      this.loadingQresult = false;
      this.q23Results = res.data.questionnaire_results;
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
  }

  donwloadQ23Results() {

    // console.log(this.q23Results);

    let array_data = [];

    for (let result of this.q23Results) {

      let data = {};
      
      if (result.area) {
        data['Area'] = result.area;
      }

      if (result.business) {
        data['Centro de Trabajo'] = result.business !== null ? result.business : 'ninguno';
      }
      
      if (result.gender) {
        data['Sexo'] = result.gender;
      }

      if (result.age) {
        data['Edad'] = result.age;
      }

      if (result.civil_status) {
        data['Estado Civil'] = result.civil_status;
      }

      if (result.education_level) {
        data['Nivel de Estudios'] = result.education_level;
      }

      if (result.position_type) {
        data['Tipo de Puesto'] = result.position_type;
      }

      if (result.recruitment_type) {
        data['Tipo de Contratación'] = result.recruitment_type;
      }
      
      if (result.current_position_time) {
        data['Tiempo en puesto actual'] = result.current_position_time;
      }

      if (result.employee_type) {
        data['Tipo de Personal'] = result.employee_type;
      }

      if (result.working_hours_type) {
        data['Jornada de Trabajo'] = result.working_hours_type;
      }

      if (result.working_hours_rotation) {
        data['Rotación de Horario'] = result.working_hours_rotation;
      }

      if (result.work_experience) {
        data['Experiencia laboral'] = result.work_experience;
      }

      if (result.state) {
        data['Estado'] = result.state;
      }      

      data['Calificación final'] = result.final_risk_score
      data['Nivel de riesgo final'] = this.evaluateFinal(result.final_risk_score);
      data['Calificación de Ambiente de trabajo'] = result.cat_work_ambient_score;
      data['Nivel de riesgo en Ambiente de trabajo'] = this.evaluateCatWorkAmbient(result.cat_work_ambient_score);
      data['Calificación de Factores propios de la actividad'] = result.cat_activity_factors_score;
      data['Nivel de riesgo en Factores propios de la actividad'] = this.evaluateCatWorkActivityF(result.cat_activity_factors_score);
      data['Calificación de Organización del tiempo de trabajo'] = result.cat_work_time_score;
      data['Nivel de riesgo en Organización del tiempo de trabajo'] = this.evaluateCatWorkTime(result.cat_work_time_score);
      data['Calificación de Liderazgo y relaciones en el trabajo'] = result.cat_work_relations_score;
      data['Nivel de riesgo en Liderazgo y relaciones en el trabajo'] = this.evaluateCatWorkRelations(result.cat_work_relations_score);
      if (this.qType === 3) {
        data['Calificación de Entorno organizacional'] = result.cat_organization_score;
        data['Nivel de riesgo en Entorno organizacional'] = this.evaluateCatOrganization(result.cat_organization_score);
      }
      data['Calificación de Condiciones en el ambiente de trabajo'] = result.dom_work_conditions_score;
      data['Nivel de riesgo en Condiciones en el ambiente de trabajo'] = this.evaluateDomWorkConditions(result.dom_work_conditions_score);
      data['Calificación de Carga de trabajo'] = result.dom_work_load_score;
      data['Nivel de riesgo en Carga de trabajo'] = this.evaluateDomWorkLoad(result.dom_work_load_score);
      data['Calificación de Falta de control sobre el trabajo'] = result.dom_missing_control_score;
      data['Nivel de riesgo en Falta de control sobre el trabajo'] = this.evaluateDomMissingControl(result.dom_missing_control_score);
      data['Calificación de Jornada de trabajo'] = result.dom_work_hours_score;
      data['Nivel de riesgo en Jornada de trabajo'] = this.evaluateDomWorkHours(result.dom_work_hours_score);
    	data['Calificación de Interferencia en la relación trabajo-familia'] = result.dom_family_score;
      data['Nivel de riesgo en Interferencia en la relación trabajo-familia'] = this.evaluateDomFamily(result.dom_family_score);
      data['Calificación de Liderazgo'] = result.dom_leadership_score;
      data['Nivel de riesgo en Liderazgo'] = this.evaluateDomLeadership(result.dom_leadership_score);
      data['Calificación de Relaciones en el trabajo'] = result.dom_work_relations_score;
      data['Nivel de riesgo en Relaciones en el trabajo'] = this.evaluateDomWorkRelations(result.dom_work_relations_score);
      data['Calificación de Violencia'] = result.dom_violence_score;
      data['Nivel de riesgo en Violencia'] = this.evaluateDomViolence(result.dom_violence_score);
      if (this.qType === 3) {
        data['Calificación de Reconocimiento del desempeño'] = result.dom_recognition_score;
        data['Nivel de riesgo en Reconocimiento del desempeño'] = this.evaluateDomRecognitions(result.dom_recognition_score);
        data['Calificación de Insuficiente sentido de pertenencia e, inestabilidad'] = result.dom_instability_score;
        data['Nivel de riesgo en Insuficiente sentido de pertenencia e, inestabilidad'] = this.evaluateDomInstability(result.dom_instability_score);
      } 
      
      array_data.push(data);

    }

    // console.log('Final', array_data);
    
    this.toExcelService.exportAsExcelFile(array_data, 'resultados');
  }

  evaluateFinal(score: Number) {
    if (this.qType== 2) {
      if (score < 20) {
        return 'Nulo';
      } else if (score < 45) {
        return 'Bajo';
      } else if (score < 70) {
        return 'Medio';
      } else if (score < 90) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 50) {
        return 'Nulo';
      } else if (score < 75) {
        return 'Bajo';
      } else if (score < 99) {
        return 'Medio';
      } else if (score < 140) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkAmbient(score: Number) {
    if (this.qType== 2) {
      if (score < 3) {
        return 'Nulo';
      } else if (score < 5) {
        return 'Bajo';
      } else if (score < 7) {
        return 'Medio';
      } else if (score < 9) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 9) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkActivityF(score: Number) {
    if (this.qType== 2) {
      if (score < 10) {
        return 'Nulo';
      } else if (score < 20) {
        return 'Bajo';
      } else if (score < 30) {
        return 'Medio';
      } else if (score < 40) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 15) {
        return 'Nulo';
      } else if (score < 30) {
        return 'Bajo';
      } else if (score < 45) {
        return 'Medio';
      } else if (score < 60) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkTime(score: Number) {
    if (this.qType== 2) {
      if (score < 4) {
        return 'Nulo';
      } else if (score < 6) {
        return 'Bajo';
      } else if (score < 9) {
        return 'Medio';
      } else if (score < 12) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 7) {
        return 'Bajo';
      } else if (score < 10) {
        return 'Medio';
      } else if (score < 13) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkRelations(score: Number) {
    if (this.qType== 2) {
      if (score < 10) {
        return 'Nulo';
      } else if (score < 18) {
        return 'Bajo';
      } else if (score < 28) {
        return 'Medio';
      } else if (score < 38) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 14) {
        return 'Nulo';
      } else if (score < 29) {
        return 'Bajo';
      } else if (score < 42) {
        return 'Medio';
      } else if (score < 58) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatOrganization(score: Number) {
    if (score < 10) {
      return 'Nulo';
    } else if (score < 14) {
      return 'Bajo';
    } else if (score < 18) {
      return 'Medio';
    } else if (score < 23) {
      return 'Alto';
    } else {
      return 'Muy alto';
    }
  }

  evaluateDomWorkConditions(score: Number) {
    if (this.qType== 2) {
      if (score < 3) {
        return 'Nulo';
      } else if (score < 5) {
        return 'Bajo';
      } else if (score < 7) {
        return 'Medio';
      } else if (score < 9) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 9) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomWorkLoad(score: Number) {
    if (this.qType== 2) {
      if (score < 12) {
        return 'Nulo';
      } else if (score < 16) {
        return 'Bajo';
      } else if (score < 20) {
        return 'Medio';
      } else if (score < 24) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 15) {
        return 'Nulo';
      } else if (score < 21) {
        return 'Bajo';
      } else if (score < 27) {
        return 'Medio';
      } else if (score < 37) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomMissingControl(score: Number) {
    if (this.qType== 2) {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 8) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 11) {
        return 'Nulo';
      } else if (score < 16) {
        return 'Bajo';
      } else if (score < 21) {
        return 'Medio';
      } else if (score < 25) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomWorkHours(score: Number) {
    if (this.qType== 2) {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 2) {
        return 'Bajo';
      } else if (score < 4) {
        return 'Medio';
      } else if (score < 6) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 2) {
        return 'Bajo';
      } else if (score < 4) {
        return 'Medio';
      } else if (score < 6) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomFamily(score: Number) {
    if (this.qType== 2) {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 2) {
        return 'Bajo';
      } else if (score < 4) {
        return 'Medio';
      } else if (score < 6) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 4) {
        return 'Nulo';
      } else if (score < 6) {
        return 'Bajo';
      } else if (score < 8) {
        return 'Medio';
      } else if (score < 10) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomLeadership(score: Number) {
    if (this.qType== 2) {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 5) {
        return 'Bajo';
      } else if (score < 8) {
        return 'Medio';
      } else if (score < 11) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 9) {
        return 'Nulo';
      } else if (score < 12) {
        return 'Bajo';
      } else if (score < 16) {
        return 'Medio';
      } else if (score < 20) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomWorkRelations(score: Number) {
    if (this.qType== 2) {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 8) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 10) {
        return 'Nulo';
      } else if (score < 13) {
        return 'Bajo';
      } else if (score < 17) {
        return 'Medio';
      } else if (score < 21) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomViolence(score: Number) {
    if (this.qType== 2) {
      if (score < 7) {
        return 'Nulo';
      } else if (score < 10) {
        return 'Bajo';
      } else if (score < 13) {
        return 'Medio';
      } else if (score < 16) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 7) {
        return 'Nulo';
      } else if (score < 10) {
        return 'Bajo';
      } else if (score < 13) {
        return 'Medio';
      } else if (score < 16) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomRecognitions(score: Number) {
    if (score < 6) {
      return 'Nulo';
    } else if (score < 10) {
      return 'Bajo';
    } else if (score < 14) {
      return 'Medio';
    } else if (score < 18) {
      return 'Alto';
    } else {
      return 'Muy alto';
    }
  }

  evaluateDomInstability(score: Number) {
    if (score < 4) {
      return 'Nulo';
    } else if (score < 6) {
      return 'Bajo';
    } else if (score < 8) {
      return 'Medio';
    } else if (score < 10) {
      return 'Alto';
    } else {
      return 'Muy alto';
    }
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.getQuestionnaireType(1);
  }

  nextPage() {
    this.offset += this.limit;
    this.getQuestionnaireType(2);
    this.limitDown += this.limit;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
  }

  previusPage() {
    this.offset -= this.limit;
    this.getQuestionnaireType(2);
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  getTotalQSD() {
    this.numQSD += 1
    this.numQSD += this.qSD.cellphone === 1 ? 1 : 0;
    this.numQSD += 1
    this.numQSD += this.qSD.gender === 1 ? 1 : 0;
    this.numQSD += this.qSD.age === 1 ? 1 : 0;
    this.numQSD += this.qSD.age_segmented === 1 ? 1 : 0;
    this.numQSD += this.qSD.state === 1 ? 1 : 0;
    this.numQSD += this.qSD.civil_status === 1 ? 1 : 0;
    this.numQSD += this.qSD.education_level === 1 ? 1 : 0;
    this.numQSD += this.qSD.occupation === 1 ? 1 : 0;
    this.numQSD += this.qSD.position_type === 1 ? 1 : 0;
    this.numQSD += this.qSD.recruitment_type === 1 ? 1 : 0;
    this.numQSD += this.qSD.employee_type === 1 ? 1 : 0;
    this.numQSD += this.qSD.working_hours_type === 1 ? 1 : 0;
    this.numQSD += this.qSD.working_hours_rotation === 1 ? 1 : 0;
    this.numQSD += this.qSD.work_experience === 1 ? 1 : 0;
    this.numQSD += this.qSD.current_position_time === 1 ? 1 : 0;
  }

  async exportResults() {
    this.toExcelService.exportAsExcelFile(await this.getExportData(), 'resultados');
  }
  
 async getExportData() {
    let array_data = [];
    const res = await this.questionnaireResultService.getQ1Results(this.idQuestionnaireApplication, null, null, `q${this.qType}`);

    if (res.success) {
      for (let result of res.data.employees) {
        let data = {};
        data['Email'] = result.application_email;
        data['Nombre'] = result.name;
        data['Area'] = result.area;

        if (this.qSD.email === 1) {
          data['Email_Extra'] = result.email;
        }
        if (this.qSD.cellphone === 1) {
          data['Lada'] = result.cellphone_prefix;
          data['Telefono'] = result.cellphone;
        }
        if (this.qSD.gender === 1) {
          data['Genero'] = result.gender;
        }
        if (this.qSD.age_segmented === 1) {
          data['Rango_de_Edad'] = result.age_range;
        }
        if (this.qSD.age === 1) {
          data['Edad'] = result.age;
        }
        if (this.qSD.state === 1) {
          data['Estado'] = result.state;
        }
        if (this.qSD.education_level === 1) {
          data['Nivel_de_Estudios'] = result.education_level;
        }
        if (this.qSD.civil_status === 1) {
          data['Estado_Civil'] = result.civil_status;
        }
        if (this.qSD.occupation === 1) {
          data['Ocupacion'] = result.occupation;
        }
        if (this.qSD.position_type === 1) {
          data['Puesto'] = result.position_type;
        }
        if (this.qSD.recruitment_type === 1) {
          data['Contratacion'] = result.recruitment_type;
        }
        if (this.qSD.employee_type === 1) {
          data['Tipo_de_Colaborador'] = result.employee_type;
        }
        if (this.qSD.working_hours_type === 1) {
          data['Horario'] = result.working_hours_type;
        }
        if (this.qSD.working_hours_rotation === 1) {
          data['Rotacion_de_Horario'] = result.working_hours_rotation;
        }
        if (this.qSD.work_experience === 1) {
          data['Experiencia'] = result.work_experience;
        }
        if (this.qSD.current_position_time === 1) {
          data['Tiempo_en_Puesto_Actual'] = result.current_position_time;
        }

        array_data.push(data);

      }
    }

    return array_data;

  }


}
