import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AnswerService } from 'src/app/services/answer.service';

@Component({
  selector: 'app-home-questionnaire',
  templateUrl: './home-questionnaire.component.html'
})
export class HomeQuestionnaireComponent implements OnInit {

  token: string;
  showSpinner = false;
  primary: string;
  secondary: string;
  tertiary: string;
  tertiary_text: string;
  clientDataListener$: Observable<any>;

  constructor(private route: ActivatedRoute, private answerService: AnswerService, private router: Router) {
    this.token = this.route.snapshot.queryParamMap.get('token');
    localStorage.removeItem('cnq35');
  }

  ngOnInit() {
    //Default Values before initializing from client data
    this.primary = "#0073E1";
    this.secondary = "#FFF9F5";
    this.tertiary = "#FFF9F5";
    this.tertiary_text = "#000000";

    if (this.token) {
      this.showSpinner = true;
      this.answerService.loadingClientDataListener.next(true);
      localStorage.setItem('cnq35', this.token);
      this.setColors();
    } else {
      this.clientDataListener$ = this.answerService.clientDataListener;
      this.clientDataListener$.subscribe(client => {
        if(client) {
          this.primary = client.primary_color;
          this.secondary = client.secondary_color;
          this.tertiary = client.tertiary_color;
          this.tertiary_text = client.text_tertiary_color;
          let colors = {
            text_primary_color: client.text_primary_color,
            text_secondary_color: client.text_secondary_color,
            text_tertiary_color: client.text_tertiary_color 
          };
          sessionStorage.setItem('text_colors', JSON.stringify(colors));
        }
        this.answerService.loadingClientDataListener.next(false);
      })
    }
  }

  async setColors() {
    const res = await this.answerService.getData();
    
    if (res.success) {
      this.primary = res.data.client.primary_color;
      this.secondary = res.data.client.secondary_color;
      this.tertiary = res.data.client.tertiary_color;
      this.tertiary_text = res.data.client.text_tertiary_color;
      let colors = {
        text_primary_color: res.data.client.text_primary_color,
        text_secondary_color: res.data.client.text_secondary_color,
        text_tertiary_color: res.data.client.text_tertiary_color 
      };
      this.answerService.clientDataListener.next(res.data.client);
      sessionStorage.setItem('text_colors', JSON.stringify(colors));
    }
    this.showSpinner = false;
    this.answerService.loadingClientDataListener.next(false);
  }

}
