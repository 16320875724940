import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ClientService } from '../../services/client.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {

  users = [];
  showSpinner = false;
  newUserForm: FormGroup;
  @ViewChild('modalUserNew') public modalUserNew: ModalDirective;
  @ViewChild('modalUserDelete') public modalUserDelete: ModalDirective;
  deleteUser = {
    name: '',
    id: 0
  };
  loadingDelete = false;
  loadingNew = false;
  user_types = [];
  clients = [];
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;
  search_text = "";
  search_client;
  search_type;
  filter_number = 1;

  constructor(private usuarioService: UsuarioService, private router: Router, private notifierService: NotifierService,
    private clientService: ClientService, private errService: ErrorService) {
    this.newUserForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      client_id: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      user_type_id: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    sessionStorage.removeItem('search_employee');
    sessionStorage.removeItem('search_client');
    this.getUsers(1);
    this.getUserTypes();
    this.getClients();
  }

  async getUsers(type) {
    this.users = [];
    this.showSpinner = true;
    const res = await this.usuarioService.getAll(this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.users = res.data.users;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getUsersByName(type) {
    this.users = [];
    this.showSpinner = true;
    const res = await this.usuarioService.getByName(this.search_text, this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.users = res.data.users;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getUsersByEmail(type) {
    this.users = [];
    this.showSpinner = true;
    const res = await this.usuarioService.getByEmail(this.search_text, this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.users = res.data.users;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getUsersByClient(type) {
    this.users = [];
    this.showSpinner = true;
    const res = await this.usuarioService.getByClient(this.search_client, this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.users = res.data.users;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getUsersByType(type) {
    this.users = [];
    this.showSpinner = true;
    const res = await this.usuarioService.getByType(this.search_type, this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.users = res.data.users;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getUserTypes() {
    const res = await this.usuarioService.getTypes();
    if (res.success) {
      this.user_types = res.data.user_types;
    }
  }

  async getClients() {
    const res = await this.clientService.getAllCatalog();
    if (res.success) {
      this.clients = res.data.clients;
    }
  }

  userDetails(id) {
    this.router.navigate(['/admin/usuarios/', id]);
  }

  openDeleteUserModal(id, name1, name2) {
    this.deleteUser.id = id;
    this.deleteUser.name = `${name1} ${name2}`;
    this.modalUserDelete.show();
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.makeSearch(1);
  }

  makeSearchText() {
    if (this.search_text !== "") {
      if (this.filter_number === 2) {
        this.getUsersByName(1);
      } else if (this.filter_number === 3) {
        this.getUsersByEmail(1);
      }
    } else if (this.filter_number === 2 || this.filter_number === 3) {
      this.getUsers(1);
    }
  }

  clearTextInput() {
    this.search_text = "";
    this.search_client = undefined;
    this.search_type = undefined;
    if (this.filter_number === 1) {
      this.getUsers(1);
    }
  }

  nextPage() {
    this.offset += this.limit;
    this.makeSearch(2);
    this.limitDown += this.limit;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
  }

  previusPage () {
    this.offset -= this.limit;
    this.makeSearch(2);
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
  }

  makeSearch(type) {
    switch(this.filter_number) {
      case 1:
          this.getUsers(type);
        break;
      case 2:
          this.getUsersByName(type);
        break;
      case 3:
          this.getUsersByEmail(type);
        break;
      case 4:
          this.getUsersByClient(type);
        break;
      case 5:
          this.getUsersByType(type);
        break;
    };
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  async removeUser() {
    this.loadingDelete = true;
    const res = await this.usuarioService.deleteById(this.deleteUser.id);
    if (res.success) {
      this.offset = 0;
      this.getUsers(1);
      this.notifierService.notify('success', 'Usuario eliminado');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalUserDelete.hide();
    this.loadingDelete = false;
  }

  showNewUserModal() {
    this.clearValues();
    this.modalUserNew.show();
  }

  clearValues() {
    this.newUserForm.controls.first_name.setValue('');
    this.newUserForm.controls.last_name.setValue('');
    this.newUserForm.controls.client_id.setValue('');
    this.newUserForm.controls.phone.setValue('');
    this.newUserForm.controls.user_type_id.setValue('');
    this.newUserForm.controls.email.setValue('');
    this.newUserForm.controls.password.setValue('');
  }
  async addUser() {
    if(!this.checkNewUserForm()) {
      return;
    }
    this.loadingNew = true;
    const data = {
      first_name: this.newUserForm.controls['first_name'].value,
      last_name: this.newUserForm.controls['last_name'].value,
      client_id: this.newUserForm.controls['client_id'].value,
      phone: this.newUserForm.controls['phone'].value,
      user_type_id: this.newUserForm.controls['user_type_id'].value,
      email: this.newUserForm.controls['email'].value,
      password: this.newUserForm.controls['password'].value,
    };

    const res = await this.usuarioService.post(data);
    if (res.success) {
      this.offset = 0
      this.getUsers(1);
      this.notifierService.notify('success', 'Usuario añadido');
    } else {
      this.notifierService.notify('error', res.error.message);
    }
    this.modalUserNew.hide();
    this.loadingNew = false;
  }

  checkNewUserForm() {
    if (this.newUserForm.get('first_name').invalid) {
      this.notifierService.notify('error', 'El nombre es obligatorio');
      return false;
    }
    if (this.newUserForm.get('client_id').invalid) {
      this.notifierService.notify('error', 'El cliente es obligatorio');
      return false;
    }
    if (this.newUserForm.get('email').invalid) {
      if(this.newUserForm.get('email').errors.email) {
        this.notifierService.notify('error', 'Debe ser un email válido');
      }
      if(this.newUserForm.get('email').errors.required) {
        this.notifierService.notify('error', 'El email es obligatorio');
      }
      return false;
    }
    if (this.newUserForm.get('user_type_id').invalid) {
      this.notifierService.notify('error', 'El tipo de usuario es obligatorio');
      return false;
    }
    if (this.newUserForm.get('last_name').invalid) {
      this.notifierService.notify('error', 'El apellido es obligatorio');
      return false;
    }
    if (this.newUserForm.get('phone').invalid) {
      this.notifierService.notify('error', 'El teléfono es obligatorio');
      return false;
    }
    if (this.newUserForm.get('password').invalid) {
      this.notifierService.notify('error', 'El password es obligatorio');
      return false;
    }
    return true;
  }

}
