import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireResultService {

  constructor(private http: HttpClient) { }

  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cn035')
      })
    };
  }

  getQ1Results(idQuestionnaire, limit, offset, qType: string = 'q1') {
    if ( limit === null) {
      return this.http.get(`${apiUrl}/questionnaireresult/${idQuestionnaire}/${qType}/needattention`, this.getAuthHeader())
        .toPromise()
        .then(res => res)
        .catch(err => err.error);
    } else {
      return this.http.get(`${apiUrl}/questionnaireresult/${idQuestionnaire}/${qType}/needattention?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader())
        .toPromise()
        .then(res => res)
        .catch(err => err.error);
    }
  }

  getQResults(idQuestionnaire, type, url) {
    return this.http.get(`${apiUrl}/questionnaireresult/${idQuestionnaire}/q${type}/${url}`, this.getAuthHeader())
      .toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

}
