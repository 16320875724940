import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cn035')
      })
    };
  }

  getAll(limit, offset) {
    return this.http.get(`${apiUrl}/user/all?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getByName(q_name, limit, offset) {
    return this.http.get(`${apiUrl}/user/name?name=${q_name}&limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getByEmail(q_email,limit, offset) {
    return this.http.get(`${apiUrl}/user/email?email=${q_email}&limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getByClient(q_client,limit, offset) {
    return this.http.get(`${apiUrl}/user/client/${q_client}?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getByType(q_type, limit, offset) {
    return this.http.get(`${apiUrl}/user/type/${q_type}?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getById(id) {
    return this.http.get(`${apiUrl}/user/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  patchById(id, data) {
    return this.http.patch(`${apiUrl}/user/${id}`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  deleteById(id) {
    return this.http.delete(`${apiUrl}/user/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  patchMe(data) {
    return this.http.patch(`${apiUrl}/user/me`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getMe() {
    return this.http.get(`${apiUrl}/user/me`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  post(data) {
    return this.http.post(`${apiUrl}/user/`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getTypes() {
    return this.http.get(`${apiUrl}/user/types`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }
}
