import { Routes } from '@angular/router';
import { ClientGuard } from '../../guards/client.guard';
import { QuestionarioDetailsComponent } from '../questionario-details/questionario-details.component';
import { AreaDetailsComponent } from '../area-details/area-details.component';
import { EmployeeDetailsComponent } from '../employee-details/employee-details.component';
import { ProfileComponent } from '../profile/profile.component';
import { AreasComponent } from '../areas/areas.component';
import { BusinessComponent } from '../business/business.component';
import { BusinessDetailsComponent } from '../business-details/business-details.component';
import { QuestionariosComponent } from '../questionarios/questionarios.component';
import { HomeClienteComponent } from '../home-cliente/home-cliente.component';

export const CLIENT_ROUTES: Routes = [
    { path: 'perfil', component: ProfileComponent, canActivate: [ClientGuard] },
    { path: 'home', component: HomeClienteComponent, canActivate: [ClientGuard] },
    { path: 'areas', component: AreasComponent, canActivate: [ClientGuard] },
    { path: 'areas/:id_area', component: AreaDetailsComponent, canActivate: [ClientGuard] },
    { path: 'business', component: BusinessComponent, canActivate: [ClientGuard] },
    { path: 'business/:id_business', component: BusinessDetailsComponent, canActivate: [ClientGuard] },
    { path: 'cuestionarios', component: QuestionariosComponent, canActivate: [ClientGuard] },
    { path: 'cuestionarios/:id_q', component: QuestionarioDetailsComponent, canActivate: [ClientGuard] },
    { path: 'cuestionarios/colaborador/:id_emp', component: EmployeeDetailsComponent, canActivate: [ClientGuard] },
    { path: '**', redirectTo: 'home' }
];
