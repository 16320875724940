import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnswerService } from '../../../services/answer.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { HttpDefaultResponse } from 'src/app/interfaces/http-default-response';
import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from 'src/app/constants/http-requests.const';
import { ModalDirective } from 'ngx-bootstrap';
import { DEFAULT_WINDOW_REFRESH_ALERT, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE } from 'src/app/constants/alert-messages.const';

@Component({
  selector: 'app-sdqform',
  templateUrl: './sdqform.component.html'
})
export class SDQformComponent implements OnInit {

  editEmployeeForm: FormGroup;
  loadingReady = false;
  loadingUpdate = false;
  sociodemographics: any;
  qType = 0;
  showFinal = false;
  catalogs: any;
  colors: any;
  showPreQuestionnaire = false;
  mobile = false;
  // For Selects
  genders = [];
  ages = [];
  civil_statuses = [];
  education_levels = [];
  areas = [];
  businesses = [];
  position_types = [];
  recruitment_types = [];
  employee_types = [];
  working_hours_types = [];
  working_hours_rotations = [];
  working_experiences = [];
  current_position_times = [];
  answer_statuses = [];
  states = [];
  end_msg = `<h4>Al terminar la aplicación del cuestionario le comunicaremos a tu organización los resultados y planes 
            de acción que apliquen de acuerdo a ello.</h4>`;
  btn_info = {
    show: true,
    text: 'Visita Cuéntame',
    link: 'https://sicuentame.com'
  }
  errorModalData = {
    code: DEFAULT_ERROR_CODE,
    message: DEFAULT_ERROR_MESSAGE,
    title: DEFAULT_ERROR_MESSAGE
  } 
  @ViewChild('modalError') public modalError: ModalDirective;

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    $event.returnValue = DEFAULT_WINDOW_REFRESH_ALERT;
  }
  

  constructor(private router: Router, private answerService: AnswerService, private notifierService: NotifierService) {
    this.colors = JSON.parse(sessionStorage.getItem('text_colors'));
    this.editEmployeeForm = new FormGroup({
      email: new FormControl('', Validators.required),
      cellphone_prefix: new FormControl('', Validators.required),
      cellphone: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      gender_id: new FormControl('', Validators.required),
      age: new FormControl('', Validators.required),
      age_id: new FormControl('', Validators.required),
      civil_status_id: new FormControl('', Validators.required),
      education_level_id: new FormControl('', Validators.required),
      occupation: new FormControl('', Validators.required),
      area_id: new FormControl('', Validators.required),
      business_id: new FormControl('', Validators.required),
      position_type_id: new FormControl('', Validators.required),
      recruitment_type_id: new FormControl('', Validators.required), 
      employee_type_id: new FormControl('', Validators.required),
      working_hours_type_id: new FormControl('', Validators.required), 
      working_hours_rotation_id: new FormControl('', Validators.required),
      work_experience_id: new FormControl('', Validators.required), 
      current_position_time_id: new FormControl('', Validators.required),
      state_id: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.getData();
    this.checkWindowSize();
  }

  isIE() {
    const ua = navigator.userAgent;
    return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  }

  outCuentame(link) {
    if (this.isIE()) {
      location.assign(link);
    } else {
      window.location.href = link;
    }
  }

  checkWindowSize() {
    if (window.screen.width <= 500) { // 768px portrait
      this.mobile = true;
    }
  }

  async getData() {
    this.loadingReady = true;
    const res = await this.answerService.getData();
    console.log('SDQformComponent', res)
    if(!res) {
      const onHide = () => {
        this.router.navigate(['/questionnaire/'], { queryParams: { 'load-error': true } });
      }
      this.showErrorModal(RESTART_FROM_ORIGINAL_URL_MESSAGE, RESTART_FROM_ORIGINAL_URL_CODE, RESTART_FROM_ORIGINAL_URL_MESSAGE, onHide)
      return
    }
    this.answerService.clientDataListener.next(res.data.client);
    if (res.success) {
      if (res.data.questionnaire_application.end_message !== null &&
        res.data.questionnaire_application.end_message.length !== 0) {
        this.end_msg = res.data.questionnaire_application.end_message;
      }
      this.btn_info.show = res.data.questionnaire_application.show_link_button === 1;
      if (res.data.questionnaire_application.link_button_message !== null &&
        res.data.questionnaire_application.link_button_message !== 0) {
        this.btn_info.text = res.data.questionnaire_application.link_button_message;
      }
      if (res.data.questionnaire_application.link_button_url !== null &&
        res.data.questionnaire_application.link_button_url !== 0) {
        this.btn_info.link = res.data.questionnaire_application.link_button_url;
      }
      this.sociodemographics = res.data.application_sociodemographic;
      this.catalogs = res.data.catalogs;
      this.areas = res.data.application_areas;
      this.businesses = res.data.application_businesses;
      this.qType = res.data.questionnaire_application.questionnaire_id;
      this.editEmployeeForm.controls['name'].setValue(res.data.employee.name);
      if (res.data.employee.area_id !== null) {
        this.editEmployeeForm.controls['area_id'].setValue(res.data.employee.area_id);
      }
      if(this.sociodemographics.business === 1 && res.data.employee.business_id !== null){
        this.editEmployeeForm.controls['business_id'].setValue(res.data.employee.business_id);
      }
      if (this.sociodemographics.gender === 1 && res.data.employee.gender_id !== null) {
        this.editEmployeeForm.controls['gender_id'].setValue(res.data.employee.gender_id);
      }
      if (this.sociodemographics.age_segmented === 1 && res.data.employee.age_id !== null) {
        this.editEmployeeForm.controls['age_id'].setValue(res.data.employee.age_id);
      }
      if (this.sociodemographics.education_level === 1 && res.data.employee.education_level_id !== null) {
        this.editEmployeeForm.controls['education_level_id'].setValue(res.data.employee.education_level_id);
      }
      if (this.sociodemographics.state === 1 && res.data.employee.state_id !== null) {
        this.editEmployeeForm.controls['state_id'].setValue(res.data.employee.state_id);
      }
      if (this.sociodemographics.civil_status === 1 && res.data.employee.civil_status_id !== null) {
        this.editEmployeeForm.controls['civil_status_id'].setValue(res.data.employee.civil_status_id);
      }
      if (this.sociodemographics.position_type === 1 && res.data.employee.position_type_id !== null) {
        this.editEmployeeForm.controls['position_type_id'].setValue(res.data.employee.position_type_id);
      }
      if (this.sociodemographics.recruitment_type === 1 && res.data.employee.recruitment_type_id !== null) {
        this.editEmployeeForm.controls['recruitment_type_id'].setValue(res.data.employee.recruitment_type_id);
      }
      if (this.sociodemographics.employee_type === 1 && res.data.employee.employee_type_id !== null) {
        this.editEmployeeForm.controls['employee_type_id'].setValue(res.data.employee.employee_type_id);
      }
      if (this.sociodemographics.working_hours_type === 1 && res.data.employee.working_hours_type_id !== null) {
        this.editEmployeeForm.controls['working_hours_type_id'].setValue(res.data.employee.working_hours_type_id);
      }
      if (this.sociodemographics.working_hours_rotation === 1 && res.data.employee.working_hours_rotation_id !== null) {
        this.editEmployeeForm.controls['working_hours_rotation_id'].setValue(res.data.employee.working_hours_rotation_id);
      }
      if (this.sociodemographics.work_experience === 1 && res.data.employee.work_experience_id !== null) {
        this.editEmployeeForm.controls['work_experience_id'].setValue(res.data.employee.work_experience_id);
      }
      if (this.sociodemographics.current_position_time === 1 && res.data.employee.current_position_time_id !== null) {
        this.editEmployeeForm.controls['current_position_time_id'].setValue(res.data.employee.current_position_time_id);
      }
      if (this.sociodemographics.occupation === 1 && res.data.employee.occupation !== null) {
        this.editEmployeeForm.controls['occupation'].setValue(res.data.employee.occupation);
      }
      this.getCatalogs();
      this.editEmployeeForm.controls.name.disable();
    }
  }

  showErrorModal (title: string, code: number, message: string, onHide = null) {
    this.errorModalData = {title: title, code: code, message: message};
    const modal = this.modalError
    modal.config = {ignoreBackdropClick: true};
    if(onHide !== null) {
      modal.onHidden.subscribe(onHide)
    }
    setTimeout(() => {
      modal.show()
    }, 100)
  }

  getCatalogs() {
    if (this.sociodemographics.gender === 1) {
      this.genders = this.catalogs.genders;
    }
    if (this.sociodemographics.age_segmented === 1) {
      this.ages = this.catalogs.ages;
    }
    if (this.sociodemographics.education_level === 1) {
      this.education_levels = this.catalogs.education_levels;
    }
    if (this.sociodemographics.state === 1) {
      this.states = this.catalogs.states;
    }
    if (this.sociodemographics.civil_status === 1) {
      this.civil_statuses = this.catalogs.civil_statuses;
    }
    if (this.sociodemographics.position_type === 1) {
      this.position_types = this.catalogs.position_types;
    }
    if (this.sociodemographics.recruitment_type === 1) {
      this.recruitment_types = this.catalogs.recruitment_types;
    }
    if (this.sociodemographics.employee_type === 1) {
      this.employee_types = this.catalogs.employee_types;
    }
    if (this.sociodemographics.working_hours_type === 1) {
      this.working_hours_types = this.catalogs.working_hours_types;
    }
    if (this.sociodemographics.working_hours_rotation === 1) {
      this.working_hours_rotations = this.catalogs.working_hours_rotations;
    }
    if (this.sociodemographics.work_experience === 1) {
      this.working_experiences = this.catalogs.work_experiences;
    }
    if (this.sociodemographics.current_position_time === 1) {
      this.current_position_times = this.catalogs.current_position_times;
    }
    this.loadingReady = false;
  }

  validInputs() {
    if (this.sociodemographics.business === 1 && this.editEmployeeForm.controls['business_id'].value === '') {
      this.notifierService.notify('error', 'El campo Centro de Trabajo es obligatorio');
      return false;
    }
    if (this.sociodemographics.email === 1 && this.editEmployeeForm.controls['email'].value === '') {
      this.notifierService.notify('error', 'El campo Email es obligatorio');
      return false;
    }
    if (this.sociodemographics.cellphone === 1 && this.editEmployeeForm.controls['cellphone_prefix'].value === '') {
      this.notifierService.notify('error', 'El campo Télefono(Prefijo) es obligatorio');
      return false;
    }
    if (this.sociodemographics.cellphone === 1 && this.editEmployeeForm.controls['cellphone'].value === '') {
      this.notifierService.notify('error', 'El campo Télefono(Número) es obligatorio');  
      return false;
    }
    if (this.sociodemographics.gender === 1 && this.editEmployeeForm.controls['gender_id'].value === '') {
      this.notifierService.notify('error', 'El campo Sexo es obligatorio');
      return false;
    }
    if (this.sociodemographics.age_segmented === 1 && this.editEmployeeForm.controls['age_id'].value === '') {
      this.notifierService.notify('error', 'El campo Edad en años es obligatorio');
      return false;
    }
    if (this.sociodemographics.age === 1 && this.editEmployeeForm.controls['age'].value === '') {
      this.notifierService.notify('error', 'El campo Edad es obligatorio');
      return false;
    }
    if (this.sociodemographics.education_level === 1 && this.editEmployeeForm.controls['education_level_id'].value === '') {
      this.notifierService.notify('error', 'El campo Nivel de Estudios es obligatorio');
      return false;
    }
    if (this.sociodemographics.state === 1 && this.editEmployeeForm.controls['state_id'].value === '') {
      this.notifierService.notify('error', 'El campo Estado es obligatorio');
      return false;
    }
    if (this.sociodemographics.civil_status === 1 && this.editEmployeeForm.controls['civil_status_id'].value === '') {
      this.notifierService.notify('error', 'El campo Estado Civil es obligatorio');
    }
    if (this.sociodemographics.occupation === 1 && this.editEmployeeForm.controls['occupation'].value === '') {
      this.notifierService.notify('error', 'El campo Puesto es obligatorio');
      return false;
    }
    if (this.sociodemographics.position_type === 1 && this.editEmployeeForm.controls['position_type_id'].value === '') {
      this.notifierService.notify('error', 'El campo Tipo de Puesto es obligatorio');
      return false;
    }
    if (this.sociodemographics.recruitment_type === 1 && this.editEmployeeForm.controls['recruitment_type_id'].value === '') {
      this.notifierService.notify('error', 'El campo Tipo de Contratación es obligatorio');
      return false;
    }
    if (this.sociodemographics.employee_type === 1 && this.editEmployeeForm.controls['employee_type_id'].value === '') {
      this.notifierService.notify('error', 'El campo Tipo de Personal es obligatorio');
      return false;
    }
    if (this.sociodemographics.working_hours_type === 1 && this.editEmployeeForm.controls['working_hours_type_id'].value === '') {
      this.notifierService.notify('error', 'El campo Tipo de Jornada de Trabajo es obligatorio');
      return false;
    }
    if (this.sociodemographics.working_hours_rotation === 1 && this.editEmployeeForm.controls['working_hours_rotation_id'].value === '') {
      this.notifierService.notify('error', 'El campo Realiza rotación de turnos es obligatorio');
      return false;
    }
    if (this.sociodemographics.work_experience === 1 && this.editEmployeeForm.controls['work_experience_id'].value === '') {
      this.notifierService.notify('error', 'El campo Tiempo experiencia laboral es obligatorio');
      return false;
    }
    if (this.sociodemographics.current_position_time === 1 && this.editEmployeeForm.controls['current_position_time_id'].value === '') {
      this.notifierService.notify('error', 'El campo Tiempo en Puesto Actual es obligatorio');
      return false;
    }
    return true;
  }

  async patchQuestionnaire() {

    this.loadingUpdate = true;

    if (!this.validInputs()) {
      this.loadingUpdate = false;
      return false;
    }

    const data = this.getPatchData();

    let request$: Observable<HttpDefaultResponse>;
    
    if (this.qType === 1 ) {
      request$ = this.answerService.sendSocioDemographicData(data);
    } else if(this.qType === 2 || this.qType === 3) {
      this.notifierService.notify('default', 'Enviando información');
      request$ = this.answerService.sendSocioDemographicData2(data);
    } else {
      request$ = this.answerService.sendSocioDemographicData3(data);
    }
    request$.subscribe( response => {
      this.loadingUpdate = false;
    
      if (response.success) {
        if (this.qType === 1 ) {
          this.showPreQuestionnaire = true;
        } else {
          this.notifierService.notify('success', 'Información envíada');
          this.showFinal = true;
        }
      } else {
        this.errorModalData = {...response.error, title: response.error.code === TIMEOUT_ERROR_CODE ? TIMEOUT_ERROR_MESSAGE : DEFAULT_ERROR_MESSAGE};
        this.modalError.show()
      }
    });
    
  }

  startQ() {
    switch(this.qType) {
      case 1:
        this.router.navigate(['questionnaire/q1']);
        break;
      case 2:
        this.router.navigate(['questionnaire/q2']);
        break;
      case 3:
        this.router.navigate(['questionnaire/q3']);
        break;
      case 4:
        this.router.navigate(['questionnaire/q1']);
        break;
      case 5:
        this.router.navigate(['questionnaire/q1']);
        break;
    }
  }

  getPatchData() {
    const data = {
      area_id: this.editEmployeeForm.controls['area_id'].value
    };

    if ( this.sociodemographics.business === 1 ) {
      data['business_id'] = this.editEmployeeForm.controls['business_id'].value;
    }
    if ( this.sociodemographics.email === 1 ) {
      data['email'] = this.editEmployeeForm.controls['email'].value;
    }
    if ( this.sociodemographics.cellphone === 1 ) {
      data['cellphone_prefix'] = this.editEmployeeForm.controls['cellphone_prefix'].value;
      data['cellphone'] = this.editEmployeeForm.controls['cellphone'].value;
    }
    if ( this.sociodemographics.gender === 1 ) {
      data['gender_id'] = this.editEmployeeForm.controls['gender_id'].value;
    }
    if ( this.sociodemographics.age_segmented === 1 ) {
      data['age_id'] = this.editEmployeeForm.controls['age_id'].value;
    }
    if ( this.sociodemographics.age === 1 ) {
      data['age'] = this.editEmployeeForm.controls['age'].value;
    }
    if ( this.sociodemographics.education_level === 1 ) {
      data['education_level_id'] = this.editEmployeeForm.controls['education_level_id'].value;
    }
    if (this.sociodemographics.state === 1) {
      data['state_id'] = this.editEmployeeForm.controls['state_id'].value;
    }
    if ( this.sociodemographics.civil_status === 1 ) {
      data['civil_status_id'] = this.editEmployeeForm.controls['civil_status_id'].value;
    }
    if ( this.sociodemographics.occupation === 1 ) {
      data['occupation'] = this.editEmployeeForm.controls['occupation'].value;
    }
    if ( this.sociodemographics.position_type === 1 ) {
      data['position_type_id'] = parseInt(this.editEmployeeForm.controls['position_type_id'].value);
    }
    if ( this.sociodemographics.recruitment_type === 1 ) {
      data['recruitment_type_id'] = this.editEmployeeForm.controls['recruitment_type_id'].value;
    }
    if ( this.sociodemographics.employee_type === 1 ) {
      data['employee_type_id'] = this.editEmployeeForm.controls['employee_type_id'].value;
    }
    if ( this.sociodemographics.working_hours_type === 1 ) {
      data['working_hours_type_id'] = this.editEmployeeForm.controls['working_hours_type_id'].value;
    }
    if ( this.sociodemographics.working_hours_rotation === 1 ) {
      data['working_hours_rotation_id'] = this.editEmployeeForm.controls['working_hours_rotation_id'].value;
    }
    if ( this.sociodemographics.work_experience === 1 ) {
      data['work_experience_id'] = this.editEmployeeForm.controls['work_experience_id'].value;
    }
    if ( this.sociodemographics.current_position_time === 1 ) {
      data['current_position_time_id'] = this.editEmployeeForm.controls['current_position_time_id'].value;
    }

    return data;
  }



}
