import { Routes } from '@angular/router';
import { UsuariosComponent } from '../usuarios/usuarios.component';
import { AdminGuard } from '../../guards/admin.guard';
import { UsuarioDetailsComponent } from '../usuario-details/usuario-details.component';
import { ClientesComponent } from '../clientes/clientes.component';
import { ClienteDetailsComponent } from '../cliente-details/cliente-details.component';
import { QuestionarioDetailsComponent } from '../questionario-details/questionario-details.component';
import { AreaDetailsComponent } from '../area-details/area-details.component';
import { BusinessDetailsComponent } from '../business-details/business-details.component';
import { EmployeeDetailsComponent } from '../employee-details/employee-details.component';
import { ProfileComponent } from '../profile/profile.component';

export const ADMIN_ROUTES: Routes = [
    { path: 'perfil', component: ProfileComponent, canActivate: [AdminGuard] },
    { path: 'usuarios', component: UsuariosComponent, canActivate: [AdminGuard] },
    { path: 'usuarios/:id_user', component: UsuarioDetailsComponent, canActivate: [AdminGuard] },
    { path: 'clientes', component: ClientesComponent, canActivate: [AdminGuard] },
    { path: 'clientes/:id_client', component: ClienteDetailsComponent, canActivate: [AdminGuard] },
    { path: 'clientes/area/:id_area', component: AreaDetailsComponent, canActivate: [AdminGuard] },
    { path: 'clientes/business/:id_business', component: BusinessDetailsComponent, canActivate: [AdminGuard] },
    { path: 'clientes/cuestionario/:id_q', component: QuestionarioDetailsComponent, canActivate: [AdminGuard] },
    { path: 'clientes/cuestionario/colaborador/:id_emp', component: EmployeeDetailsComponent, canActivate: [AdminGuard] },
    { path: '**', redirectTo: 'clientes' }
];
