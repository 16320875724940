import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMsg = '';
  btnText = 'Iniciar Sesión';
  asyncFlag = false;

  constructor(private authService: AuthService, private router: Router) {
    this.loginForm = new FormGroup({
      email: new FormControl(),
      password: new FormControl()
    });
  }

  ngOnInit() {
    if (!this.authService.adminLogged()) {
      this.router.navigate(['/']);
    }
  }

  async login() {
    this.errorMsg = '';

    const data = {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    };

    if (data.email.length === 0) {
      this.errorMsg = 'Ingresa tu correo';
    } else if (data.password.length === 0) {
      this.errorMsg = 'Ingresa una contraseña';
    } else {
      this.asyncFlag = true;
      const res = await this.authService.loginRequest(data);
      this.asyncFlag = false;

      if (res.success) {
        this.authService.saveLoginData(res.data.user_type_id, res.data.token, res.data.exp);
      } else {
        if (res.error.code === 106) {
          this.errorMsg = 'Contraseña incorrecta';
        } else {
          this.errorMsg = 'Usuario no encontrado';
        }
      }
    }
  }

}
