import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AreaService } from '../../services/area.service';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-area-details',
  templateUrl: './area-details.component.html'
})
export class AreaDetailsComponent implements OnInit {

  area: any;
  editAreaForm: FormGroup;
  canEdit = false;
  idArea;
  loadingUpdate = false;
  aux_user: any;

  constructor(private activatedRoute: ActivatedRoute, private areaService: AreaService, private router: Router,
    private notifierService: NotifierService, private authService: AuthService, private errService: ErrorService) {
    this.editAreaForm = new FormGroup({
      name: new FormControl('', Validators.required),
      client: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.disableForm();
    const id = this.activatedRoute.snapshot.paramMap.get('id_area');
    this.idArea = id;
    this.getArea(id);
  }

  setValues() {
    this.editAreaForm.controls['name'].setValue(this.area.name);
    this.editAreaForm.controls['client'].setValue(this.area.client);
  }

  backArea() {
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/', this.area.client_id]);
    } else {
      this.router.navigate(['/client/areas/']);
    }
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  async getArea(id) {
    const res = await this.areaService.getById(id);
    if (res.success) {
      this.area = res.data.area;
      this.setValues();
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  disableForm() {
    this.editAreaForm.controls['name'].disable();
    this.editAreaForm.controls['client'].disable();
  }

  enableForm() {
    this.editAreaForm.controls['name'].enable();
  }

  async editArea() {
    this.loadingUpdate = true;
    const data = {
      name: this.editAreaForm.controls['name'].value,
    };

    const res = await this.areaService.patchById(this.idArea, data);
    this.loadingUpdate = false;
    if (res.success) {
      this.getArea(this.idArea);
      this.setValues();
      this.disableForm();
      this.canEdit = false;
      this.notifierService.notify('success', 'Datos actualizados');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  cancelEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }

}
