import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ApplicationAreaService } from '../../services/application-area.service';
import { AreaService } from '../../services/area.service';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { AuthService } from 'src/app/services/auth.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
  selector: 'app-application-areas',
  templateUrl: './application-areas.component.html'
})
export class ApplicationAreasComponent implements OnInit {

  @Input() idQuestionnaireApplication;
  idClient;
  application_areas = [];
  showSpinner = false;
  aux_user: any;
  isEmpty: boolean = false;
  newApplicationAreaForm: FormGroup;
  @ViewChild('modalApplicationAreaNew') public modalApplicationAreaNew: ModalDirective;
  @ViewChild('modalApplicationAreaDelete') public modalApplicationAreaDelete: ModalDirective;
  deleteApplicationArea = {
    name: '',
    id: 0
  };
  filtered_application_areas = [];
  loadingDelete = false;
  loadingNew = false;
  areas = [];
  areas_form = [];

  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;

  constructor(private appAreaService: ApplicationAreaService, private router: Router, private notifierService: NotifierService,
    private areaService: AreaService, private questionarioService: QuestionnaireService, private fb: FormBuilder,
    private authService: AuthService) {
    this.newApplicationAreaForm = this.fb.group({
      app_areas: this.fb.array([])
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.getApplicationAreas(1);
    this.setIdClient();
  }

  async getApplicationAreas(type) {
    this.application_areas = [];
    this.filtered_application_areas = [];
    this.showSpinner = true;
    const res = await this.appAreaService.getByQuestionnaireApplication(this.idQuestionnaireApplication, this.limit, this.offset);
    this.showSpinner = false;
    if (res.success) {
      this.filtered_application_areas = res.data.application_applicationareas;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
  }

  async setIdClient() {
    const res = await this.questionarioService.getById(this.idQuestionnaireApplication);
    if (res.success) {
      this.idClient = res.data.questionnaire_application.client_id;
      this.getAreas();
    }
  }

  async getAreas() {
    const res = await this.areaService.getByClientCatalog(this.idClient);
    if (res.success) {
      this.areas = res.data.areas;
    }
  }

  openDeleteApplicationAreaModal(id, name) {
    this.deleteApplicationArea.id = id;
    this.deleteApplicationArea.name = `${name}`;
    this.modalApplicationAreaDelete.show();
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.limitUp = this.limit >= this.count ? this.count : this.limit;
    this.filtered_application_areas = [];
    this.getApplicationAreas(1);
  }

  nextPage() {
    this.offset += this.limit;
    this.limitDown += this.limit;
    this.limitUp = (this.limitUp + this.limit) <= this.count ? (this.limitUp + this.limit) : this.count;
    this.getApplicationAreas(0);
  }

  previusPage() {
    this.offset -= this.limit;
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
    this.getApplicationAreas(0);
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  async removeApplicationArea() {
    this.loadingDelete = true;
    const res = await this.appAreaService.deleteById(this.deleteApplicationArea.id);
    if (res.success) {
      this.offset = 0;
      this.limitDown = 1;
      this.limitUp = this.limit 
      this.getApplicationAreas(1);
      this.notifierService.notify('success', 'Área de Aplicación eliminada');
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
    this.modalApplicationAreaDelete.hide();
    this.loadingDelete = false;
  }

  showNewApplicationAreaModal() {
    this.clearSelectAreas();
    this.areas_form = this.getDiffAreas();
    if(this.areas_form.length === 0){
      this.isEmpty = true;
    }
    this.areas_form.forEach(() => {
      (<FormArray>this.newApplicationAreaForm.controls['app_areas']).push(new FormControl(false));
    });
    this.modalApplicationAreaNew.config= {class : 'modal-dialog-centered', }
    
    this.modalApplicationAreaNew.show();
  }

  async addApplicationAreas() {
    this.loadingNew = true;
    let err = 0;

    const selectedOrderIds = this.newApplicationAreaForm.value.app_areas
      .map((v, i) => v ? this.areas_form[i].id_area : null)
      .filter(v => v !== null);

    for (let selected of selectedOrderIds) {
      let data = {
        questionnaire_application_id: this.idQuestionnaireApplication,
        area_id: selected,
        target_answers: 0,
        current_answers: 0,
      };
      const res = await this.appAreaService.post(data);
      if (!res.success) {
        err++;
      }
    }

    if (err === 0) {
      this.offset = 0;
      this.limitDown = 1;
      this.limitUp = this.limit;
      this.getApplicationAreas(1);
      this.notifierService.notify('success', 'Áreas de Aplicación añadidas');
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }

    this.modalApplicationAreaNew.hide();
    this.loadingNew = false;
  }

  clearSelectAreas() {
    while ((<FormArray>this.newApplicationAreaForm.controls['app_areas']).length !== 0) {
      (<FormArray>this.newApplicationAreaForm.controls['app_areas']).removeAt(0)
    }
  }

  getDiffAreas() {
    return this.areas.filter((area) => {
      if (this.application_areas.map((ap) => ap.area_id).indexOf(area.id_area) === -1) {
        return area;
      }
    });
  }

  clearValues() {
    this.newApplicationAreaForm.controls.area_id.setValue('');
    this.newApplicationAreaForm.controls.target_answers.setValue('');
    this.newApplicationAreaForm.controls.current_answers.setValue('');
  }


}
