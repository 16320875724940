import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BusinessService } from '../../services/business.service';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html'
})
export class BusinessDetailsComponent implements OnInit {

  business: any;
  editBusinessForm: FormGroup;
  canEdit = false;
  idBusiness;
  loadingUpdate = false;
  aux_user: any;

  constructor(private activatedRoute: ActivatedRoute, private businessService: BusinessService, private router: Router,
    private notifierService: NotifierService, private authService: AuthService, private errService: ErrorService) {
    this.editBusinessForm = new FormGroup({
      name: new FormControl('', Validators.required),
      client: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.disableForm();
    const id = this.activatedRoute.snapshot.paramMap.get('id_business');
    this.idBusiness = id;
    this.getBusiness(id);
  }

  setValues() {
    this.editBusinessForm.controls['name'].setValue(this.business.name);
    this.editBusinessForm.controls['client'].setValue(this.business.client);
  }

  backBusiness() {
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/', this.business.client_id]);
    } else {
      this.router.navigate(['/client/business/']);
    }
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  async getBusiness(id) {
    const res = await this.businessService.getById(id);
    if (res.success) {
      this.business = res.data.business;
      this.setValues();
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  disableForm() {
    this.editBusinessForm.controls['name'].disable();
    this.editBusinessForm.controls['client'].disable();
  }

  enableForm() {
    this.editBusinessForm.controls['name'].enable();
  }

  async editBusiness() {
    this.loadingUpdate = true;
    const data = {
      name: this.editBusinessForm.controls['name'].value,
    };

    const res = await this.businessService.patchById(this.idBusiness, data);
    this.loadingUpdate = false;
    if (res.success) {
      this.getBusiness(this.idBusiness);
      this.setValues();
      this.disableForm();
      this.canEdit = false;
      this.notifierService.notify('success', 'Datos actualizados');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  cancelEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }

}
