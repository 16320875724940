import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationAreaService {

  constructor(private http: HttpClient) { }

  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cn035')
      })
    };
  }

  getByQuestionnaireApplication(id, limit, offset) {
    if (limit !== null) {
      return this.http.get(`${apiUrl}/applicationarea/questionnaireapplication/${id}?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader()).
        toPromise()
        .then(res => res)
        .catch(err => err.error);
    } else {
      return this.http.get(`${apiUrl}/applicationarea/questionnaireapplication/${id}`, this.getAuthHeader()).
        toPromise()
        .then(res => res)
        .catch(err => err.error);
    }
  }

  post(data) {
    return this.http.post(`${apiUrl}/applicationarea/`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  deleteById(id) {
    return this.http.delete(`${apiUrl}/applicationarea/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getById(id) {
    return this.http.get(`${apiUrl}/applicationarea/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  patchById(id, data) {
    return this.http.patch(`${apiUrl}/applicationarea/${id}`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }
}
