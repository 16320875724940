import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  loginRequest(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic Y3VlbnRhbWU6UnNla054Z3FNVHRteUJIQmtxZmp3enFTTXJudVBkRWU='
      })
    };
    return this.http.post(`${apiUrl}/login`, data, httpOptions).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  reauth() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cn035')
      })
    };
    return this.http.get<{ success: boolean, data: any }>(`${apiUrl}/login/reauthenticate`, httpOptions);
  }

  actionReauth() {
    this.reauth().subscribe( res => {
        localStorage.setItem('cn035', res.data.token);
        localStorage.setItem('cn035_exp', res.data.exp);
    });
  }

  saveLoginData(userType, token, exp) {
    if (userType === 1) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/client']);
    }
    localStorage.setItem('cn035', token);
    localStorage.setItem('cn035_exp', exp);
  }

  logout() {
    localStorage.removeItem('cn035');
    localStorage.removeItem('cn035_exp');
    this.router.navigate(['']);
  }

  getUser() {
    return this.parseJwt(localStorage.getItem('cn035'));
  }

  adminLogged() {
    if (localStorage.getItem('cn035') !== undefined) {
      const user = this.parseJwt(localStorage.getItem('cn035'));
      return user.user_type_id === 1 && (new Date(user.exp * 1000) >= new Date());
    } else {
      return false;
    }
  }

  clientLogged() {
    if (localStorage.getItem('cn035') !== undefined) {
      const user = this.parseJwt(localStorage.getItem('cn035'));
      return user.user_type_id === 2 && (new Date(user.exp * 1000) >= new Date());
    } else {
      return false;
    }
  }

  isAdmin() {
    const user = this.parseJwt(localStorage.getItem('cn035'));
    return user.user_type_id === 1;
  }

  isClient() {
    const user = this.parseJwt(localStorage.getItem('cn035'));
    return user.user_type_id === 2;
  }

  parseJwt(token) {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } else {
      return {};
    }
  }
}
