import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  constructor(private http: HttpClient) { }

  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cn035')
      })
    };
  }

  getByClient(id, limit, offset) {
    return this.http.get(`${apiUrl}/questionnaireapplication/client/${id}?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader())
      .toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getById(id) {
    return this.http.get(`${apiUrl}/questionnaireapplication/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  post(data) {
    return this.http.post(`${apiUrl}/questionnaireapplication/`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  patchById(id, data) {
    return this.http.patch(`${apiUrl}/questionnaireapplication/${id}`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  deleteById(id) {
    return this.http.delete(`${apiUrl}/questionnaireapplication/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getStatuses() {
    return this.http.get(`${apiUrl}/questionnaireapplication/statuses`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getSubTypes() {
    return this.http.get(`${apiUrl}/questionnaireapplication/subsettypes`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getApplicationStatuses() {
    return this.http.get(`${apiUrl}/questionnaireapplication/applicationstatuses`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getApplicationTypes() {
    return this.http.get(`${apiUrl}/questionnaireapplication/applicationtypes`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getQuestionnares() {
    return this.http.get(`${apiUrl}/questionnaire/all`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getSocioDemographics(id) {
    return this.http.get(`${apiUrl}/applicationsociodemographic/questionnaire/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  patchSocioDemographics(id, data) {
    return this.http.patch(`${apiUrl}/applicationsociodemographic/${id}`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getFullResults(id, type) {
    return this.http.get(`${apiUrl}/questionnaireresult/${id}/q${type}/full` , this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getFullResultsQ23(type, id) {
    return this.http.get(`${apiUrl}/questionnaireresult/${id}/q${type}/full`, this.getAuthHeader()).toPromise()
    .then(res => res)
    .catch(err => err.error);
  }

  sendMail(id, body) {
    return this.http.post(`${apiUrl}/questionnaireapplication/${id}/send`, body, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  setSelected(id) {
    return this.http.post(`${apiUrl}/questionnaireapplication/${id}/setselected`, null, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  setUnselected(id) {
    return this.http.post(`${apiUrl}/questionnaireapplication/${id}/unsetselected`, null, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getGenderStats(id) {
    return this.http.get(`${apiUrl}/questionnaireapplication/${id}/genderstats`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  checkSample(id) {
    return this.http.get(`${apiUrl}/questionnaireapplication/${id}/checksample`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  setSample(id) {
    return this.http.post(`${apiUrl}/questionnaireapplication/${id}/sample`, null, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  setTotalPopulaltion(id) {
    return this.http.post(`${apiUrl}/questionnaireapplication/${id}/totalpopulation`, null, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  generateAccessCodes(id){
    return this.http.post(`${apiUrl}/questionnaireapplication/${id}/accesscodes`,null,this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error)
  }
}
