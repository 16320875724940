import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ClientService } from 'src/app/services/client.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-usuario-details',
  templateUrl: './usuario-details.component.html'
})
export class UsuarioDetailsComponent implements OnInit {

  usuario: any;
  editUserForm: FormGroup;
  canEdit = false;
  idUser;
  loadingUpdate = false;
  user_types = [];
  clients = [];

  constructor(private activatedRoute: ActivatedRoute, private usuarioService: UsuarioService, private router: Router,
    private notifierService: NotifierService, private clientService: ClientService, private errService: ErrorService) {
    this.editUserForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      client_id: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      user_type_id: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      password1: new FormControl(''),
      password2: new FormControl(''),
    });
    this.getUserTypes();
    this.getClients();
  }

  ngOnInit() {
    this.disableForm();
    const id = this.activatedRoute.snapshot.paramMap.get('id_user');
    this.idUser = id;
    this.getUser(id);
  }

  setValues() {
    this.editUserForm.controls['first_name'].setValue(this.usuario.first_name);
    this.editUserForm.controls['last_name'].setValue(this.usuario.last_name);
    this.editUserForm.controls['client_id'].setValue(this.usuario.client_id);
    this.editUserForm.controls['phone'].setValue(this.usuario.phone);
    this.editUserForm.controls['user_type_id'].setValue(this.usuario.user_type_id);
    this.editUserForm.controls['email'].setValue(this.usuario.email);
    this.editUserForm.controls['password1'].setValue('');
    this.editUserForm.controls['password2'].setValue('');
  }

  async getUser(id) {
    const res = await this.usuarioService.getById(id);
    if (res.success) {
      this.usuario = res.data.user;
      this.setValues();
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getUserTypes() {
    const res = await this.usuarioService.getTypes();
    if (res.success) {
      this.user_types = res.data.user_types;
    }
  }

  async getClients() {
    const res = await this.clientService.getAllCatalog();
    if (res.success) {
      this.clients = res.data.clients;
    }
  }

  backUser() {
    this.router.navigate(['/admin/usuarios']);
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  disableForm() {
    this.editUserForm.controls['first_name'].disable();
    this.editUserForm.controls['last_name'].disable();
    this.editUserForm.controls['client_id'].disable();
    this.editUserForm.controls['phone'].disable();
    this.editUserForm.controls['user_type_id'].disable();
    this.editUserForm.controls['email'].disable();
    this.editUserForm.controls['password1'].disable();
    this.editUserForm.controls['password2'].disable();
  }

  enableForm() {
    this.editUserForm.controls['first_name'].enable();
    this.editUserForm.controls['last_name'].enable();
    this.editUserForm.controls['client_id'].enable();
    this.editUserForm.controls['phone'].enable();
    this.editUserForm.controls['user_type_id'].enable();
    this.editUserForm.controls['email'].enable();
    this.editUserForm.controls['password1'].enable();
    this.editUserForm.controls['password2'].enable();
  }

  async edit() {
    this.loadingUpdate = true;
    const data = {
      first_name: this.editUserForm.controls['first_name'].value,
      last_name: this.editUserForm.controls['last_name'].value,
      client_id: this.editUserForm.controls['client_id'].value,
      phone: this.editUserForm.controls['phone'].value,
      user_type_id: this.editUserForm.controls['user_type_id'].value,
      email: this.editUserForm.controls['email'].value,
    };

    const pass = this.validatePassword();
    let err = 0;

    if (pass !== 2) {
      if (pass === 3) {
        data['password'] = this.editUserForm.controls['password1'].value;
      } else {
        this.notifierService.notify('error', 'La contraseña no coincide');
        this.loadingUpdate = false;
        err = 1;
      }
    }

    if (err === 0) {
      const res = await this.usuarioService.patchById(this.idUser, data);
      this.loadingUpdate = false;
      if (res.success) {
        this.getUser(this.idUser);
        this.setValues();
        this.disableForm();
        this.canEdit = false;
        this.notifierService.notify('success', 'Datos actualizados');
      } else {
        this.notifierService.notify('error', this.errService.getError(res.error.code));
      }
    }
  }

  validatePassword() {
    if (this.editUserForm.controls['password1'].value === this.editUserForm.controls['password2'].value) {
      if (this.editUserForm.controls['password1'].value.length !== 0 && this.editUserForm.controls['password2'].value.length !== 0) {
        return 3;
      } else {
        return 2;
      }
    } else {
      return 1;
    }
  }

  cancelEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }

}
