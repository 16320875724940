import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormControl } from '@angular/forms';
import { EmployeeService } from '../../services/employee.service';
import { AreaService } from 'src/app/services/area.service';
import { BusinessService } from 'src/app/services/business.service';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { ModalDirective } from 'ngx-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html'
})
export class EmployeeDetailsComponent implements OnInit {

  employee: any;
  aux_user: any;
  editEmployeeForm: FormGroup;
  canEdit = false;
  idEmployee;
  loadingUpdate = false;
  loadingReady = false;
  @ViewChild('modalSendMailTemplate') public modalSendMailTemplate: ModalDirective;
  @ViewChild('modalConfirmNow') public modalConfirmNow: ModalDirective;
  @ViewChild('modalConfirmTemplate') public modalConfirmTemplate: ModalDirective;
  subjectTemplateMail = '';
  loadingMail = false;
  template = '';
  // For Selects
  genders = [];
  ages = [];
  civil_statuses = [];
  education_levels = [];
  areas = [];
  businesses = [];
  position_types = [];
  recruitment_types = [];
  employee_types = [];
  working_hours_types = [];
  working_hours_rotations = [];
  working_experiences = [];
  current_position_times = [];
  answer_statuses = [];
  states = [];
  public isManual : boolean = false;

  constructor(private employeeService: EmployeeService, private router: Router, private notifierService: NotifierService,
    private areaService: AreaService, private businessService: BusinessService, private questionarioService: QuestionnaireService, private activatedRoute: ActivatedRoute,
    private authService: AuthService, private errService: ErrorService) {
    this.editEmployeeForm = new FormGroup({
      application_email: new FormControl(''), //
      email: new FormControl(''), //
      user: new FormControl(''), //
      access: new FormControl(''), //
      access_code: new FormControl(''), //
      cellphone_prefix: new FormControl(''), //
      cellphone: new FormControl(''), //
      name: new FormControl(''), //
      selected_for_application: new FormControl(''), //
      gender_id: new FormControl(''), //
      age: new FormControl(''), //
      age_id: new FormControl(''), //
      civil_status_id: new FormControl(''), //
      education_level_id: new FormControl(''),
      occupation: new FormControl(''),
      area_id: new FormControl(''), //
      business_id: new FormControl(''), //
      position_type_id: new FormControl(''), //
      recruitment_type_id: new FormControl(''), // 
      employee_type_id: new FormControl(''), //
      working_hours_type_id: new FormControl(''), // 
      working_hours_rotation_id: new FormControl(''), //
      work_experience_id: new FormControl(''), // 
      current_position_time_id: new FormControl(''), //
      answer_status_id: new FormControl(''), // 
      questionnaire_sent_at: new FormControl(''), //
      token: new FormControl(''), //
      state_id: new FormControl(''), //
      questionnaire_answered_at: new FormControl(''), //
      number_of_sents: new FormControl(''), //
    });
    this.idEmployee = this.activatedRoute.snapshot.paramMap.get('id_emp');
    this.aux_user = this.authService.getUser();
    this.getGenders();
    this.getAges();
    this.getCivilStatuses();
    this.getEducationLevels();
    this.getPositionTypes();
    this.getRecruitmentTypes();
    this.getEmployeeTypes();
    this.getWorkingHoursTypes();
    this.getWorkingHoursRotations();
    this.getWorkExperences();
    this.getPositionTimes();
    this.getAnswerStatuses();
    this.getStates();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.getEmployee();
    this.disableForm();
  }

  backEmployee() {
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/cuestionario', this.employee.questionnaire_application_id]);
    } else {
      this.router.navigate(['/client/cuestionarios/', this.employee.questionnaire_application_id]);
    }
  }

  async getEmployee() {
    this.loadingReady = false;
    const res = await this.employeeService.getById(this.idEmployee);
    if (res.success) {
      this.employee = res.data.employee;
      this.setIdClient(res.data.employee.questionnaire_application_id);
      this.setValues();
      this.loadingReady = true;
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async setIdClient(id) {
    const res = await this.questionarioService.getById(id);
    if (res.success) {
      this.isManual = res.data.questionnaire_application.application_type_id === 2 ? true : false ;
      this.getAreas(res.data.questionnaire_application.client_id);
      this.getBusinesses(res.data.questionnaire_application.client_id);
    }
  }

  async getAreas(id) {
    const res = await this.areaService.getByClientCatalog(id);
    if (res.success) {
      this.areas = res.data.areas;
    }
  }

  async getBusinesses(id) {
    const res = await this.businessService.getByClientCatalog(id);
    if (res.success) {
      this.businesses = res.data.businesses;
    }
  }

  async getGenders() {
    const res = await this.employeeService.getGenders();
    if (res.success) {
      this.genders = res.data.genders;
    }
  }

  async getAges() {
    const res = await this.employeeService.getAges();
    if (res.success) {
      this.ages = res.data.ages;
    }
  }

  async getCivilStatuses() {
    const res = await this.employeeService.getCivilStatuses();
    if (res.success) {
      this.civil_statuses = res.data.civil_statuses;
    }
  }

  async getEducationLevels() {
    const res = await this.employeeService.getEducationLevels();
    if (res.success) {
      this.education_levels = res.data.education_levels;
    }
  }

  async getPositionTypes() {
    const res = await this.employeeService.getPositionTypes();
    if (res.success) {
      this.position_types = res.data.position_types;
    }
  }

  async getRecruitmentTypes() {
    const res = await this.employeeService.getRecruitmentTypes();
    if (res.success) {
      this.recruitment_types = res.data.recruitment_types;
    }
  }

  async getEmployeeTypes() {
    const res = await this.employeeService.getEmployeeTypes();
    if (res.success) {
      this.employee_types = res.data.employee_types;
    }
  }

  async getWorkingHoursTypes() {
    const res = await this.employeeService.getWorkingHoursTypes();
    if (res.success) {
      this.working_hours_types = res.data.working_hours_types;
    }
  }

  async getWorkingHoursRotations() {
    const res = await this.employeeService.getWorkingHoursRotations();
    if (res.success) {
      this.working_hours_rotations = res.data.working_hours_rotations;
    }
  }

  async getWorkExperences() {
    const res = await this.employeeService.getWorkExperiences();
    if (res.success) {
      this.working_experiences = res.data.working_experiences;
    }
  }

  async getPositionTimes() {
    const res = await this.employeeService.getWorkCurrentPositionTimes();
    if (res.success) {
      this.current_position_times = res.data.current_position_times;
    }
  }

  async getAnswerStatuses() {
    const res = await this.employeeService.getAnswerStatuses();
    if (res.success) {
      this.answer_statuses = res.data.answer_statuses;
    }
  }

  async getStates() {
    const res = await this.employeeService.getStates();
    if (res.success) {
      this.states = res.data.states;
    }
  }

  showNowMailModal() {
    this.modalConfirmNow.config = { ignoreBackdropClick: true };
    this.modalConfirmNow.show();
  }

  showTemplateMailModal() {
    this.subjectTemplateMail = '';
    this.template = '';
    this.modalSendMailTemplate.show();
    this.modalConfirmTemplate.config = { ignoreBackdropClick: true, keyboard: false }
  }

  setValues() {
    this.editEmployeeForm.controls['application_email'].setValue(this.employee.application_email);
    this.editEmployeeForm.controls['email'].setValue(this.employee.email);
    this.editEmployeeForm.controls['user'].setValue(this.employee.user);
    this.editEmployeeForm.controls['cellphone_prefix'].setValue(this.employee.cellphone_prefix);
    this.editEmployeeForm.controls['cellphone'].setValue(this.employee.cellphone);
    this.editEmployeeForm.controls['name'].setValue(this.employee.name);
    this.editEmployeeForm.controls['selected_for_application'].setValue(this.employee.selected_for_application);
    this.editEmployeeForm.controls['gender_id'].setValue(this.employee.gender_id);
    this.editEmployeeForm.controls['age'].setValue(this.employee.age);
    this.editEmployeeForm.controls['age_id'].setValue(this.employee.age_id);
    this.editEmployeeForm.controls['civil_status_id'].setValue(this.employee.civil_status_id);
    this.editEmployeeForm.controls['education_level_id'].setValue(this.employee.education_level_id);
    this.editEmployeeForm.controls['occupation'].setValue(this.employee.occupation);
    this.editEmployeeForm.controls['area_id'].setValue(this.employee.area_id);
    this.editEmployeeForm.controls['business_id'].setValue(this.employee.business_id);
    this.editEmployeeForm.controls['position_type_id'].setValue(this.employee.position_type_id);
    this.editEmployeeForm.controls['recruitment_type_id'].setValue(this.employee.recruitment_type_id);
    this.editEmployeeForm.controls['employee_type_id'].setValue(this.employee.employee_type_id);
    this.editEmployeeForm.controls['working_hours_type_id'].setValue(this.employee.working_hours_type_id);
    this.editEmployeeForm.controls['working_hours_rotation_id'].setValue(this.employee.working_hours_rotation_id);
    this.editEmployeeForm.controls['work_experience_id'].setValue(this.employee.work_experience_id);
    this.editEmployeeForm.controls['current_position_time_id'].setValue(this.employee.current_position_time_id);
    this.editEmployeeForm.controls['answer_status_id'].setValue(this.employee.answer_status_id);
    this.editEmployeeForm.controls['state_id'].setValue(this.employee.state_id);
    this.editEmployeeForm.controls['access_code'].setValue(this.employee.access_code);
    if (this.employee.access !== null ) {
      this.editEmployeeForm.controls['access'].setValue('calette123');
    }
    if (this.employee.questionnaire_sent_at !== '0000-00-00 00:00:00' && this.employee.questionnaire_sent_at !== null) {
      // tslint:disable-next-line: max-line-length
      this.editEmployeeForm.controls['questionnaire_sent_at'].setValue(new Date(this.employee.questionnaire_sent_at).toISOString().substring(0, 10));
    }
    this.editEmployeeForm.controls['token'].setValue(this.employee.token);
    if (this.employee.questionnaire_sent_at !== '0000-00-00 00:00:00' && this.employee.questionnaire_sent_at !== null) {
      // tslint:disable-next-line: max-line-length
      this.editEmployeeForm.controls['questionnaire_answered_at'].setValue(new Date(this.employee.questionnaire_answered_at).toISOString().substring(0, 10));
    }
    this.editEmployeeForm.controls['number_of_sents'].setValue(this.employee.number_of_sents);
  }

  disableForm() {
    this.editEmployeeForm.controls['application_email'].disable();
    this.editEmployeeForm.controls['email'].disable();
    this.editEmployeeForm.controls['user'].disable();
    this.editEmployeeForm.controls['access'].disable();
    this.editEmployeeForm.controls['access_code'].disable();
    this.editEmployeeForm.controls['cellphone_prefix'].disable();
    this.editEmployeeForm.controls['cellphone'].disable();
    this.editEmployeeForm.controls['name'].disable();
    this.editEmployeeForm.controls['selected_for_application'].disable();
    this.editEmployeeForm.controls['gender_id'].disable();
    this.editEmployeeForm.controls['age'].disable();
    this.editEmployeeForm.controls['age_id'].disable();
    this.editEmployeeForm.controls['civil_status_id'].disable();
    this.editEmployeeForm.controls['education_level_id'].disable();
    this.editEmployeeForm.controls['occupation'].disable();
    this.editEmployeeForm.controls['area_id'].disable();
    this.editEmployeeForm.controls['business_id'].disable();
    this.editEmployeeForm.controls['position_type_id'].disable();
    this.editEmployeeForm.controls['recruitment_type_id'].disable();
    this.editEmployeeForm.controls['employee_type_id'].disable();
    this.editEmployeeForm.controls['working_hours_type_id'].disable();
    this.editEmployeeForm.controls['working_hours_rotation_id'].disable();
    this.editEmployeeForm.controls['work_experience_id'].disable();
    this.editEmployeeForm.controls['current_position_time_id'].disable();
    this.editEmployeeForm.controls['answer_status_id'].disable();
    this.editEmployeeForm.controls['questionnaire_sent_at'].disable();
    this.editEmployeeForm.controls['token'].disable();
    this.editEmployeeForm.controls['questionnaire_answered_at'].disable();
    this.editEmployeeForm.controls['number_of_sents'].disable();
    this.editEmployeeForm.controls['state_id'].disable();
  }

  enableForm() {
    this.editEmployeeForm.controls['application_email'].enable();
    this.editEmployeeForm.controls['email'].enable();
    this.editEmployeeForm.controls['user'].enable();
    this.editEmployeeForm.controls['access'].enable();
    this.editEmployeeForm.controls['access_code'].enable();
    this.editEmployeeForm.controls['cellphone_prefix'].enable();
    this.editEmployeeForm.controls['cellphone'].enable();
    this.editEmployeeForm.controls['name'].enable();
    this.editEmployeeForm.controls['selected_for_application'].enable();
    this.editEmployeeForm.controls['gender_id'].enable();
    this.editEmployeeForm.controls['age'].enable();
    this.editEmployeeForm.controls['age_id'].enable();
    this.editEmployeeForm.controls['civil_status_id'].enable();
    this.editEmployeeForm.controls['education_level_id'].enable();
    this.editEmployeeForm.controls['occupation'].enable();
    this.editEmployeeForm.controls['area_id'].enable();
    this.editEmployeeForm.controls['business_id'].enable();
    this.editEmployeeForm.controls['position_type_id'].enable();
    this.editEmployeeForm.controls['recruitment_type_id'].enable();
    this.editEmployeeForm.controls['employee_type_id'].enable();
    this.editEmployeeForm.controls['working_hours_type_id'].enable();
    this.editEmployeeForm.controls['working_hours_rotation_id'].enable();
    this.editEmployeeForm.controls['work_experience_id'].enable();
    this.editEmployeeForm.controls['current_position_time_id'].enable();
    this.editEmployeeForm.controls['answer_status_id'].enable();
    this.editEmployeeForm.controls['questionnaire_sent_at'].enable();
    this.editEmployeeForm.controls['token'].enable();
    this.editEmployeeForm.controls['questionnaire_answered_at'].enable();
    this.editEmployeeForm.controls['number_of_sents'].enable();
    this.editEmployeeForm.controls['state_id'].enable();
  }

  async editEmployee() {
    this.loadingUpdate = true;
    
    const data = this.getPatchData();

    const res = await this.employeeService.patchById(this.idEmployee, data);
    this.loadingUpdate = false;
    if (res.success) {
      this.getEmployee();
      this.setValues();
      this.disableForm();
      this.canEdit = false;
      this.notifierService.notify('success', 'Datos actualizados');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  cancelEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }

  getPatchData() {
    const data = {};

    if (this.editEmployeeForm.controls['selected_for_application'].value !== '' &&
        this.editEmployeeForm.controls['selected_for_application'].value !== this.employee.selected_for_application) {
      data['selected_for_application'] = this.editEmployeeForm.controls['selected_for_application'].value;
    }
    if (this.editEmployeeForm.controls['application_email'].value !== '' && 
        this.editEmployeeForm.controls['application_email'].value !== this.employee.application_email) {
      data['application_email'] = this.editEmployeeForm.controls['application_email'].value;
    }
    if (this.editEmployeeForm.controls['area_id'].value !== '' &&
      this.editEmployeeForm.controls['area_id'].value !== this.employee.area_id) {
      data['area_id'] = this.editEmployeeForm.controls['area_id'].value;
    }
    if (this.editEmployeeForm.controls['business_id'].value !== '' &&
      this.editEmployeeForm.controls['business_id'].value !== this.employee.business_id) {
      data['business_id'] = this.editEmployeeForm.controls['business_id'].value;
    }
    if (this.editEmployeeForm.controls['email'].value !== '' &&
        this.editEmployeeForm.controls['email'].value !== this.employee.email) {
      data['email'] = this.editEmployeeForm.controls['email'].value;
    }
    if (this.editEmployeeForm.controls['user'].value !== '' &&
        this.editEmployeeForm.controls['user'].value !== this.employee.user) {
      data['user'] = this.editEmployeeForm.controls['user'].value;
    }
    if (this.editEmployeeForm.controls['access'].value !== '' && this.editEmployeeForm.controls['access'].value !== 'calette123' &&
      this.editEmployeeForm.controls['access'].value !== this.employee.access) {
      data['access'] = this.editEmployeeForm.controls['access'].value;
    }
    if (this.editEmployeeForm.controls['access_code'].value !== '' && 
      this.editEmployeeForm.controls['access_code'].value !== this.employee.access_code) {
      data['access_code'] = this.editEmployeeForm.controls['access_code'].value;
    }
    if (this.editEmployeeForm.controls['cellphone_prefix'].value !== '' &&
        this.editEmployeeForm.controls['cellphone_prefix'].value !== this.employee.cellphone_prefix) {
      data['cellphone_prefix'] = this.editEmployeeForm.controls['cellphone_prefix'].value;
    }
    if (this.editEmployeeForm.controls['cellphone'].value !== '' &&
        this.editEmployeeForm.controls['cellphone'].value !== this.employee.cellphone) {
      data['cellphone'] = this.editEmployeeForm.controls['cellphone'].value;
    }
    if (this.editEmployeeForm.controls['name'].value !== '' &&
        this.editEmployeeForm.controls['name'].value !== this.employee.name) {
      data['name'] = this.editEmployeeForm.controls['name'].value;
    }
    if (this.editEmployeeForm.controls['age'].value !== '' &&
      this.editEmployeeForm.controls['age'].value !== 0 &&
      this.editEmployeeForm.controls['age'].value !== this.employee.age) {
      data['age'] = this.editEmployeeForm.controls['age'].value;
    }
    if (this.editEmployeeForm.controls['occupation'].value !== '' &&
        this.editEmployeeForm.controls['occupation'].value !== this.employee.occupation) {
      data['occupation'] = this.editEmployeeForm.controls['occupation'].value;
    }
    if (this.editEmployeeForm.controls['gender_id'].value !== '' &&
        this.editEmployeeForm.controls['gender_id'].value != this.employee.gender_id) {
      data['gender_id'] = this.editEmployeeForm.controls['gender_id'].value;
    }
    if (this.editEmployeeForm.controls['age_id'].value !== '' &&
        this.editEmployeeForm.controls['age_id'].value != this.employee.age_id) {
      data['age_id'] = this.editEmployeeForm.controls['age_id'].value;
    }
    if (this.editEmployeeForm.controls['civil_status_id'].value !== '' &&
        this.editEmployeeForm.controls['civil_status_id'].value != this.employee.civil_status_id) {
      data['civil_status_id'] = this.editEmployeeForm.controls['civil_status_id'].value;
    }
    if (this.editEmployeeForm.controls['education_level_id'].value !== '' &&
        this.editEmployeeForm.controls['education_level_id'].value != this.employee.education_level_id) {
      data['education_level_id'] = this.editEmployeeForm.controls['education_level_id'].value;
    }
    if (this.editEmployeeForm.controls['position_type_id'].value !== '' &&
        this.editEmployeeForm.controls['position_type_id'].value != this.employee.position_type_id) {
      data['position_type_id'] = this.editEmployeeForm.controls['position_type_id'].value;
    }
    if (this.editEmployeeForm.controls['recruitment_type_id'].value !== '' &&
        this.editEmployeeForm.controls['recruitment_type_id'].value != this.employee.recruitment_type_id) {
      data['recruitment_type_id'] = this.editEmployeeForm.controls['recruitment_type_id'].value;
    }
    if (this.editEmployeeForm.controls['employee_type_id'].value !== '' &&
        this.editEmployeeForm.controls['employee_type_id'].value != this.employee.employee_type_id) {
      data['employee_type_id'] = this.editEmployeeForm.controls['employee_type_id'].value;
    }
    if (this.editEmployeeForm.controls['working_hours_type_id'].value !== '' &&
        this.editEmployeeForm.controls['working_hours_type_id'].value != this.employee.working_hours_type_id) {
      data['working_hours_type_id'] = this.editEmployeeForm.controls['working_hours_type_id'].value;
    }
    if (this.editEmployeeForm.controls['working_hours_rotation_id'].value !== '' &&
        this.editEmployeeForm.controls['working_hours_rotation_id'].value != this.employee.working_hours_rotation_id) {
      data['working_hours_rotation_id'] = this.editEmployeeForm.controls['working_hours_rotation_id'].value;
    }
    if (this.editEmployeeForm.controls['work_experience_id'].value !== '' &&
        this.editEmployeeForm.controls['work_experience_id'].value !== this.employee.work_experience_id) {
      data['work_experience_id'] = this.editEmployeeForm.controls['work_experience_id'].value;
    }
    if (this.editEmployeeForm.controls['current_position_time_id'].value !== '' &&
        this.editEmployeeForm.controls['current_position_time_id'].value != this.employee.current_position_time_id) {
      data['current_position_time_id'] = this.editEmployeeForm.controls['current_position_time_id'].value;
    }
    if (this.editEmployeeForm.controls['answer_status_id'].value !== '' &&
        this.editEmployeeForm.controls['answer_status_id'].value != this.employee.answer_status_id) {
      data['answer_status_id'] = this.editEmployeeForm.controls['answer_status_id'].value;
    }
    if (this.editEmployeeForm.controls['state_id'].value !== '' &&
      this.editEmployeeForm.controls['state_id'].value != this.employee.state_id) {
      data['state_id'] = this.editEmployeeForm.controls['state_id'].value;
    }
    if (this.editEmployeeForm.controls['questionnaire_sent_at'].value !== '' &&
      this.editEmployeeForm.controls['questionnaire_sent_at'].value !== new Date(this.employee.questionnaire_sent_at).toISOString().substring(0, 10)) {
      data['questionnaire_sent_at'] = this.editEmployeeForm.controls['questionnaire_sent_at'].value;
    }
    if (this.editEmployeeForm.controls['token'].value !== '' && 
        this.editEmployeeForm.controls['token'].value !== this.employee.token) {
      data['token'] = this.editEmployeeForm.controls['token'].value;
    }
    if (this.editEmployeeForm.controls['questionnaire_answered_at'].value !== '' &&
      this.editEmployeeForm.controls['questionnaire_answered_at'].value !== new Date(this.employee.questionnaire_answered_at).toISOString().substring(0, 10)) {
      data['questionnaire_answered_at'] = this.editEmployeeForm.controls['questionnaire_answered_at'].value;
    }
    if (this.editEmployeeForm.controls['number_of_sents'].value !== '' &&
        this.editEmployeeForm.controls['number_of_sents'].value !== 0 &&
        this.editEmployeeForm.controls['number_of_sents'].value !== this.employee.number_of_sents) {
      data['number_of_sents'] = this.editEmployeeForm.controls['number_of_sents'].value;
    }

    return data;
  }

  async sendNowMail() {
    this.loadingMail = true;
    const res = await this.employeeService.sendMail(this.idEmployee, {});
    this.loadingMail = false;
    this.modalConfirmNow.hide();
    if (res.success) {
      this.notifierService.notify('success', 'Correo envíado');
    } else {
      this.notifierService.notify('error', 'No se pudo enviar el correo');
    }
  }

  async sendTemplateMail() {
    this.loadingMail = true;
    const res = await this.employeeService.sendMail(this.idEmployee, { subject: this.subjectTemplateMail, tempalte: this.template });
    this.loadingMail = false;
    this.closeTemplateModals();
    if (res.success) {
      this.notifierService.notify('success', 'Correo envíado');
    } else {
      this.notifierService.notify('error', 'No se pudo enviar el correo');
    }
  }

  closeTemplateModals() {
    this.modalConfirmTemplate.hide();
    this.modalSendMailTemplate.hide();
  }

}
