import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('cn035')
      })
    };
  }

  getAllCatalog() {
    return this.http.get(`${apiUrl}/client/all`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getAll(limit, offset) {
    return this.http.get(`${apiUrl}/client/all?limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader())
      .toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getByName(q_name, limit, offset) {
    return this.http.get(`${apiUrl}/client/name?name=${q_name}&limit=${limit}&offset=${offset}&order=0`, this.getAuthHeader())
      .toPromise()
      .then(res => res)
      .catch(err => err.error);
  }
  
  getById(id) {
    return this.http.get(`${apiUrl}/client/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  patchById(id, data) {
    return this.http.patch(`${apiUrl}/client/${id}`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  deleteById(id) {
    return this.http.delete(`${apiUrl}/client/${id}`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  getMe() {
    return this.http.get(`${apiUrl}/client/me`, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  post(data) {
    return this.http.post(`${apiUrl}/client/`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }
  
  askForSignedUrl(id, data) {
    return this.http.post(`${apiUrl}/client/${id}/logo`, data, this.getAuthHeader()).toPromise()
      .then(res => res)
      .catch(err => err.error);
  }

  putToSignedUrl(url, data) {
    return this.http.put(`${url}`, data).toPromise()
      .then(res => res)
      .catch(err => err.error);;
  }
}
