import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { AnswerService } from '../../../services/answer.service';
import { NotifierService } from 'angular-notifier';
import { OnlineStatusService } from 'src/app/services/online-status.service';
import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from 'src/app/constants/http-requests.const';
import { ModalDirective } from 'ngx-bootstrap';
import { RESTART_FROM_ORIGINAL_URL_MESSAGE } from 'src/app/constants/alert-messages.const';

@Component({
  selector: 'app-welcome-questionnaire',
  templateUrl: './welcome-questionnaire.component.html'
})
export class WelcomeQuestionnaireComponent implements OnInit {

  token: string;
  showNotAllowed = false;
  questionnaire: any;
  notQ1 = false;
  unified = false;
  name = '';
  errorMsg = '';
  instructions = [
    'Verifica tu identidad',
    'Proporciona tus datos',
    'Responde el Cuestionario'
  ]
  colors: any;
  start_msg = `<p>El objetivo es identificar a aquellas personas que han sido sujetas a acontecimientos traumático severos durante o
      con motivo de trabajo y canalizarlos para su atención clínica.</p>`;
  applicationUrl;
  showSpinner: boolean = false;
  errorModalData = {
    code: DEFAULT_ERROR_CODE,
    message: DEFAULT_ERROR_MESSAGE,
    title: DEFAULT_ERROR_MESSAGE
  } 
  @ViewChild('modalError') public modalError: ModalDirective;

  constructor(private route: ActivatedRoute, private answerService: AnswerService, private router: Router,
    private ref: ChangeDetectorRef, private notifierService: NotifierService, private onlineStatusService: OnlineStatusService) {
    this.colors = JSON.parse(sessionStorage.getItem('text_colors'));
    this.applicationUrl = JSON.parse(sessionStorage.getItem('ApplicationUrl'));
  }

  ngOnInit() {
    this.token = localStorage.getItem('cnq35');
    const loadError = this.route.snapshot.queryParamMap.get('load-error');
    if(loadError) {
      this.showNotAllowed = true;
      this.errorMsg = RESTART_FROM_ORIGINAL_URL_MESSAGE;
      return;
    }
    if (this.token !== undefined && this.token !== null && this.token !== '') {
      this.showSpinner = true;
      this.validateToken();
    }else if(this.applicationUrl !== undefined && this.applicationUrl !== null && this.applicationUrl !== ''){
      this.answerService.loadingClientDataListener.next(true);
      this.router.navigate(['/questionnaire/manual']);
    }else {
      this.showNotAllowed = true;
      this.errorMsg = 'No tienes acceso al cuestionario';
    }
  }

  async validateToken() {
    const res = await this.answerService.getData();
    if (res.success) {
      this.answerService.clientDataListener.next(res.data.client);
      this.questionnaire = res.data.questionnaire_application;
      const qType = res.data.questionnaire_application.questionnaire_id;
      if (res.data.employee.name !== null) {
        this.name = res.data.employee.name;
      }
      if (res.data.questionnaire_application.questionnaire_id == 2) {
        this.start_msg = `<p>El objetivo de la evaluación es hacer una identificación y análisis los Factores de Riesgo Psicosocial 
        para de esta manera establecer medidas de prevención y acciones de control de los factores de riesgo psicosocial, 
        de la violencia laboral y promoción del entorno organizacional favorable.</p>`;
        this.notQ1 = true;
      }
      if (res.data.questionnaire_application.questionnaire_id == 3) {
        this.start_msg = `<p>El objetivo de la evaluación es hacer una identificación y análisis los Factores de Riesgo Psicosocial 
        y evaluación del Entorno Organizacional para de esta manera establecer medidas de prevención y acciones de control de los factores 
        de riesgo psicosocial, de la violencia laboral y promoción del entorno organizacional favorable.</p>`;
        this.notQ1 = true;
      }
      if (res.data.questionnaire_application.questionnaire_id == 4 || res.data.questionnaire_application.questionnaire_id == 5) {
        this.notQ1 = true;
        this.unified = true;
        // this.start_msg = `
        //   <p>
        //     Te invitamos a responder los dos cuestionarios que solicita la Norma. Estos cuestionarios forman parte de las acciones para promover entornos organizacionales favorables
        //     y dar cumplimiento a la NOM-035-STPS-2018; por lo que tu participación es de carácter obligatoria.
        //   </p>
        //   <p>  
        //     Guía de Referencia I:  Cuestionario para identificar colaboradores sujetos a un acontecimiento traumático severo con motivo laboral.
        //   </p>
        //   <p>  
        //     Guía de Referencia II${res.data.questionnaire_application.questionnaire_id == 5 ? 'I' : ''}: Cuestionario para identificar y analizar los factores de riesgo psicosocial y evaluación del entorno organizacional favorable en la organización.
        //   </p>
        //   <p>
        //     El tiempo de respuesta de ambos cuestionarios es de aproximadamente 15 minutos por lo que te recomendamos contestarlos desde un lugar cómodo y privado.
        //   </p>
        //   <p>
        //     Queremos recordarte que tus respuestas serán completamente confidenciales.
        //   </p>`;

        // TEMPORARY CHANGES
        this.start_msg = `
          <p>
            Te invitamos a responder la encuesta NOM-035. Esta evaluación forma parte de las acciones para promover entornos organizacionales favorables y dar cumplimiento a la norma (NOM-035-STPS-2018).
          </p>
          <p>  
            La encuesta tiene como objetivo identificar acontecimientos traumáticos con motivo laboral y analizar los factores de riesgo psicosocial y evaluar el entorno organizacional.
          </p>
          <p>
            El tiempo de respuesta es aproximadamente de 10 minutos, por lo que te recomendamos contestarlos desde un lugar cómodo y privado.
          </p>
          <p>
            <b>Las respuestas son 100% confidenciales.</b>
          </p>`;
      }
      // if (res.data.questionnaire_application.questionnaire_id == 5) {
      //   this.notQ1 = true;
      //   this.unified = true;
      //   this.start_msg = `
      //     <p>
      //       La segunda fase de nuestra ruta de Bienestar en Acción ya está en marcha.
      //     </p>
      //     <p>  
      //       En esta ocasión, participaremos en colaboración con Cuéntame en la construcción de un entorno organizacional favorable.          
      //     </p>
      //     <p>  
      //       Cuéntame es un aliado certificado en implementación de la NOM-035 que identifica, previene y resuelve riesgos psicosociales, al brindar herramientas personalizadas que mejoran nuestro bienestar.
      //     </p>
      //     <p>
      //       Para lograrlo, responderemos 2 cuestionarios que corresponden a la NOM-035:
      //     </p>
      //     <p>
      //       Guía de Referencia I: Cuestionario para identificar colaboradores sujetos a un acontecimiento traumático severo con motivo laboral.          
      //     </p>
      //     <p>
      //       Guía de Referencia III: Cuestionario para identificar y analizar los factores de riesgo psicosocial y evaluación del entorno organizacional favorable en la organización.
      //     </p>
      //     <p>
      //       Los cuestionarios son 100% confidenciales y forman parte de las acciones para crear una cultura más fuerte y saludable para todos.          
      //     </p>
      //     <p>
      //       ¡Súmate a esta travesía!
      //     </p>`;
      // }
      if (res.data.questionnaire_application.start_message !== null &&
        res.data.questionnaire_application.start_message.length !== 0) {
        this.start_msg = res.data.questionnaire_application.start_message;
      }
      this.instructions = this.notQ1 ? [
        `Las preguntas debes de contestarlas conforme al orden que van apareciendo.`,
        `El cuestionario debe de responderse en su totalidad.`, 
        `Es importante que te encuentres en un ambiente en donde las condiciones de las instalaciones y mobiliario sean idóneas y permitan concentrarte.`, 
        `No existen respuestas correctas o incorrectas.`, 
        `Al responder las preguntas, debes de considerar las condiciones de los dos últimos meses. `,
        `Tu opinión es lo más importante por lo que te pedimos respondas las preguntas con sinceridad.`,
      ] : this.instructions;
    }
    else{
      switch (res.error.code) {
        case 435:
          this.errorMsg = 'El cuestionario ha sido completado';
          break;
        case 436:
          this.errorMsg = 'El tiempo para responder el cuestionario ha expirado';
          break;
        case 437:
          this.errorMsg = 'El cuestionario se ha cerrado debido a mantenimiento, te notificaremos en cuanto se habilite';
          break;
        default:
          this.errorMsg = 'Acceso invalido';
          break;
      }
      console.log(this.showNotAllowed, res.error.code, this.errorMsg);
      this.showNotAllowed = true;
      this.ref.detectChanges();
    }
    this.showSpinner = false;
  }

  fowardData() {
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(response.isAlive) {
        this.router.navigate([`/questionnaire/access`]);
      } else {
        this.modalError.config = {ignoreBackdropClick: true};
        this.errorModalData = {title: TIMEOUT_ERROR_MESSAGE, code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE};
        this.modalError.show()
      }
    });
    
  }

}
/*
export interface Employees {
  id_employee: number,
  questionnaire_application_id: number,
  application_email: string,
  name: string,
  answer_status_id: number,
  answer_status: string
}*/
