import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { BusinessService } from 'src/app/services/business.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html'
})
export class BusinessComponent implements OnInit {

  @Input() idClient;
  showSpinner = false;
  newBusinessForm: FormGroup;
  @ViewChild('modalBusinessNew') public modalBusinessNew: ModalDirective;
  @ViewChild('modalBusinessDelete') public modalBusinessDelete: ModalDirective;
  deleteBusiness = {
    name: '',
    id: 0
  };
  loadingDelete = false;
  loadingNew = false;
  businesses = [];
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;
  aux_user: any;

  constructor(private businessService: BusinessService, private router: Router, private notifierService: NotifierService,
    private authService: AuthService, private errService: ErrorService) {
    this.newBusinessForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.getBusinesses(1);
  }

  async getBusinesses(type) {
    this.businesses = [];
    this.showSpinner = true;
    let res;
    if (this.aux_user.user_type_id === 1) {
      res = await this.businessService.getByClient(this.idClient, this.limit, this.offset);
    } else {
      res = await this.businessService.getByClient(this.aux_user.client_id, this.limit, this.offset);
    }
    this.showSpinner = false;
    if (res.success) {
      this.businesses = res.data.businesses;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  businessDetails(id) {
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/business/', id]);
    } else {
      this.router.navigate(['/client/business/', id]);
    }
  }

  openDeleteBusinessModal(id, name) {
    this.deleteBusiness.id = id;
    this.deleteBusiness.name = `${name}`;
    this.modalBusinessDelete.show();
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.getBusinesses(1);
  }

  nextPage() {
    this.offset += this.limit;
    this.getBusinesses(2);
    this.limitDown += this.limit;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
  }

  previusPage() {
    this.offset -= this.limit;
    this.getBusinesses(2);
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  async removeBusiness() {
    this.loadingDelete = true;
    const res = await this.businessService.deleteById(this.deleteBusiness.id);
    if (res.success) {
      this.offset = 0;
      this.getBusinesses(1);
      this.notifierService.notify('success', 'Centros de Trabajo eliminado');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalBusinessDelete.hide();
    this.loadingDelete = false;
  }

  showNewBusinessModal() {
    this.clearValues();
    this.modalBusinessNew.show();
  }

  clearValues() {
    this.newBusinessForm.controls.name.setValue('');
  }

  async addBusiness() {
    this.loadingNew = true;
    const data = {
      name: this.newBusinessForm.controls['name'].value,
      client_id: this.idClient
    };
    const res = await this.businessService.post(data);
    if (res.success) {
      this.offset = 0;
      this.getBusinesses(1);
      this.notifierService.notify('success', 'Centros de Trabajo añadido');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalBusinessNew.hide();
    this.loadingNew = false;
  }

}
