import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { AnswerService } from '../../../services/answer.service';
import { NotifierService } from 'angular-notifier';
import { OnlineStatusService } from 'src/app/services/online-status.service';
import { DEFAULT_ERROR_CODE, DEFAULT_ERROR_MESSAGE, TIMEOUT_ERROR_CODE, TIMEOUT_ERROR_MESSAGE } from 'src/app/constants/http-requests.const';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-manual-questionnaire',
  templateUrl: './manual-questionnaire.component.html'
})
export class ManualQuestionnaireComponent implements OnInit {

  token: string;
  showNotAllowed = false;
  questionnaire: any;
  notQ1 = false;
  name = '';
  access_code = '';
  errorMsg = '';
  instructions = [
    'Verifica tu identidad',
    'Proporciona tus datos',
    'Responde el Cuestionario'
  ]
  instructionsManual = [];
  colors: any;
  searchingEmployee = false;
  employeeFound : boolean;
  applicationUrl: string;
  employees: Employees[];
  isFirstTime = true;
  employeeSelectedId;
  primary: string;
  secondary: string;
  tertiary: string;
  tertiary_text: string;
  codeMsg = 'Ingresa tu código de acceso';
  start_msg = `<p>El objetivo es identificar a aquellas personas que han sido sujetas a acontecimientos traumático severos durante o
      con motivo de trabajo y canalizarlos para su atención clínica.</p>`;
  errorModalData = {
    code: DEFAULT_ERROR_CODE,
    message: DEFAULT_ERROR_MESSAGE,
    title: DEFAULT_ERROR_MESSAGE
  }
  showSpinner: boolean;
  @ViewChild('modalError') public modalError: ModalDirective;

  constructor(private route: ActivatedRoute, private answerService: AnswerService, private router: Router,
    private ref: ChangeDetectorRef, private notifierService: NotifierService, private onlineStatusService: OnlineStatusService) {
    this.applicationUrl = JSON.parse(sessionStorage.getItem('ApplicationUrl'));
    console.log("URL:",this.applicationUrl);
    this.employees = [];
  }

  ngOnInit() {
    if(this.applicationUrl !== undefined && this.applicationUrl !== null && this.applicationUrl !== ''){
      this.showSpinner = true;
      this.Manual(this.applicationUrl);
    }else {
      this.showSpinner = false;
      this.showNotAllowed = true;
      this.errorMsg = 'No tienes acceso al cuestionario';
    }
  }

  next(){
    this.router.navigate(['/questionnaire/welcome']);
  }

  async searchEmployee(){
    this.onlineStatusService.checkInternetConnection().subscribe(response => {
      if(response.isAlive) {
        this.getToken();
      } else {
        this.modalError.config = {ignoreBackdropClick: true};
        this.errorModalData = {title: TIMEOUT_ERROR_MESSAGE, code: TIMEOUT_ERROR_CODE, message: TIMEOUT_ERROR_MESSAGE};
        this.modalError.show()
      }
    });
    
  }

  async getToken() {
    this.searchingEmployee = true;
    let data = {};
    if (this.questionnaire !== null && this.access_code !== ''){
      data['questionnaire_application_id'] = this.questionnaire.id_questionnaire_application;
    }
    if (this.access_code !== null && this.access_code !== ''){
      data['access_code'] = this.access_code;
    }
    const res = await this.answerService.getToken(data);
    if(res.success){      
      localStorage.setItem('cnq35', res.data.token);
      this.setColors(false);
      //let url = `/questionnaire?token=${res.data.token}`;
      this.employeeFound = true;
      this.router.navigate(['/questionnaire/welcome']);
    }else{
      this.employeeFound = false;
      //this.notifierService.notify('error', 'Ocurrio un problema al obtener el token, intente de nuevo'); 
    }
    this.isFirstTime = false;
    this.searchingEmployee = false;
  }

  async setColors(data) {
    if(data) {
      this.primary = data.client.primary_color;
      this.secondary = data.client.secondary_color;
      this.tertiary = data.client.tertiary_color;
      this.tertiary_text = data.client.text_tertiary_color;
      let colors = {
        text_primary_color: data.client.text_primary_color,
        text_secondary_color: data.client.text_secondary_color,
        text_tertiary_color: data.client.text_tertiary_color
      };
      sessionStorage.setItem('text_colors', JSON.stringify(colors));
      this.colors = colors;
      return;
    }
    const res = await this.answerService.getData();
    if (res.success) {
      this.primary = res.data.client.primary_color;
      this.secondary = res.data.client.secondary_color;
      this.tertiary = res.data.client.tertiary_color;
      this.tertiary_text = res.data.client.text_tertiary_color;
      let colors = {
        text_primary_color: res.data.client.text_primary_color,
        text_secondary_color: res.data.client.text_secondary_color,
        text_tertiary_color: res.data.client.text_tertiary_color
      };
      this.answerService.clientDataListener.next(res.data.client);
      sessionStorage.setItem('text_colors', JSON.stringify(colors));
    }
  }
  
  async Manual(appUrl) {
    const res = await this.answerService.getApplicationUrl(appUrl);
    if (res.success) {
      this.answerService.clientDataListener.next(res.data.client);
      this.answerService.loadingClientDataListener.next(false);
      this.setColors(res.data)
      setTimeout(() => {
        this.showSpinner = false;
      }, 700);
      this.answerService.SetIsManual(true);
      this.questionnaire = res.data.questionnaire_application;
      this.instructionsManual = [
        `Es necesario que en el cuadro.`,
        `En caso de que no te encuentres con tu nombre completo, prueba anotando sólo un apellido o nombre, o en caso omiso alternando el orden.`, 
        `(Por ejemplo. para el nombre completo : Vázquez Rivera Luis Fernando. Buscar como: Vázquez Rivera, Vázquez Luis, o Luis Fernando.)`
      ];
      this.codeMsg = res.data.questionnaire_application.code_message ? res.data.questionnaire_application.code_message : 'código de acceso';
    }
    else{
      switch (res.error.code) {
        case 435:
          this.errorMsg = 'El cuestionario ha sido completado';
          break;
        case 436:
          this.errorMsg = 'El tiempo para responder el cuestionario ha expirado';
          break;
        case 437:
          this.errorMsg = 'El cuestionario se ha cerrado debido a mantenimiento, te notificaremos en cuanto se habilite';
          break;
        case 409:
          this.errorMsg = 'No se encontró ningun cuestionario con esa url';
          break; 
        default:
          this.errorMsg = 'Acceso invalido';
          break;
      }
      //console.log(this.showNotAllowed, res.error.code, this.errorMsg);
      this.showNotAllowed = true;
      this.ref.detectChanges();
    }
  }

  async validateToken() {
    const res = await this.answerService.getData();
    if (res.success) {
      this.questionnaire = res.data.questionnaire_application;
      this.answerService.clientDataListener.next(res.data.client);
      if (res.data.employee.name !== null) {
        this.name = res.data.employee.name;
      }
      if (res.data.questionnaire_application.questionnaire_id == 2) {
        this.start_msg = `<p>El objetivo de la evaluación es hacer una identificación y análisis los Factores de Riesgo Psicosocial 
        para de esta manera establecer medidas de prevención y acciones de control de los factores de riesgo psicosocial, 
        de la violencia laboral y promoción del entorno organizacional favorable.</p>`;
        this.notQ1 = true;
      }
      if (res.data.questionnaire_application.questionnaire_id == 3) {
        this.start_msg = `<p>El objetivo de la evaluación es hacer una identificación y análisis los Factores de Riesgo Psicosocial 
        y evaluación del Entorno Organizacional para de esta manera establecer medidas de prevención y acciones de control de los factores 
        de riesgo psicosocial, de la violencia laboral y promoción del entorno organizacional favorable.</p>`;
        this.notQ1 = true;
      }
      if (res.data.questionnaire_application.start_message !== null &&
        res.data.questionnaire_application.start_message.length !== 0) {
        this.start_msg = res.data.questionnaire_application.start_message;
      }
      this.instructions = this.notQ1 ? [
        `Las preguntas debes de contestarlas conforme al orden que van apareciendo.`,
        `El cuestionario debe de responderse en su totalidad.`, 
        `Es importante que te encuentres en un ambiente en donde las condiciones de las instalaciones y mobiliario sean idóneas y permitan concentrarte.`, 
        `No existen respuestas correctas o incorrectas.`, 
        `Al responder las preguntas, debes de considerar las condiciones de los dos últimos meses. `,
        `Tu opinión es lo más importante por lo que te pedimos respondas las preguntas con sinceridad.`,
      ] : this.instructions;
    }
    else{
      switch (res.error.code) {
        case 435:
          this.errorMsg = 'El cuestionario ha sido completado';
          break;
        case 436:
          this.errorMsg = 'El tiempo para responder el cuestionario ha expirado';
          break;
        case 437:
          this.errorMsg = 'El cuestionario se ha cerrado debido a mantenimiento, te notificaremos en cuanto se habilite';
          break;
        default:
          this.errorMsg = 'Acceso invalido';
          break;
      }
      console.log(this.showNotAllowed, res.error.code, this.errorMsg);
      this.showNotAllowed = true;
      this.ref.detectChanges();
    }
  }

  fowardData() {
    this.router.navigate([`/questionnaire/access`]);
  }

}

export interface Employees {
  id_employee: number,
  questionnaire_application_id: number,
  application_email: string,
  name: string,
  answer_status_id: number,
  answer_status: string
}

