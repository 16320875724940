import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

// Third part modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AlertModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularDraggableModule } from 'angular2-draggable';
import { NotifierModule } from 'angular-notifier';
import { NgxEditorModule } from 'ngx-editor';

// Interceptor
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';

// Routes
import { APP_ROUTING } from './app.routes';

// Guards
import { AdminGuard } from './guards/admin.guard';
import { ClientGuard } from './guards/client.guard';
import { QuestaionnaireGuard } from './guards/questionnaire.guard ';

// Services
import { AuthService } from './services/auth.service';
import { UsuarioService } from './services/usuario.service';
import { ClientService } from './services/client.service';
import { AreaService } from './services/area.service';
import { BusinessService } from './services/business.service';
import { RouterExtService } from './services/router-ext.service';
import { QuestionnaireService } from './services/questionnaire.service';
import { AnswerService } from './services/answer.service';
import { QuestionnaireResultService } from './services/questionnaire-result.service';
import { ToExcelService } from './services/to-excel.service'

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { SidevarComponent } from './components/shared/sidevar/sidevar.component';
import { HeadNavigationComponent } from './components/shared/head-navigation/head-navigation.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { UsuarioDetailsComponent } from './components/usuario-details/usuario-details.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { ClienteDetailsComponent } from './components/cliente-details/cliente-details.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AreasComponent } from './components/areas/areas.component';
import { AreaDetailsComponent } from './components/area-details/area-details.component';
import { BusinessComponent } from './components/business/business.component';
import { BusinessDetailsComponent } from './components/business-details/business-details.component';
import { ApplicationBusinessComponent } from './components/application-business/application-business.component';
import { QuestionariosComponent } from './components/questionarios/questionarios.component';
import { QuestionarioDetailsComponent } from './components/questionario-details/questionario-details.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { ApplicationAreasComponent } from './components/application-areas/application-areas.component';
import { SociodemographicsComponent } from './components/sociodemographics/sociodemographics.component';
import { HomeQuestionnaireComponent } from './components/questionnaires/home-questionnaire/home-questionnaire.component';
import { QuestonnaireNavbarComponent } from './components/shared/questonnaire-navbar/questonnaire-navbar.component';
import { WelcomeQuestionnaireComponent } from './components/questionnaires/welcome-questionnaire/welcome-questionnaire.component';
import { ManualQuestionnaireComponent } from './components/questionnaires/manual-questionnaire/manual-questionnaire.component';
import { SDQformComponent } from './components/questionnaires/sdqform/sdqform.component';
import { ValidateCredentialesComponent } from './components/questionnaires/validate-credentiales/validate-credentiales.component';
import { Q1Component } from './components/questionnaires/q1/q1.component';
import { QuestionarioResultsComponent } from './components/questionario-results/questionario-results.component';
import { HomeClienteComponent } from './components/home-cliente/home-cliente.component';
import { Q3Component } from './components/questionnaires/q3/q3.component';
import { Q2Component } from './components/questionnaires/q2/q2.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    SidevarComponent,
    HeadNavigationComponent,
    UsuariosComponent,
    UsuarioDetailsComponent,
    ClientesComponent,
    ClienteDetailsComponent,
    ProfileComponent,
    AreasComponent,
    AreaDetailsComponent,
    BusinessComponent,
    BusinessDetailsComponent,
    ApplicationBusinessComponent,
    QuestionariosComponent,
    QuestionarioDetailsComponent,
    EmployeesComponent,
    EmployeeDetailsComponent,
    ApplicationAreasComponent,
    SociodemographicsComponent,
    HomeQuestionnaireComponent,
    QuestonnaireNavbarComponent,
    WelcomeQuestionnaireComponent,
    ManualQuestionnaireComponent,
    SDQformComponent,
    ValidateCredentialesComponent,
    Q1Component,
    QuestionarioResultsComponent,
    HomeClienteComponent,
    SafeHtmlPipe,
    Q3Component,
    Q2Component,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    NgxEditorModule,
    APP_ROUTING,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    AngularDraggableModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10
        }
      },
      behaviour: {
        autoHide: 2000,
        onClick: 'hide',
        showDismissButton: true
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 500,
          easing: 'ease'
        },
        hide: {
          preset: 'fade',
          speed: 500,
          easing: 'ease',
          offset: 50
        },
        shift: {
          speed: 500,
          easing: 'ease'
        },
        overlap: 150
      }
    }),
  ],
  providers: [
    AuthService,
    AdminGuard,
    ClientGuard,
    QuestaionnaireGuard,
    UsuarioService,
    ClientService,
    AreaService,
    BusinessService,
    RouterExtService,
    QuestionnaireService,
    AnswerService,
    QuestionnaireResultService,
    ToExcelService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
