import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { logo1 } from './imgs';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';
import { ToExcelService } from '../../services/to-excel.service';

@Component({
  selector: 'app-questionarios',
  templateUrl: './questionarios.component.html'
})
export class QuestionariosComponent implements OnInit {

  @Input() idClient;
  showSpinner = false;
  newQuestionnaireForm: FormGroup;
  @ViewChild('modalQuestionnaireNew') public modalQuestionnaireNew: ModalDirective;
  @ViewChild('modalQuestionnaireDelete') public modalQuestionnaireDelete: ModalDirective;
  deleteQuestionnaire = {
    name: '',
    id: 0
  };
  loadingDelete = false;
  loadingNew = false;
  loadingPDF = false;
  aux_user: any;
  questionnaires = [];
  questionnaires_app = [];
  appTypes = [];
  appStatuses = [];
  appQStatuses = [];
  model: NgbDateStruct;
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;
  qSD: any;
  qType: any;
  applicationType = [{"Automatico": 1 , "Manual":2}];

  constructor(private questionnaireService: QuestionnaireService, private router: Router, private notifierService: NotifierService,
    private authService: AuthService, private errService: ErrorService, private toExcelService: ToExcelService) {
    this.newQuestionnaireForm = new FormGroup({
      questionnaire_id: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      details: new FormControl('', Validators.required),
      request_date: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      late_limit_date: new FormControl('', Validators.required),
      validate_user: new FormControl('', Validators.required),
      validate_access: new FormControl('', Validators.required),
      user_message: new FormControl('', Validators.required),
      access_message: new FormControl('', Validators.required),
      code_message: new FormControl('', Validators.required),
      application_url: new FormControl('', Validators.required),
      application_type_id: new FormControl('', Validators.required),
      questionnaire_application_status_id: new FormControl('', Validators.required),
      answers_sheets: new FormControl('', Validators.required),
      base_report_sheet: new FormControl('', Validators.required),
      base_report: new FormControl('', Validators.required),
      complete_report_sheet: new FormControl('', Validators.required),
      complete_report: new FormControl('', Validators.required),
      application_status_id: new FormControl('', Validators.required),
    });
    this.qSD = {};
    this.qType = {};
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.getQuestionnaireApps(1);
    this.getAppQStatuses();
    this.getAppStatuses();
    this.getApplicationTypes();
    this.getQuestionnaires();
  }

  async getSocioDemographicData(idQuestionnaireApplication) {
    const res = await this.questionnaireService.getSocioDemographics(idQuestionnaireApplication);
    if ( res.success ) {
      this.qSD = res.data.application_sociodemographic;
      console.log(this.qSD);
    } else {
      this.notifierService.notify('error', 'No se pudo obtener datos sociodemográficos');
    }
  }

  async getQuestionnaires() {
    const res = await this.questionnaireService.getQuestionnares();
    if (res.success) {
      this.questionnaires = res.data.questionnaires;
    }
  }

  async getApplicationTypes() {
    const res = await this.questionnaireService.getApplicationTypes();
    if (res.success) {
      this.appTypes = res.data.application_types;
    }
  }

  async getAppStatuses() {
    const res = await this.questionnaireService.getApplicationStatuses();
    if (res.success) {
      this.appStatuses = res.data.application_statuses;
    }
  }

  async getAppQStatuses() {
    const res = await this.questionnaireService.getStatuses();
    if (res.success) {
      this.appQStatuses = res.data.questionnaire_application_statuses;
    }
  }

  async getQuestionnaireApps(type) {
    this.questionnaires_app = [];
    this.showSpinner = true;
    let res;
    if (this.aux_user.user_type_id === 1) {
      res = await this.questionnaireService.getByClient(this.idClient, this.limit, this.offset);
    } else {
      res = await this.questionnaireService.getByClient(this.aux_user.client_id, this.limit, this.offset);
    }
    this.showSpinner = false;
    if (res.success) {
      this.questionnaires_app = res.data.questionnaire_applications;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  questionnaireDetails(id) {
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/cuestionario/', id]);
    } else {
      this.router.navigate(['/client/cuestionarios/', id]);
    }
  }

  openDeleteQuestionnaireModal(id, name) {
    this.deleteQuestionnaire.id = id;
    this.deleteQuestionnaire.name = `${name}`;
    this.modalQuestionnaireDelete.show();
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.getQuestionnaireApps(1);
  }

  nextPage() {
    this.offset += this.limit;
    this.getQuestionnaireApps(2);
    this.limitDown += this.limit;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
  }

  previusPage() {
    this.offset -= this.limit;
    this.getQuestionnaireApps(2);
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  async removeQuestionnaire() {
    this.loadingDelete = true;
    const res = await this.questionnaireService.deleteById(this.deleteQuestionnaire.id);
    if (res.success) {
      this.offset = 0;
      this.getQuestionnaireApps(1);
      this.notifierService.notify('success', 'Cuestionario eliminado');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalQuestionnaireDelete.hide();
    this.loadingDelete = false;
  }

  showNewQuestionnaireModal() {
    this.clearValues();
    this.modalQuestionnaireNew.show();
  }

  clearValues() {
    this.newQuestionnaireForm.controls.questionnaire_id.setValue('');
    this.newQuestionnaireForm.controls.name.setValue('');
    this.newQuestionnaireForm.controls.details.setValue('');
    this.newQuestionnaireForm.controls.request_date.setValue('');
    this.newQuestionnaireForm.controls.start_date.setValue('');
    this.newQuestionnaireForm.controls.end_date.setValue('');
    this.newQuestionnaireForm.controls.validate_user.setValue('');
    this.newQuestionnaireForm.controls.validate_access.setValue('');
    this.newQuestionnaireForm.controls.user_message.setValue('');
    this.newQuestionnaireForm.controls.access_message.setValue('');
    this.newQuestionnaireForm.controls.code_message.setValue('');
    this.newQuestionnaireForm.controls.application_url.setValue('');
    this.newQuestionnaireForm.controls.application_type_id.setValue('');
    this.newQuestionnaireForm.controls.questionnaire_application_status_id.setValue('');
    this.newQuestionnaireForm.controls.answers_sheets.setValue('');
    this.newQuestionnaireForm.controls.base_report_sheet.setValue('');
    this.newQuestionnaireForm.controls.base_report.setValue('');
    this.newQuestionnaireForm.controls.complete_report_sheet.setValue('');
    this.newQuestionnaireForm.controls.complete_report.setValue('');
    this.newQuestionnaireForm.controls.application_status_id.setValue('');
    this.newQuestionnaireForm.controls.late_limit_date.setValue('');
  }

  async addQuestionnaire() {
    this.loadingNew = true;
    
    const data = this.getPostData();

    const res = await this.questionnaireService.post(data);
    if (res.success) {
      this.offset = 0;
      this.getQuestionnaireApps(1);
      this.notifierService.notify('success', 'Cuestionario añadido');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalQuestionnaireNew.hide();
    this.loadingNew = false;
  }

  getPostData() {
    const data = {
      client_id: this.idClient,
      questionnaire_id: this.newQuestionnaireForm.controls['questionnaire_id'].value,
      application_type_id: 1,
      name: this.newQuestionnaireForm.controls['name'].value,
      validate_user: this.newQuestionnaireForm.controls['validate_user'].value,
      validate_access: this.newQuestionnaireForm.controls['validate_access'].value,
      application_subset_type_id: 1,
      answers_sheets: 0,
      base_report_sheet: 0,
      base_report: 0,
      complete_report_sheet: 0,
      complete_report: 0,
    }
    
    if ( this.newQuestionnaireForm.controls['details'].value !== '' ) {
      data['details'] = this.newQuestionnaireForm.controls['details'].value;
    }
    if ( this.newQuestionnaireForm.controls['request_date'].value !== '' ) {
      data['request_date'] = this.newQuestionnaireForm.controls['request_date'].value;
    }
    if ( this.newQuestionnaireForm.controls['start_date'].value !== '' ) {
      data['start_date'] = this.newQuestionnaireForm.controls['start_date'].value;
    }
    if ( this.newQuestionnaireForm.controls['end_date'].value !== '' ) {
      data['end_date'] = this.newQuestionnaireForm.controls['end_date'].value;
    }
    if (this.newQuestionnaireForm.controls['late_limit_date'].value !== '') {
      data['late_limit_date'] = this.newQuestionnaireForm.controls['late_limit_date'].value;
    }
    if ( this.newQuestionnaireForm.controls['validate_user'].value !== '' ) {
      data['validate_user'] = this.newQuestionnaireForm.controls['validate_user'].value;
    }
    if ( this.newQuestionnaireForm.controls['validate_access'].value !== '' ) {
      data['validate_access'] = this.newQuestionnaireForm.controls['validate_access'].value;
    }
    if ( this.newQuestionnaireForm.controls['user_message'].value !== '' ) {
      data['user_message'] = this.newQuestionnaireForm.controls['user_message'].value;
    }
    if ( this.newQuestionnaireForm.controls['access_message'].value !== '' ) {
      data['access_message'] = this.newQuestionnaireForm.controls['access_message'].value;
    }
    if ( this.newQuestionnaireForm.controls['code_message'].value !== '' ) {
      data['code_message'] = this.newQuestionnaireForm.controls['code_message'].value;
    }
    if ( this.newQuestionnaireForm.controls['application_url'].value !== '' ) {
      data['application_url'] = this.newQuestionnaireForm.controls['application_url'].value;
    }
    if ( this.newQuestionnaireForm.controls['application_type_id'].value !== '' ) {
      data['application_type_id'] = this.newQuestionnaireForm.controls['application_type_id'].value;
    }
    if ( this.newQuestionnaireForm.controls['questionnaire_application_status_id'].value !== '' ) {
      data['questionnaire_application_status_id'] = this.newQuestionnaireForm.controls['questionnaire_application_status_id'].value;
    }
    if ( this.newQuestionnaireForm.controls['application_status_id'].value !== '' ) {
      data['application_status_id'] = this.newQuestionnaireForm.controls['application_status_id'].value;
    }
    
    return data;
  }

  downloadExcel(id, type) {
    this.qType = type;
    if(this.qType === 1){
      this.downloadExcelQ1(id);
    }else if (this.qType === 2) {
      this.downloadExcelQ2(id, type);
    } else if (this.qType == 3){
      this.downloadExcelQ3(id, type);
    } else if (this.qType == 4){
      this.downloadExcelQ4(id, type);
    } else if (this.qType == 5){
      this.downloadExcelQ5(id, type);
    }
  } 

  async downloadExcelQ4(id: number, type) {
    this.loadingPDF = true;
    let array_data = [];
    await this.getSocioDemographicData(id);
    const res2 = await this.questionnaireService.getFullResultsQ23(type, id);
    if (!res2.success) {
      this.notifierService.notify('error', 'Ocurrio un problema al descargar'); 
      this.loadingPDF = false;
      return 
    }
    const employees = res2.data.responses;
    for (let employee of employees){
      let data = {};
      data['Empleado ID'] = employee.id_employee;
      data['Área'] = employee.area;
      data['Nombre'] = employee.name;
      data['Email'] = employee.application_email;
      if(this.qSD.business)
        data['Razon Social'] = employee.business !== null ? employee.business : '-';
      if(this.qSD.gender)
        data['Sexo'] = employee.gender !== null ? employee.gender : '-';
      if(this.qSD.age)
        data['Edad'] = employee.age !== null ? employee.age : '-';
      if(this.qSD.age_segmented)
        data['Edad en Años'] = employee.age_range !== null ? employee.age_range : '-';
      if(this.qSD.civil_status)
        data['Estado Civil'] = employee.civil_status !== null ? employee.civil_status : '-';
      if(this.qSD.education_level)
        data['Nivel de Estudios'] = employee.education_level !== null ? employee.education_level : '-';
      if(this.qSD.occupation)
        data['Puesto'] = employee.occupation !== null ? employee.occupation : '-';
      if(this.qSD.position_type)
        data['Tipo de Puesto'] = employee.position_type !== null ? employee.position_type : '-';
      if(this.qSD.recruitment_type)
        data['Tipo de Contratación'] = employee.recruitment_type !== null ? employee.recruitment_type : '-';
      if(this.qSD.employee_type)
        data['Tipo de Personal'] = employee.employee_type !== null ? employee.employee_type : '-';
      if(this.qSD.working_hours_type)
        data['Tipo de Jornada de Trabajo'] = employee.working_hours_type !== null ? employee.working_hours_type : '-';
      if(this.qSD.working_hours_rotation)
        data['Realiza Rotacion de Turnos'] = employee.working_hours_rotation !== null ? employee.working_hours_rotation : '-';
      if(this.qSD.work_experience)
        data['Tiempo Experiencia Laboral'] = employee.work_experience !== null ? employee.work_experience : '-';
      if(this.qSD.current_position_time)
        data['Posición Actual'] = employee.current_position_time !== null ? employee.current_position_time : '-';
      if(this.qSD.state)
        data['Estado'] = employee.state;
      let ats_answers = JSON.parse(employee.ats_answers);
      if(ats_answers.s1 !== undefined){
        data['s1'] = ats_answers.s1 !== "" ? ats_answers.s1 : '-';
      }
      if(ats_answers.s1_1 !== undefined && ats_answers.s1_2 !== undefined && ats_answers.s1_3 !== undefined && ats_answers.s1_4 !== undefined && ats_answers.s1_5 !== undefined && ats_answers.s1_6 !== undefined){
        data['s1_1'] = ats_answers.s1_1 !== "" ? ats_answers.s1_1 : '-';
        data['s1_2'] = ats_answers.s1_2 !== "" ? ats_answers.s1_2 : '-';
        data['s1_3'] = ats_answers.s1_3 !== "" ? ats_answers.s1_3 : '-';
        data['s1_4'] = ats_answers.s1_4 !== "" ? ats_answers.s1_4 : '-';
        data['s1_5'] = ats_answers.s1_5 !== "" ? ats_answers.s1_5 : '-';
        data['s1_6'] = ats_answers.s1_6 !== "" ? ats_answers.s1_6 : '-'; 
      }
      data['s2_1'] = ats_answers.s2_1 !== "" ? ats_answers.s2_1 : '-';
      data['s2_2'] = ats_answers.s2_2 !== "" ? ats_answers.s2_2 : '-';
      data['s3_1'] = ats_answers.s3_1 !== "" ? ats_answers.s3_1 : '-';
      data['s3_2'] = ats_answers.s3_2 !== "" ? ats_answers.s3_2 : '-';
      data['s3_3'] = ats_answers.s3_3 !== "" ? ats_answers.s3_3 : '-';
      data['s3_4'] = ats_answers.s3_4 !== "" ? ats_answers.s3_4 : '-';
      data['s3_5'] = ats_answers.s3_5 !== "" ? ats_answers.s3_5 : '-';
      data['s3_6'] = ats_answers.s3_6 !== "" ? ats_answers.s3_6 : '-';
      data['s3_7'] = ats_answers.s3_7 !== "" ? ats_answers.s3_7 : '-';
      data['s4_1'] = ats_answers.s4_1 !== "" ? ats_answers.s4_1 : '-';
      data['s4_2'] = ats_answers.s4_2 !== "" ? ats_answers.s4_2 : '-';
      data['s4_3'] = ats_answers.s4_3 !== "" ? ats_answers.s4_3 : '-';
      data['s4_4'] = ats_answers.s4_4 !== "" ? ats_answers.s4_4 : '-';
      data['s4_5'] = ats_answers.s4_5 !== "" ? ats_answers.s4_5 : '-';
      // -------------------------------> resultados Q3
      if(this.qSD.business)
        data['Razon Social'] = employee.business !== null ? employee.business : '-';
      if(this.qSD.gender)
        data['Sexo'] = employee.gender !== null ? employee.gender : '-';
      if(this.qSD.age)
        data['Edad'] = employee.age !== null ? employee.age : '-';
      if(this.qSD.age_segmented)
        data['Edad en Años'] = employee.age_range !== null ? employee.age_range : '-';
      if(this.qSD.civil_status)
        data['Estado Civil'] = employee.civil_status !== null ? employee.civil_status : '-';
      if(this.qSD.education_level)
        data['Nivel de Estudios'] = employee.education_level !== null ? employee.education_level : '-';
      if(this.qSD.occupation)
        data['Puesto'] = employee.occupation !== null ? employee.occupation : '-';
      if(this.qSD.position_type)
        data['Tipo de Puesto'] = employee.position_type !== null ? employee.position_type : '-';
      if(this.qSD.recruitment_type)
        data['Tipo de Contratación'] = employee.recruitment_type !== null ? employee.recruitment_type : '-';
      if(this.qSD.employee_type)
        data['Tipo de Personal'] = employee.employee_type !== null ? employee.employee_type : '-';
      if(this.qSD.working_hours_type)
        data['Tipo de Jornada de Trabajo'] = employee.working_hours_type !== null ? employee.working_hours_type : '-';
      if(this.qSD.working_hours_rotation)
        data['Realiza Rotacion de Turnos'] = employee.working_hours_rotation !== null ? employee.working_hours_rotation : '-';
      if(this.qSD.work_experience)
        data['Tiempo Experiencia Laboral'] = employee.work_experience !== null ? employee.work_experience : '-';
      if(this.qSD.current_position_time)
        data['Posición Actual'] = employee.current_position_time !== null ? employee.current_position_time : '-';
      if(this.qSD.state)
        data['Estado'] = employee.state;
      let aux_answers = JSON.parse(employee.answers);
      data['Q1'] = aux_answers.q1 !== "" ? aux_answers.q1 : '-';
      data['Q2'] = aux_answers.q2 !== "" ? aux_answers.q2 : '-';
      data['Q3'] = aux_answers.q3 !== "" ? aux_answers.q3 : '-';
      data['Q4'] = aux_answers.q4 !== "" ? aux_answers.q4 : '-';
      data['Q5'] = aux_answers.q5 !== "" ? aux_answers.q5 : '-';
      data['Q6'] = aux_answers.q6 !== "" ? aux_answers.q6 : '-';
      data['Q7'] = aux_answers.q7 !== "" ? aux_answers.q7 : '-';
      data['Q8'] = aux_answers.q8 !== "" ? aux_answers.q8 : '-';
      data['Q9'] = aux_answers.q9 !== "" ? aux_answers.q9 : '-';
      data['Q10'] = aux_answers.q10 !== "" ? aux_answers.q10 : '-';
      data['Q11'] = aux_answers.q11 !== "" ? aux_answers.q11 : '-';
      data['Q12'] = aux_answers.q12 !== "" ? aux_answers.q12 : '-';
      data['Q13'] = aux_answers.q13 !== "" ? aux_answers.q13 : '-';
      data['Q14'] = aux_answers.q14 !== "" ? aux_answers.q14 : '-';
      data['Q15'] = aux_answers.q15 !== "" ? aux_answers.q15 : '-';
      data['Q16'] = aux_answers.q16 !== "" ? aux_answers.q16 : '-';
      data['Q17'] = aux_answers.q17 !== "" ? aux_answers.q17 : '-';
      data['Q18'] = aux_answers.q18 !== "" ? aux_answers.q18 : '-';
      data['Q19'] = aux_answers.q19 !== "" ? aux_answers.q19 : '-';
      data['Q20'] = aux_answers.q20 !== "" ? aux_answers.q20 : '-';
      data['Q21'] = aux_answers.q21 !== "" ? aux_answers.q21 : '-';
      data['Q22'] = aux_answers.q22 !== "" ? aux_answers.q22 : '-';
      data['Q23'] = aux_answers.q23 !== "" ? aux_answers.q23 : '-';
      data['Q24'] = aux_answers.q24 !== "" ? aux_answers.q24 : '-';
      data['Q25'] = aux_answers.q25 !== "" ? aux_answers.q25 : '-';
      data['Q26'] = aux_answers.q26 !== "" ? aux_answers.q26 : '-';
      data['Q27'] = aux_answers.q27 !== "" ? aux_answers.q27 : '-';
      data['Q28'] = aux_answers.q28 !== "" ? aux_answers.q28 : '-';
      data['Q29'] = aux_answers.q29 !== "" ? aux_answers.q29 : '-';
      data['Q30'] = aux_answers.q30 !== "" ? aux_answers.q30 : '-';
      data['Q31'] = aux_answers.q31 !== "" ? aux_answers.q31 : '-';
      data['Q32'] = aux_answers.q32 !== "" ? aux_answers.q32 : '-';
      data['Q33'] = aux_answers.q33 !== "" ? aux_answers.q33 : '-';
      data['Q34'] = aux_answers.q34 !== "" ? aux_answers.q34 : '-';
      data['Q35'] = aux_answers.q35 !== "" ? aux_answers.q35 : '-';
      data['Q36'] = aux_answers.q36 !== "" ? aux_answers.q36 : '-';
      data['Q37'] = aux_answers.q37 !== "" ? aux_answers.q37 : '-';
      data['Q38'] = aux_answers.q38 !== "" ? aux_answers.q38 : '-';
      data['Q39'] = aux_answers.q39 !== "" ? aux_answers.q39 : '-';
      data['Q40'] = aux_answers.q40 !== "" ? aux_answers.q40 : '-';
      data['Q41'] = aux_answers.q41 !== "" ? aux_answers.q41 : '-';
      data['Q42'] = aux_answers.q42 !== "" ? aux_answers.q42 : '-';
      data['Q43'] = aux_answers.q43 !== "" ? aux_answers.q43 : '-';
      data['Q44'] = aux_answers.q44 !== "" ? aux_answers.q44 : '-';
      data['Q45'] = aux_answers.q45 !== "" ? aux_answers.q45 : '-';
      data['Q46'] = aux_answers.q46 !== "" ? aux_answers.q46 : '-';
      data['Calificación final'] = employee.final_risk_score
      data['Nivel de riesgo final'] = this.evaluateFinal(employee.final_risk_score);
      data['Calificación de Ambiente de trabajo'] = employee.cat_work_ambient_score;
      data['Nivel de riesgo en Ambiente de trabajo'] = this.evaluateCatWorkAmbient(employee.cat_work_ambient_score);
      data['Calificación de Factores propios de la actividad'] = employee.cat_activity_factors_score;
      data['Nivel de riesgo en Factores propios de la actividad'] = this.evaluateCatWorkActivityF(employee.cat_activity_factors_score);
      data['Calificación de Organización del tiempo de trabajo'] = employee.cat_work_time_score;
      data['Nivel de riesgo en Organización del tiempo de trabajo'] = this.evaluateCatWorkTime(employee.cat_work_time_score);
      data['Calificación de Liderazgo y relaciones en el trabajo'] = employee.cat_work_relations_score;
      data['Nivel de riesgo en Liderazgo y relaciones en el trabajo'] = this.evaluateCatWorkRelations(employee.cat_work_relations_score);
      data['Calificación de Condiciones en el ambiente de trabajo'] = employee.dom_work_conditions_score;
      data['Nivel de riesgo en Condiciones en el ambiente de trabajo'] = this.evaluateDomWorkConditions(employee.dom_work_conditions_score);
      data['Calificación de Carga de trabajo'] = employee.dom_work_load_score;
      data['Nivel de riesgo en Carga de trabajo'] = this.evaluateDomWorkLoad(employee.dom_work_load_score);
      data['Calificación de Falta de control sobre el trabajo'] = employee.dom_missing_control_score;
      data['Nivel de riesgo en Falta de control sobre el trabajo'] = this.evaluateDomMissingControl(employee.dom_missing_control_score);
      data['Calificación de Jornada de trabajo'] = employee.dom_work_hours_score;
      data['Nivel de riesgo en Jornada de trabajo'] = this.evaluateDomWorkHours(employee.dom_work_hours_score);
      data['Calificación de Interferencia en la relación trabajo-familia'] = employee.dom_family_score;
      data['Nivel de riesgo en Interferencia en la relación trabajo-familia'] = this.evaluateDomFamily(employee.dom_family_score);
      data['Calificación de Liderazgo'] = employee.dom_leadership_score;
      data['Nivel de riesgo en Liderazgo'] = this.evaluateDomLeadership(employee.dom_leadership_score);
      data['Calificación de Relaciones en el trabajo'] = employee.dom_work_relations_score;
      data['Nivel de riesgo en Relaciones en el trabajo'] = this.evaluateDomWorkRelations(employee.dom_work_relations_score);
      data['Calificación de Violencia'] = employee.dom_violence_score;
      data['Nivel de riesgo en Violencia'] = this.evaluateDomViolence(employee.dom_violence_score);
      array_data.push(data);
    };
    this.toExcelService.exportAsExcelFile(array_data,"resultados q4");
    this.loadingPDF = false;
  }

  async downloadExcelQ5(id: number, type) {
    this.loadingPDF = true;
    let array_data = [];
    await this.getSocioDemographicData(id);
    const res2 = await this.questionnaireService.getFullResultsQ23(type, id);
    if (!res2.success) {
      this.notifierService.notify('error', 'Ocurrio un problema al descargar'); 
      this.loadingPDF = false;
      return 
    }
    const employees = res2.data.responses;
    for (let employee of employees){
      let data = {};
      data['Empleado ID'] = employee.id_employee;
      data['Área'] = employee.area;
      data['Nombre'] = employee.name;
      data['Email'] = employee.application_email;
      if(this.qSD.business)
        data['Razon Social'] = employee.business !== null ? employee.business : '-';
      if(this.qSD.gender)
        data['Sexo'] = employee.gender !== null ? employee.gender : '-';
      if(this.qSD.age)
        data['Edad'] = employee.age !== null ? employee.age : '-';
      if(this.qSD.age_segmented)
        data['Edad en Años'] = employee.age_range !== null ? employee.age_range : '-';
      if(this.qSD.civil_status)
        data['Estado Civil'] = employee.civil_status !== null ? employee.civil_status : '-';
      if(this.qSD.education_level)
        data['Nivel de Estudios'] = employee.education_level !== null ? employee.education_level : '-';
      if(this.qSD.occupation)
        data['Puesto'] = employee.occupation !== null ? employee.occupation : '-';
      if(this.qSD.position_type)
        data['Tipo de Puesto'] = employee.position_type !== null ? employee.position_type : '-';
      if(this.qSD.recruitment_type)
        data['Tipo de Contratación'] = employee.recruitment_type !== null ? employee.recruitment_type : '-';
      if(this.qSD.employee_type)
        data['Tipo de Personal'] = employee.employee_type !== null ? employee.employee_type : '-';
      if(this.qSD.working_hours_type)
        data['Tipo de Jornada de Trabajo'] = employee.working_hours_type !== null ? employee.working_hours_type : '-';
      if(this.qSD.working_hours_rotation)
        data['Realiza Rotacion de Turnos'] = employee.working_hours_rotation !== null ? employee.working_hours_rotation : '-';
      if(this.qSD.work_experience)
        data['Tiempo Experiencia Laboral'] = employee.work_experience !== null ? employee.work_experience : '-';
      if(this.qSD.current_position_time)
        data['Posición Actual'] = employee.current_position_time !== null ? employee.current_position_time : '-';
      if(this.qSD.state)
        data['Estado'] = employee.state;
      let ats_answers = JSON.parse(employee.ats_answers);
      if(ats_answers.s1 !== undefined){
        data['s1'] = ats_answers.s1 !== "" ? ats_answers.s1 : '-';
      }
      if(ats_answers.s1_1 !== undefined && ats_answers.s1_2 !== undefined && ats_answers.s1_3 !== undefined && ats_answers.s1_4 !== undefined && ats_answers.s1_5 !== undefined && ats_answers.s1_6 !== undefined){
        data['s1_1'] = ats_answers.s1_1 !== "" ? ats_answers.s1_1 : '-';
        data['s1_2'] = ats_answers.s1_2 !== "" ? ats_answers.s1_2 : '-';
        data['s1_3'] = ats_answers.s1_3 !== "" ? ats_answers.s1_3 : '-';
        data['s1_4'] = ats_answers.s1_4 !== "" ? ats_answers.s1_4 : '-';
        data['s1_5'] = ats_answers.s1_5 !== "" ? ats_answers.s1_5 : '-';
        data['s1_6'] = ats_answers.s1_6 !== "" ? ats_answers.s1_6 : '-'; 
      }
      data['s2_1'] = ats_answers.s2_1 !== "" ? ats_answers.s2_1 : '-';
      data['s2_2'] = ats_answers.s2_2 !== "" ? ats_answers.s2_2 : '-';
      data['s3_1'] = ats_answers.s3_1 !== "" ? ats_answers.s3_1 : '-';
      data['s3_2'] = ats_answers.s3_2 !== "" ? ats_answers.s3_2 : '-';
      data['s3_3'] = ats_answers.s3_3 !== "" ? ats_answers.s3_3 : '-';
      data['s3_4'] = ats_answers.s3_4 !== "" ? ats_answers.s3_4 : '-';
      data['s3_5'] = ats_answers.s3_5 !== "" ? ats_answers.s3_5 : '-';
      data['s3_6'] = ats_answers.s3_6 !== "" ? ats_answers.s3_6 : '-';
      data['s3_7'] = ats_answers.s3_7 !== "" ? ats_answers.s3_7 : '-';
      data['s4_1'] = ats_answers.s4_1 !== "" ? ats_answers.s4_1 : '-';
      data['s4_2'] = ats_answers.s4_2 !== "" ? ats_answers.s4_2 : '-';
      data['s4_3'] = ats_answers.s4_3 !== "" ? ats_answers.s4_3 : '-';
      data['s4_4'] = ats_answers.s4_4 !== "" ? ats_answers.s4_4 : '-';
      data['s4_5'] = ats_answers.s4_5 !== "" ? ats_answers.s4_5 : '-';
      // -------------------------------> resultados Q3
      if(this.qSD.business)
        data['Razon Social'] = employee.business !== null ? employee.business : '-';
      if(this.qSD.gender)
        data['Sexo'] = employee.gender !== null ? employee.gender : '-';
      if(this.qSD.age)
        data['Edad'] = employee.age !== null ? employee.age : '-';
      if(this.qSD.age_segmented)
        data['Edad en Años'] = employee.age_range !== null ? employee.age_range : '-';
      if(this.qSD.civil_status)
        data['Estado Civil'] = employee.civil_status !== null ? employee.civil_status : '-';
      if(this.qSD.education_level)
        data['Nivel de Estudios'] = employee.education_level !== null ? employee.education_level : '-';
      if(this.qSD.occupation)
        data['Puesto'] = employee.occupation !== null ? employee.occupation : '-';
      if(this.qSD.position_type)
        data['Tipo de Puesto'] = employee.position_type !== null ? employee.position_type : '-';
      if(this.qSD.recruitment_type)
        data['Tipo de Contratación'] = employee.recruitment_type !== null ? employee.recruitment_type : '-';
      if(this.qSD.employee_type)
        data['Tipo de Personal'] = employee.employee_type !== null ? employee.employee_type : '-';
      if(this.qSD.working_hours_type)
        data['Tipo de Jornada de Trabajo'] = employee.working_hours_type !== null ? employee.working_hours_type : '-';
      if(this.qSD.working_hours_rotation)
        data['Realiza Rotacion de Turnos'] = employee.working_hours_rotation !== null ? employee.working_hours_rotation : '-';
      if(this.qSD.work_experience)
        data['Tiempo Experiencia Laboral'] = employee.work_experience !== null ? employee.work_experience : '-';
      if(this.qSD.current_position_time)
        data['Posición Actual'] = employee.current_position_time !== null ? employee.current_position_time : '-';
      if(this.qSD.state)
        data['Estado'] = employee.state;
      let aux_answers = JSON.parse(employee.answers);
      data['Q1'] = aux_answers.q1 !== "" ? aux_answers.q1 : '-';
      data['Q2'] = aux_answers.q2 !== "" ? aux_answers.q2 : '-';
      data['Q3'] = aux_answers.q3 !== "" ? aux_answers.q3 : '-';
      data['Q4'] = aux_answers.q4 !== "" ? aux_answers.q4 : '-';
      data['Q5'] = aux_answers.q5 !== "" ? aux_answers.q5 : '-';
      data['Q6'] = aux_answers.q6 !== "" ? aux_answers.q6 : '-';
      data['Q7'] = aux_answers.q7 !== "" ? aux_answers.q7 : '-';
      data['Q8'] = aux_answers.q8 !== "" ? aux_answers.q8 : '-';
      data['Q9'] = aux_answers.q9 !== "" ? aux_answers.q9 : '-';
      data['Q10'] = aux_answers.q10 !== "" ? aux_answers.q10 : '-';
      data['Q11'] = aux_answers.q11 !== "" ? aux_answers.q11 : '-';
      data['Q12'] = aux_answers.q12 !== "" ? aux_answers.q12 : '-';
      data['Q13'] = aux_answers.q13 !== "" ? aux_answers.q13 : '-';
      data['Q14'] = aux_answers.q14 !== "" ? aux_answers.q14 : '-';
      data['Q15'] = aux_answers.q15 !== "" ? aux_answers.q15 : '-';
      data['Q16'] = aux_answers.q16 !== "" ? aux_answers.q16 : '-';
      data['Q17'] = aux_answers.q17 !== "" ? aux_answers.q17 : '-';
      data['Q18'] = aux_answers.q18 !== "" ? aux_answers.q18 : '-';
      data['Q19'] = aux_answers.q19 !== "" ? aux_answers.q19 : '-';
      data['Q20'] = aux_answers.q20 !== "" ? aux_answers.q20 : '-';
      data['Q21'] = aux_answers.q21 !== "" ? aux_answers.q21 : '-';
      data['Q22'] = aux_answers.q22 !== "" ? aux_answers.q22 : '-';
      data['Q23'] = aux_answers.q23 !== "" ? aux_answers.q23 : '-';
      data['Q24'] = aux_answers.q24 !== "" ? aux_answers.q24 : '-';
      data['Q25'] = aux_answers.q25 !== "" ? aux_answers.q25 : '-';
      data['Q26'] = aux_answers.q26 !== "" ? aux_answers.q26 : '-';
      data['Q27'] = aux_answers.q27 !== "" ? aux_answers.q27 : '-';
      data['Q28'] = aux_answers.q28 !== "" ? aux_answers.q28 : '-';
      data['Q29'] = aux_answers.q29 !== "" ? aux_answers.q29 : '-';
      data['Q30'] = aux_answers.q30 !== "" ? aux_answers.q30 : '-';
      data['Q31'] = aux_answers.q31 !== "" ? aux_answers.q31 : '-';
      data['Q32'] = aux_answers.q32 !== "" ? aux_answers.q32 : '-';
      data['Q33'] = aux_answers.q33 !== "" ? aux_answers.q33 : '-';
      data['Q34'] = aux_answers.q34 !== "" ? aux_answers.q34 : '-';
      data['Q35'] = aux_answers.q35 !== "" ? aux_answers.q35 : '-';
      data['Q36'] = aux_answers.q36 !== "" ? aux_answers.q36 : '-';
      data['Q37'] = aux_answers.q37 !== "" ? aux_answers.q37 : '-';
      data['Q38'] = aux_answers.q38 !== "" ? aux_answers.q38 : '-';
      data['Q39'] = aux_answers.q39 !== "" ? aux_answers.q39 : '-';
      data['Q40'] = aux_answers.q40 !== "" ? aux_answers.q40 : '-';
      data['Q41'] = aux_answers.q41 !== "" ? aux_answers.q41 : '-';
      data['Q42'] = aux_answers.q42 !== "" ? aux_answers.q42 : '-';
      data['Q43'] = aux_answers.q43 !== "" ? aux_answers.q43 : '-';
      data['Q44'] = aux_answers.q44 !== "" ? aux_answers.q44 : '-';
      data['Q45'] = aux_answers.q45 !== "" ? aux_answers.q45 : '-';
      data['Q46'] = aux_answers.q46 !== "" ? aux_answers.q46 : '-';
      data['Q47'] = aux_answers.q47 !== "" ? aux_answers.q47 : '-';
      data['Q48'] = aux_answers.q48 !== "" ? aux_answers.q48 : '-';
      data['Q49'] = aux_answers.q49 !== "" ? aux_answers.q49 : '-';
      data['Q50'] = aux_answers.q50 !== "" ? aux_answers.q50 : '-';
      data['Q51'] = aux_answers.q51 !== "" ? aux_answers.q51 : '-';
      data['Q52'] = aux_answers.q52 !== "" ? aux_answers.q52 : '-';
      data['Q53'] = aux_answers.q53 !== "" ? aux_answers.q53 : '-';
      data['Q54'] = aux_answers.q54 !== "" ? aux_answers.q54 : '-';
      data['Q55'] = aux_answers.q55 !== "" ? aux_answers.q55 : '-';
      data['Q56'] = aux_answers.q56 !== "" ? aux_answers.q56 : '-';
      data['Q57'] = aux_answers.q57 !== "" ? aux_answers.q57 : '-';
      data['Q58'] = aux_answers.q58 !== "" ? aux_answers.q58 : '-';
      data['Q59'] = aux_answers.q59 !== "" ? aux_answers.q59 : '-';
      data['Q60'] = aux_answers.q60 !== "" ? aux_answers.q60 : '-';
      data['Q61'] = aux_answers.q61 !== "" ? aux_answers.q61 : '-';
      data['Q62'] = aux_answers.q62 !== "" ? aux_answers.q62 : '-';
      data['Q63'] = aux_answers.q63 !== "" ? aux_answers.q63 : '-';
      data['Q64'] = aux_answers.q64 !== "" ? aux_answers.q64 : '-';
      data['Q65'] = aux_answers.q65 !== "" ? aux_answers.q65 : '-';
      data['Q66'] = aux_answers.q66 !== "" ? aux_answers.q66 : '-';
      data['Q67'] = aux_answers.q67 !== "" ? aux_answers.q67 : '-';
      data['Q68'] = aux_answers.q68 !== "" ? aux_answers.q68 : '-';
      data['Q69'] = aux_answers.q69 !== "" ? aux_answers.q69 : '-';
      data['Q70'] = aux_answers.q70 !== "" ? aux_answers.q70 : '-';
      data['Q71'] = aux_answers.q71 !== "" ? aux_answers.q71 : '-';
      data['Q72'] = aux_answers.q72 !== "" ? aux_answers.q72 : '-';
      data['Calificación final'] = employee.final_risk_score
      data['Nivel de riesgo final'] = this.evaluateFinal(employee.final_risk_score);
      data['Calificación de Ambiente de trabajo'] = employee.cat_work_ambient_score;
      data['Nivel de riesgo en Ambiente de trabajo'] = this.evaluateCatWorkAmbient(employee.cat_work_ambient_score);
      data['Calificación de Factores propios de la actividad'] = employee.cat_activity_factors_score;
      data['Nivel de riesgo en Factores propios de la actividad'] = this.evaluateCatWorkActivityF(employee.cat_activity_factors_score);
      data['Calificación de Organización del tiempo de trabajo'] = employee.cat_work_time_score;
      data['Nivel de riesgo en Organización del tiempo de trabajo'] = this.evaluateCatWorkTime(employee.cat_work_time_score);
      data['Calificación de Liderazgo y relaciones en el trabajo'] = employee.cat_work_relations_score;
      data['Nivel de riesgo en Liderazgo y relaciones en el trabajo'] = this.evaluateCatWorkRelations(employee.cat_work_relations_score);
      data['Calificación de Entorno organizacional'] = employee.cat_organization_score;
      data['Nivel de riesgo en Entorno organizacional'] = this.evaluateCatOrganization(employee.cat_organization_score);
      data['Calificación de Condiciones en el ambiente de trabajo'] = employee.dom_work_conditions_score;
      data['Nivel de riesgo en Condiciones en el ambiente de trabajo'] = this.evaluateDomWorkConditions(employee.dom_work_conditions_score);
      data['Calificación de Carga de trabajo'] = employee.dom_work_load_score;
      data['Nivel de riesgo en Carga de trabajo'] = this.evaluateDomWorkLoad(employee.dom_work_load_score);
      data['Calificación de Falta de control sobre el trabajo'] = employee.dom_missing_control_score;
      data['Nivel de riesgo en Falta de control sobre el trabajo'] = this.evaluateDomMissingControl(employee.dom_missing_control_score);
      data['Calificación de Jornada de trabajo'] = employee.dom_work_hours_score;
      data['Nivel de riesgo en Jornada de trabajo'] = this.evaluateDomWorkHours(employee.dom_work_hours_score);
      data['Calificación de Interferencia en la relación trabajo-familia'] = employee.dom_family_score;
      data['Nivel de riesgo en Interferencia en la relación trabajo-familia'] = this.evaluateDomFamily(employee.dom_family_score);
      data['Calificación de Liderazgo'] = employee.dom_leadership_score;
      data['Nivel de riesgo en Liderazgo'] = this.evaluateDomLeadership(employee.dom_leadership_score);
      data['Calificación de Relaciones en el trabajo'] = employee.dom_work_relations_score;
      data['Nivel de riesgo en Relaciones en el trabajo'] = this.evaluateDomWorkRelations(employee.dom_work_relations_score);
      data['Calificación de Violencia'] = employee.dom_violence_score;
      data['Nivel de riesgo en Violencia'] = this.evaluateDomViolence(employee.dom_violence_score);
      data['Calificación de Reconocimiento del desempeño'] = employee.dom_recognition_score;
      data['Nivel de riesgo en Reconocimiento del desempeño'] = this.evaluateDomRecognitions(employee.dom_recognition_score);
      data['Calificación de Insuficiente sentido de pertenencia e, inestabilidad'] = employee.dom_instability_score;
      data['Nivel de riesgo en Insuficiente sentido de pertenencia e, inestabilidad'] = this.evaluateDomInstability(employee.dom_instability_score);
      array_data.push(data);
    };
    this.toExcelService.exportAsExcelFile(array_data,"resultados q5");
    this.loadingPDF = false;
  }

  async downloadExcelQ1(id){
    this.loadingPDF = true;
    let array_data = [];
    const res = await this.questionnaireService.getFullResults(id, 1);
    await this.getSocioDemographicData(id);
    const employees = res.data.employees;
    try{
      for (let employee of employees){
        let data = {};
        data['Empleado ID'] = employee.id_employee;
        data['Área'] = employee.area;
        data['Nombre'] = employee.name;
        data['Email'] = employee.application_email;
        if(this.qSD.business)
          data['Razon Social'] = employee.business !== null ? employee.business : '-';
        if(this.qSD.gender)
          data['Sexo'] = employee.gender !== null ? employee.gender : '-';
        if(this.qSD.age)
          data['Edad'] = employee.age !== null ? employee.age : '-';
        if(this.qSD.age_segmented)
          data['Edad en Años'] = employee.age_range !== null ? employee.age_range : '-';
        if(this.qSD.civil_status)
          data['Estado Civil'] = employee.civil_status !== null ? employee.civil_status : '-';
        if(this.qSD.education_level)
          data['Nivel de Estudios'] = employee.education_level !== null ? employee.education_level : '-';
        if(this.qSD.occupation)
          data['Puesto'] = employee.occupation !== null ? employee.occupation : '-';
        if(this.qSD.position_type)
          data['Tipo de Puesto'] = employee.position_type !== null ? employee.position_type : '-';
        if(this.qSD.recruitment_type)
          data['Tipo de Contratación'] = employee.recruitment_type !== null ? employee.recruitment_type : '-';
        if(this.qSD.employee_type)
          data['Tipo de Personal'] = employee.employee_type !== null ? employee.employee_type : '-';
        if(this.qSD.working_hours_type)
          data['Tipo de Jornada de Trabajo'] = employee.working_hours_type !== null ? employee.working_hours_type : '-';
        if(this.qSD.working_hours_rotation)
          data['Realiza Rotacion de Turnos'] = employee.working_hours_rotation !== null ? employee.working_hours_rotation : '-';
        if(this.qSD.work_experience)
          data['Tiempo Experiencia Laboral'] = employee.work_experience !== null ? employee.work_experience : '-';
        if(this.qSD.current_position_time)
          data['Posición Actual'] = employee.current_position_time !== null ? employee.current_position_time : '-';
        if(this.qSD.state)
          data['Estado'] = employee.state;
        let aux_answers = JSON.parse(employee.answers);
        if(aux_answers.s1 !== undefined){
          data['s1'] = aux_answers.s1 !== "" ? aux_answers.s1 : '-';
        }
        if(aux_answers.s1_1 !== undefined && aux_answers.s1_2 !== undefined && aux_answers.s1_3 !== undefined && aux_answers.s1_4 !== undefined && aux_answers.s1_5 !== undefined && aux_answers.s1_6 !== undefined){
          data['s1_1'] = aux_answers.s1_1 !== "" ? aux_answers.s1_1 : '-';
          data['s1_2'] = aux_answers.s1_2 !== "" ? aux_answers.s1_2 : '-';
          data['s1_3'] = aux_answers.s1_3 !== "" ? aux_answers.s1_3 : '-';
          data['s1_4'] = aux_answers.s1_4 !== "" ? aux_answers.s1_4 : '-';
          data['s1_5'] = aux_answers.s1_5 !== "" ? aux_answers.s1_5 : '-';
          data['s1_6'] = aux_answers.s1_6 !== "" ? aux_answers.s1_6 : '-'; 
        }
        data['s2_1'] = aux_answers.s2_1 !== "" ? aux_answers.s2_1 : '-';
        data['s2_2'] = aux_answers.s2_2 !== "" ? aux_answers.s2_2 : '-';
        data['s3_1'] = aux_answers.s3_1 !== "" ? aux_answers.s3_1 : '-';
        data['s3_2'] = aux_answers.s3_2 !== "" ? aux_answers.s3_2 : '-';
        data['s3_3'] = aux_answers.s3_3 !== "" ? aux_answers.s3_3 : '-';
        data['s3_4'] = aux_answers.s3_4 !== "" ? aux_answers.s3_4 : '-';
        data['s3_5'] = aux_answers.s3_5 !== "" ? aux_answers.s3_5 : '-';
        data['s3_6'] = aux_answers.s3_6 !== "" ? aux_answers.s3_6 : '-';
        data['s3_7'] = aux_answers.s3_7 !== "" ? aux_answers.s3_7 : '-';
        data['s4_1'] = aux_answers.s4_1 !== "" ? aux_answers.s4_1 : '-';
        data['s4_2'] = aux_answers.s4_2 !== "" ? aux_answers.s4_2 : '-';
        data['s4_3'] = aux_answers.s4_3 !== "" ? aux_answers.s4_3 : '-';
        data['s4_4'] = aux_answers.s4_4 !== "" ? aux_answers.s4_4 : '-';
        data['s4_5'] = aux_answers.s4_5 !== "" ? aux_answers.s4_5 : '-';
  
        array_data.push(data);
      };
      this.toExcelService.exportAsExcelFile(array_data,"resultados q1");
    }catch(error){
      this.notifierService.notify('error', 'Ocurrio un problema al descargar'); 
    }
    this.loadingPDF = false;
  }

  async downloadExcelQ2(id, type){
    this.loadingPDF = true;
    let array_data = [];
    const res = await this.questionnaireService.getFullResultsQ23(type, id);
    await this.getSocioDemographicData(id);
    const employees = res.data.responses;
    try{
      for (let employee of employees){
        let data = {};
        data['Empleado ID'] = employee.id_employee;
        data['Área'] = employee.area;
        data['Nombre'] = employee.name;
        data['Email'] = employee.application_email;
        if(this.qSD.business)
          data['Razon Social'] = employee.business !== null ? employee.business : '-';
        if(this.qSD.gender)
          data['Sexo'] = employee.gender !== null ? employee.gender : '-';
        if(this.qSD.age)
          data['Edad'] = employee.age !== null ? employee.age : '-';
        if(this.qSD.age_segmented)
          data['Edad en Años'] = employee.age_range !== null ? employee.age_range : '-';
        if(this.qSD.civil_status)
          data['Estado Civil'] = employee.civil_status !== null ? employee.civil_status : '-';
        if(this.qSD.education_level)
          data['Nivel de Estudios'] = employee.education_level !== null ? employee.education_level : '-';
        if(this.qSD.occupation)
          data['Puesto'] = employee.occupation !== null ? employee.occupation : '-';
        if(this.qSD.position_type)
          data['Tipo de Puesto'] = employee.position_type !== null ? employee.position_type : '-';
        if(this.qSD.recruitment_type)
          data['Tipo de Contratación'] = employee.recruitment_type !== null ? employee.recruitment_type : '-';
        if(this.qSD.employee_type)
          data['Tipo de Personal'] = employee.employee_type !== null ? employee.employee_type : '-';
        if(this.qSD.working_hours_type)
          data['Tipo de Jornada de Trabajo'] = employee.working_hours_type !== null ? employee.working_hours_type : '-';
        if(this.qSD.working_hours_rotation)
          data['Realiza Rotacion de Turnos'] = employee.working_hours_rotation !== null ? employee.working_hours_rotation : '-';
        if(this.qSD.work_experience)
          data['Tiempo Experiencia Laboral'] = employee.work_experience !== null ? employee.work_experience : '-';
        if(this.qSD.current_position_time)
          data['Posición Actual'] = employee.current_position_time !== null ? employee.current_position_time : '-';
        if(this.qSD.state)
          data['Estado'] = employee.state;
        let aux_answers = JSON.parse(employee.answers);
        data['Q1'] = aux_answers.q1 !== "" ? aux_answers.q1 : '-';
        data['Q2'] = aux_answers.q2 !== "" ? aux_answers.q2 : '-';
        data['Q3'] = aux_answers.q3 !== "" ? aux_answers.q3 : '-';
        data['Q4'] = aux_answers.q4 !== "" ? aux_answers.q4 : '-';
        data['Q5'] = aux_answers.q5 !== "" ? aux_answers.q5 : '-';
        data['Q6'] = aux_answers.q6 !== "" ? aux_answers.q6 : '-';
        data['Q7'] = aux_answers.q7 !== "" ? aux_answers.q7 : '-';
        data['Q8'] = aux_answers.q8 !== "" ? aux_answers.q8 : '-';
        data['Q9'] = aux_answers.q9 !== "" ? aux_answers.q9 : '-';
        data['Q10'] = aux_answers.q10 !== "" ? aux_answers.q10 : '-';
        data['Q11'] = aux_answers.q11 !== "" ? aux_answers.q11 : '-';
        data['Q12'] = aux_answers.q12 !== "" ? aux_answers.q12 : '-';
        data['Q13'] = aux_answers.q13 !== "" ? aux_answers.q13 : '-';
        data['Q14'] = aux_answers.q14 !== "" ? aux_answers.q14 : '-';
        data['Q15'] = aux_answers.q15 !== "" ? aux_answers.q15 : '-';
        data['Q16'] = aux_answers.q16 !== "" ? aux_answers.q16 : '-';
        data['Q17'] = aux_answers.q17 !== "" ? aux_answers.q17 : '-';
        data['Q18'] = aux_answers.q18 !== "" ? aux_answers.q18 : '-';
        data['Q19'] = aux_answers.q19 !== "" ? aux_answers.q19 : '-';
        data['Q20'] = aux_answers.q20 !== "" ? aux_answers.q20 : '-';
        data['Q21'] = aux_answers.q21 !== "" ? aux_answers.q21 : '-';
        data['Q22'] = aux_answers.q22 !== "" ? aux_answers.q22 : '-';
        data['Q23'] = aux_answers.q23 !== "" ? aux_answers.q23 : '-';
        data['Q24'] = aux_answers.q24 !== "" ? aux_answers.q24 : '-';
        data['Q25'] = aux_answers.q25 !== "" ? aux_answers.q25 : '-';
        data['Q26'] = aux_answers.q26 !== "" ? aux_answers.q26 : '-';
        data['Q27'] = aux_answers.q27 !== "" ? aux_answers.q27 : '-';
        data['Q28'] = aux_answers.q28 !== "" ? aux_answers.q28 : '-';
        data['Q29'] = aux_answers.q29 !== "" ? aux_answers.q29 : '-';
        data['Q30'] = aux_answers.q30 !== "" ? aux_answers.q30 : '-';
        data['Q31'] = aux_answers.q31 !== "" ? aux_answers.q31 : '-';
        data['Q32'] = aux_answers.q32 !== "" ? aux_answers.q32 : '-';
        data['Q33'] = aux_answers.q33 !== "" ? aux_answers.q33 : '-';
        data['Q34'] = aux_answers.q34 !== "" ? aux_answers.q34 : '-';
        data['Q35'] = aux_answers.q35 !== "" ? aux_answers.q35 : '-';
        data['Q36'] = aux_answers.q36 !== "" ? aux_answers.q36 : '-';
        data['Q37'] = aux_answers.q37 !== "" ? aux_answers.q37 : '-';
        data['Q38'] = aux_answers.q38 !== "" ? aux_answers.q38 : '-';
        data['Q39'] = aux_answers.q39 !== "" ? aux_answers.q39 : '-';
        data['Q40'] = aux_answers.q40 !== "" ? aux_answers.q40 : '-';
        data['Q41'] = aux_answers.q41 !== "" ? aux_answers.q41 : '-';
        data['Q42'] = aux_answers.q42 !== "" ? aux_answers.q42 : '-';
        data['Q43'] = aux_answers.q43 !== "" ? aux_answers.q43 : '-';
        data['Q44'] = aux_answers.q44 !== "" ? aux_answers.q44 : '-';
        data['Q45'] = aux_answers.q45 !== "" ? aux_answers.q45 : '-';
        data['Q46'] = aux_answers.q46 !== "" ? aux_answers.q46 : '-';
        data['Calificación final'] = employee.final_risk_score
        data['Nivel de riesgo final'] = this.evaluateFinal(employee.final_risk_score);
        data['Calificación de Ambiente de trabajo'] = employee.cat_work_ambient_score;
        data['Nivel de riesgo en Ambiente de trabajo'] = this.evaluateCatWorkAmbient(employee.cat_work_ambient_score);
        data['Calificación de Factores propios de la actividad'] = employee.cat_activity_factors_score;
        data['Nivel de riesgo en Factores propios de la actividad'] = this.evaluateCatWorkActivityF(employee.cat_activity_factors_score);
        data['Calificación de Organización del tiempo de trabajo'] = employee.cat_work_time_score;
        data['Nivel de riesgo en Organización del tiempo de trabajo'] = this.evaluateCatWorkTime(employee.cat_work_time_score);
        data['Calificación de Liderazgo y relaciones en el trabajo'] = employee.cat_work_relations_score;
        data['Nivel de riesgo en Liderazgo y relaciones en el trabajo'] = this.evaluateCatWorkRelations(employee.cat_work_relations_score);
        data['Calificación de Condiciones en el ambiente de trabajo'] = employee.dom_work_conditions_score;
        data['Nivel de riesgo en Condiciones en el ambiente de trabajo'] = this.evaluateDomWorkConditions(employee.dom_work_conditions_score);
        data['Calificación de Carga de trabajo'] = employee.dom_work_load_score;
        data['Nivel de riesgo en Carga de trabajo'] = this.evaluateDomWorkLoad(employee.dom_work_load_score);
        data['Calificación de Falta de control sobre el trabajo'] = employee.dom_missing_control_score;
        data['Nivel de riesgo en Falta de control sobre el trabajo'] = this.evaluateDomMissingControl(employee.dom_missing_control_score);
        data['Calificación de Jornada de trabajo'] = employee.dom_work_hours_score;
        data['Nivel de riesgo en Jornada de trabajo'] = this.evaluateDomWorkHours(employee.dom_work_hours_score);
        data['Calificación de Interferencia en la relación trabajo-familia'] = employee.dom_family_score;
        data['Nivel de riesgo en Interferencia en la relación trabajo-familia'] = this.evaluateDomFamily(employee.dom_family_score);
        data['Calificación de Liderazgo'] = employee.dom_leadership_score;
        data['Nivel de riesgo en Liderazgo'] = this.evaluateDomLeadership(employee.dom_leadership_score);
        data['Calificación de Relaciones en el trabajo'] = employee.dom_work_relations_score;
        data['Nivel de riesgo en Relaciones en el trabajo'] = this.evaluateDomWorkRelations(employee.dom_work_relations_score);
        data['Calificación de Violencia'] = employee.dom_violence_score;
        data['Nivel de riesgo en Violencia'] = this.evaluateDomViolence(employee.dom_violence_score);
  
        array_data.push(data);
      };
      this.toExcelService.exportAsExcelFile(array_data,"resultados q2");
    }catch(error){
      this.notifierService.notify('error', 'Ocurrio un problema al descargar'); 
    }
    this.loadingPDF = false;
  }

  async downloadExcelQ3(id, type){
    this.loadingPDF = true;
    let array_data = [];
    const res = await this.questionnaireService.getFullResultsQ23(type, id);
    await this.getSocioDemographicData(id);
    const employees = res.data.responses;
    try{
      for (let employee of employees){
        let data = {};
        data['Empleado ID'] = employee.id_employee;
        data['Área'] = employee.area;
        data['Nombre'] = employee.name;
        data['Email'] = employee.application_email;
        if(this.qSD.business)
          data['Razon Social'] = employee.business !== null ? employee.business : '-';
        if(this.qSD.gender)
          data['Sexo'] = employee.gender !== null ? employee.gender : '-';
        if(this.qSD.age)
          data['Edad'] = employee.age !== null ? employee.age : '-';
        if(this.qSD.age_segmented)
          data['Edad en Años'] = employee.age_range !== null ? employee.age_range : '-';
        if(this.qSD.civil_status)
          data['Estado Civil'] = employee.civil_status !== null ? employee.civil_status : '-';
        if(this.qSD.education_level)
          data['Nivel de Estudios'] = employee.education_level !== null ? employee.education_level : '-';
        if(this.qSD.occupation)
          data['Puesto'] = employee.occupation !== null ? employee.occupation : '-';
        if(this.qSD.position_type)
          data['Tipo de Puesto'] = employee.position_type !== null ? employee.position_type : '-';
        if(this.qSD.recruitment_type)
          data['Tipo de Contratación'] = employee.recruitment_type !== null ? employee.recruitment_type : '-';
        if(this.qSD.employee_type)
          data['Tipo de Personal'] = employee.employee_type !== null ? employee.employee_type : '-';
        if(this.qSD.working_hours_type)
          data['Tipo de Jornada de Trabajo'] = employee.working_hours_type !== null ? employee.working_hours_type : '-';
        if(this.qSD.working_hours_rotation)
          data['Realiza Rotacion de Turnos'] = employee.working_hours_rotation !== null ? employee.working_hours_rotation : '-';
        if(this.qSD.work_experience)
          data['Tiempo Experiencia Laboral'] = employee.work_experience !== null ? employee.work_experience : '-';
        if(this.qSD.current_position_time)
          data['Posición Actual'] = employee.current_position_time !== null ? employee.current_position_time : '-';
        if(this.qSD.state)
          data['Estado'] = employee.state;
        let aux_answers = JSON.parse(employee.answers);
        data['Q1'] = aux_answers.q1 !== "" ? aux_answers.q1 : '-';
        data['Q2'] = aux_answers.q2 !== "" ? aux_answers.q2 : '-';
        data['Q3'] = aux_answers.q3 !== "" ? aux_answers.q3 : '-';
        data['Q4'] = aux_answers.q4 !== "" ? aux_answers.q4 : '-';
        data['Q5'] = aux_answers.q5 !== "" ? aux_answers.q5 : '-';
        data['Q6'] = aux_answers.q6 !== "" ? aux_answers.q6 : '-';
        data['Q7'] = aux_answers.q7 !== "" ? aux_answers.q7 : '-';
        data['Q8'] = aux_answers.q8 !== "" ? aux_answers.q8 : '-';
        data['Q9'] = aux_answers.q9 !== "" ? aux_answers.q9 : '-';
        data['Q10'] = aux_answers.q10 !== "" ? aux_answers.q10 : '-';
        data['Q11'] = aux_answers.q11 !== "" ? aux_answers.q11 : '-';
        data['Q12'] = aux_answers.q12 !== "" ? aux_answers.q12 : '-';
        data['Q13'] = aux_answers.q13 !== "" ? aux_answers.q13 : '-';
        data['Q14'] = aux_answers.q14 !== "" ? aux_answers.q14 : '-';
        data['Q15'] = aux_answers.q15 !== "" ? aux_answers.q15 : '-';
        data['Q16'] = aux_answers.q16 !== "" ? aux_answers.q16 : '-';
        data['Q17'] = aux_answers.q17 !== "" ? aux_answers.q17 : '-';
        data['Q18'] = aux_answers.q18 !== "" ? aux_answers.q18 : '-';
        data['Q19'] = aux_answers.q19 !== "" ? aux_answers.q19 : '-';
        data['Q20'] = aux_answers.q20 !== "" ? aux_answers.q20 : '-';
        data['Q21'] = aux_answers.q21 !== "" ? aux_answers.q21 : '-';
        data['Q22'] = aux_answers.q22 !== "" ? aux_answers.q22 : '-';
        data['Q23'] = aux_answers.q23 !== "" ? aux_answers.q23 : '-';
        data['Q24'] = aux_answers.q24 !== "" ? aux_answers.q24 : '-';
        data['Q25'] = aux_answers.q25 !== "" ? aux_answers.q25 : '-';
        data['Q26'] = aux_answers.q26 !== "" ? aux_answers.q26 : '-';
        data['Q27'] = aux_answers.q27 !== "" ? aux_answers.q27 : '-';
        data['Q28'] = aux_answers.q28 !== "" ? aux_answers.q28 : '-';
        data['Q29'] = aux_answers.q29 !== "" ? aux_answers.q29 : '-';
        data['Q30'] = aux_answers.q30 !== "" ? aux_answers.q30 : '-';
        data['Q31'] = aux_answers.q31 !== "" ? aux_answers.q31 : '-';
        data['Q32'] = aux_answers.q32 !== "" ? aux_answers.q32 : '-';
        data['Q33'] = aux_answers.q33 !== "" ? aux_answers.q33 : '-';
        data['Q34'] = aux_answers.q34 !== "" ? aux_answers.q34 : '-';
        data['Q35'] = aux_answers.q35 !== "" ? aux_answers.q35 : '-';
        data['Q36'] = aux_answers.q36 !== "" ? aux_answers.q36 : '-';
        data['Q37'] = aux_answers.q37 !== "" ? aux_answers.q37 : '-';
        data['Q38'] = aux_answers.q38 !== "" ? aux_answers.q38 : '-';
        data['Q39'] = aux_answers.q39 !== "" ? aux_answers.q39 : '-';
        data['Q40'] = aux_answers.q40 !== "" ? aux_answers.q40 : '-';
        data['Q41'] = aux_answers.q41 !== "" ? aux_answers.q41 : '-';
        data['Q42'] = aux_answers.q42 !== "" ? aux_answers.q42 : '-';
        data['Q43'] = aux_answers.q43 !== "" ? aux_answers.q43 : '-';
        data['Q44'] = aux_answers.q44 !== "" ? aux_answers.q44 : '-';
        data['Q45'] = aux_answers.q45 !== "" ? aux_answers.q45 : '-';
        data['Q46'] = aux_answers.q46 !== "" ? aux_answers.q46 : '-';
        data['Q47'] = aux_answers.q47 !== "" ? aux_answers.q47 : '-';
        data['Q48'] = aux_answers.q48 !== "" ? aux_answers.q48 : '-';
        data['Q49'] = aux_answers.q49 !== "" ? aux_answers.q49 : '-';
        data['Q50'] = aux_answers.q50 !== "" ? aux_answers.q50 : '-';
        data['Q51'] = aux_answers.q51 !== "" ? aux_answers.q51 : '-';
        data['Q52'] = aux_answers.q52 !== "" ? aux_answers.q52 : '-';
        data['Q53'] = aux_answers.q53 !== "" ? aux_answers.q53 : '-';
        data['Q54'] = aux_answers.q54 !== "" ? aux_answers.q54 : '-';
        data['Q55'] = aux_answers.q55 !== "" ? aux_answers.q55 : '-';
        data['Q56'] = aux_answers.q56 !== "" ? aux_answers.q56 : '-';
        data['Q57'] = aux_answers.q57 !== "" ? aux_answers.q57 : '-';
        data['Q58'] = aux_answers.q58 !== "" ? aux_answers.q58 : '-';
        data['Q59'] = aux_answers.q59 !== "" ? aux_answers.q59 : '-';
        data['Q60'] = aux_answers.q60 !== "" ? aux_answers.q60 : '-';
        data['Q61'] = aux_answers.q61 !== "" ? aux_answers.q61 : '-';
        data['Q62'] = aux_answers.q62 !== "" ? aux_answers.q62 : '-';
        data['Q63'] = aux_answers.q63 !== "" ? aux_answers.q63 : '-';
        data['Q64'] = aux_answers.q64 !== "" ? aux_answers.q64 : '-';
        data['Q65'] = aux_answers.q65 !== "" ? aux_answers.q65 : '-';
        data['Q66'] = aux_answers.q66 !== "" ? aux_answers.q66 : '-';
        data['Q67'] = aux_answers.q67 !== "" ? aux_answers.q67 : '-';
        data['Q68'] = aux_answers.q68 !== "" ? aux_answers.q68 : '-';
        data['Q69'] = aux_answers.q69 !== "" ? aux_answers.q69 : '-';
        data['Q70'] = aux_answers.q70 !== "" ? aux_answers.q70 : '-';
        data['Q71'] = aux_answers.q71 !== "" ? aux_answers.q71 : '-';
        data['Q72'] = aux_answers.q72 !== "" ? aux_answers.q72 : '-';
        data['Calificación final'] = employee.final_risk_score
        data['Nivel de riesgo final'] = this.evaluateFinal(employee.final_risk_score);
        data['Calificación de Ambiente de trabajo'] = employee.cat_work_ambient_score;
        data['Nivel de riesgo en Ambiente de trabajo'] = this.evaluateCatWorkAmbient(employee.cat_work_ambient_score);
        data['Calificación de Factores propios de la actividad'] = employee.cat_activity_factors_score;
        data['Nivel de riesgo en Factores propios de la actividad'] = this.evaluateCatWorkActivityF(employee.cat_activity_factors_score);
        data['Calificación de Organización del tiempo de trabajo'] = employee.cat_work_time_score;
        data['Nivel de riesgo en Organización del tiempo de trabajo'] = this.evaluateCatWorkTime(employee.cat_work_time_score);
        data['Calificación de Liderazgo y relaciones en el trabajo'] = employee.cat_work_relations_score;
        data['Nivel de riesgo en Liderazgo y relaciones en el trabajo'] = this.evaluateCatWorkRelations(employee.cat_work_relations_score);
        data['Calificación de Entorno organizacional'] = employee.cat_organization_score;
        data['Nivel de riesgo en Entorno organizacional'] = this.evaluateCatOrganization(employee.cat_organization_score);
        data['Calificación de Condiciones en el ambiente de trabajo'] = employee.dom_work_conditions_score;
        data['Nivel de riesgo en Condiciones en el ambiente de trabajo'] = this.evaluateDomWorkConditions(employee.dom_work_conditions_score);
        data['Calificación de Carga de trabajo'] = employee.dom_work_load_score;
        data['Nivel de riesgo en Carga de trabajo'] = this.evaluateDomWorkLoad(employee.dom_work_load_score);
        data['Calificación de Falta de control sobre el trabajo'] = employee.dom_missing_control_score;
        data['Nivel de riesgo en Falta de control sobre el trabajo'] = this.evaluateDomMissingControl(employee.dom_missing_control_score);
        data['Calificación de Jornada de trabajo'] = employee.dom_work_hours_score;
        data['Nivel de riesgo en Jornada de trabajo'] = this.evaluateDomWorkHours(employee.dom_work_hours_score);
        data['Calificación de Interferencia en la relación trabajo-familia'] = employee.dom_family_score;
        data['Nivel de riesgo en Interferencia en la relación trabajo-familia'] = this.evaluateDomFamily(employee.dom_family_score);
        data['Calificación de Liderazgo'] = employee.dom_leadership_score;
        data['Nivel de riesgo en Liderazgo'] = this.evaluateDomLeadership(employee.dom_leadership_score);
        data['Calificación de Relaciones en el trabajo'] = employee.dom_work_relations_score;
        data['Nivel de riesgo en Relaciones en el trabajo'] = this.evaluateDomWorkRelations(employee.dom_work_relations_score);
        data['Calificación de Violencia'] = employee.dom_violence_score;
        data['Nivel de riesgo en Violencia'] = this.evaluateDomViolence(employee.dom_violence_score);
        data['Calificación de Reconocimiento del desempeño'] = employee.dom_recognition_score;
        data['Nivel de riesgo en Reconocimiento del desempeño'] = this.evaluateDomRecognitions(employee.dom_recognition_score);
        data['Calificación de Insuficiente sentido de pertenencia e, inestabilidad'] = employee.dom_instability_score;
        data['Nivel de riesgo en Insuficiente sentido de pertenencia e, inestabilidad'] = this.evaluateDomInstability(employee.dom_instability_score);
  
        array_data.push(data);
      };
      this.toExcelService.exportAsExcelFile(array_data,"resultados q3");
    }catch(error){
      this.notifierService.notify('error', 'Ocurrio un problema al descargar'); 
    }
    this.loadingPDF = false;
  }

  downloadReport(id, type, name: string) {
    if (type === 1) {
      this.downloadReportQ1(id, name, type);
    } else if (type === 2 || type === 3) {
      this.downloadReportQ23(id, type, name);
    } else {
      this.downloadReportQ1(id, name, type);
      setTimeout(() => {
        this.downloadReportQ23(id, type, name);
      }, 500);
    }
  }

  async downloadReportQ23(id, type, name: string) {
    this.loadingPDF = true;
    const res = await this.questionnaireService.getFullResultsQ23(type, id);
    const employees = res.data.responses;
    let doc = new jsPDF();
    let i = 0;

    try{
      for (let employee of employees) { 
        i += 1;
        let indexData = 0;
        doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
        doc.setFontStyle("bold");
        doc.setFontSize(18);
        doc.text(`${name}`, 105, 30, null, null, 'center');
        doc.setFontStyle("normal");
        if (type === 2 ) {
          doc.setFontSize(10);
          doc.text('Cuestionario para Identificar los Factores de', 105, 40, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 44, null, null, 'center');
          doc.setFontSize(12);
          doc.text('El objetivo de la evaluación es hacer una identificación y análisis los Factores de Riesgo', 20, 55);
          doc.text('Psicosocial para de esta manera establecer medidas de prevención y acciones de control ', 20, 60);
          doc.text('de los factores de riesgo psicosocial, de la violencia laboral y promoción promoción del ', 20, 65);
          doc.text('entorno organizacional favorable.', 20, 70);
          
        } else {
          doc.setFontSize(10);
          doc.text('Cuestionario para Identificar los Factores de Riesgo Psicosocial', 100, 40, null, null, 'center');
          doc.text('y Evaluar el Entorno Organizacional en los centros de trabajo', 100, 44, null, null, 'center');
          doc.setFontSize(12);
          doc.text('El objetivo de la evaluación es hacer una identificación y análisis los Factores de Riesgo', 20, 55);
          doc.text('Psicosocial y evaluación del Entorno Organizacional para de esta manera establecer medidas', 20, 60);
          doc.text('de prevención y acciones de control de los factores de riesgo psicosocial, de la violencia', 20, 65);
          doc.text('laboral y promoción del entorno organizacional favorable.', 20, 70);
        }      
  
        // Validate Form
        let next_y2 = 90;
        doc.setFontStyle("bold");
        doc.setFontSize(18);
        doc.text('Verifica tu identidad', 105, next_y2, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(12);
        next_y2 += 10;
        doc.text('Este cuestionario es personal y debe ser contestado por la persona a la que va dirigido.', 20, next_y2)
        doc.text('Por favor valida tus datos:', 20, next_y2 + 5);
        next_y2 += 22;
        doc.text('Nombre: ', 40, next_y2);
        doc.rect(60, next_y2 - 6, 125, 10);
        doc.text(employee.name, 62, next_y2);
        next_y2 += 14;
        doc.text('Email: ', 40, next_y2);
        doc.rect(60, next_y2 - 6, 125, 10);
        doc.text(employee.application_email, 62, next_y2);
        next_y2 += 25;
        doc.setFontStyle("bold");
        doc.setFontSize(13);
        doc.text(`Confirmo que soy ${employee.name}.`, 105, next_y2, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(14);
        doc.rect(100, next_y2 + 4, 10, 10);
        doc.setTextColor(255, 0, 0);
        doc.text('X', 105, next_y2 + 11, null, null, 'center');
        doc.setTextColor(0, 0, 0);
  
        next_y2 += 25;
        doc.setFontStyle("bold");
        doc.setFontSize(12);
        doc.text(`Confirmo que he comprendido el objetivo del cuestionario`, 105, next_y2, null, null, 'center');
        doc.text(`y que las instalaciones y mobiliario en el que lo estoy contestando son idóneas.`, 102, next_y2 + 5, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(14);
        doc.rect(100, next_y2 + 9, 10, 10);
        doc.setTextColor(255, 0, 0);
        doc.text('X', 105, next_y2 + 16, null, null, 'center');
        doc.setTextColor(0, 0, 0);
  
        next_y2 += 30;
        doc.setFontStyle("bold");
        doc.setFontSize(12);
        doc.text(`Confirmo que cualquier duda que tenga mientras que contesto el cuestionario,`, 105, next_y2, null, null, 'center');
        doc.text(`contactaré al número 5534482378 o enviaré un correo a contacto@sicuentame.com`, 100, next_y2 + 5, null, null, 'center');
        doc.text(`para resolverla`, 105, next_y2 + 10, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(14);
        doc.rect(100, next_y2 + 14, 10, 10);
        doc.setTextColor(255, 0, 0);
        doc.text('X', 105, next_y2 + 21, null, null, 'center');
        doc.setTextColor(0, 0, 0);
  
        // Questions
        let aux_answers = JSON.parse(employee.answers);
  
        doc.addPage('a4', 'p');
        doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
        let next_y3 = 30
        if (type === 2) {
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
              { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ2S1(aux_answers),
            head: [[{ content: 'Para responder las preguntas siguientes considere las condiciones de su centro de trabajo, así como la cantidad y ritmo de trabajo.', colSpan: 6 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 30
          
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ2S2(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las responsabilidades que tiene.', colSpan: 6 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 150,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ2S3(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con el tiempo destinado a su trabajo y sus responsabilidades familiares', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          next_y3 = 50
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ2S4(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con las decisiones que puede tomar en su trabajo.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 30
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ2S5(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con la capacitación e información que recibe sobre su trabajo.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 170,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ2S6(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes se refieren a las relaciones con sus compañeros de trabajo y su jefe.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 60
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.text('En mi trabajo debo brindar servicio a clientes o usuario', 20, next_y3);
          doc.rect(125, next_y3 - 6, 10, 10);
          doc.text(`${aux_answers.sec1 ? 'Si' : 'No'}`, 127, next_y3);
  
          if (aux_answers.sec1) {
            doc.autoTable({
              margin: { top: 10 },
              startY: next_y3 + 13,
              theme: "grid",
              tableLineColor: 0,
              headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
              columnStyles: {
                r1: { halign: 'center', minCellWidth: 27 },
                r2: { halign: 'center', minCellWidth: 25 },
                r3: { halign: 'center', minCellWidth: 24 },
                r4: { halign: 'center', minCellWidth: 20 },
                r5: { halign: 'center', minCellWidth: 21 },
              },
              bodyStyles: { valign: 'middle', cellPadding: 5 },
              columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
              { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
              body: this.getAnswersQ2S7(aux_answers),
              head: [[{
                content: 'Las preguntas siguientes están relacionadas con la atención a clientes y usuarios.', colSpan: 6
              }]],
              didParseCell: (data) => {
                if (data.row.index === 0) {
                  data.cell.styles.fillColor = [40, 170, 100];
                  data.cell.styles.textColor = [255, 255, 255]
                  data.cell.styles.fontStyle = 'bold';
                }
              }
            });
  
            next_y3 += 100;
          }
  
          next_y3 += 20;
  
          doc.text('Soy jefe de otros trabajadores', 20, next_y3);
          doc.rect(80, next_y3 - 6, 10, 10);
          doc.text(`${aux_answers.sec2 ? 'Si' : 'No'}`, 82, next_y3);
  
          if (aux_answers.sec2) {
            doc.autoTable({
              margin: { top: 10 },
              startY: next_y3 + 13,
              theme: "grid",
              tableLineColor: 0,
              headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
              columnStyles: {
                r1: { halign: 'center', minCellWidth: 27 },
                r2: { halign: 'center', minCellWidth: 25 },
                r3: { halign: 'center', minCellWidth: 24 },
                r4: { halign: 'center', minCellWidth: 20 },
                r5: { halign: 'center', minCellWidth: 21 },
              },
              bodyStyles: { valign: 'middle', cellPadding: 5 },
              columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
              { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
              body: this.getAnswersQ2S8(aux_answers),
              head: [[{
                content: 'Las preguntas siguientes están relacionadas con las actitudes de las personas que supervisa.', colSpan: 6
              }]],
              didParseCell: (data) => {
                if (data.row.index === 0) {
                  data.cell.styles.fillColor = [40, 170, 100];
                  data.cell.styles.textColor = [255, 255, 255]
                  data.cell.styles.fontStyle = 'bold';
                }
              }
            });
          } else {
            doc.text('Si su respuesta fue No, ha concluido el cuestionario.', 20, next_y3 + 15);
          }
        } else {
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de Riesgo Psicosocial', 105, 35, null, null, 'center');
          doc.text('y Evaluar el Entorno Organizacional en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S1(aux_answers),
            head: [[{ content: 'Para responder las preguntas siguientes considere las condiciones ambientales de su centro de trabajo.', colSpan: 6 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 170,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S2(aux_answers),
            head: [[{ content: 'Para responder a las preguntas siguientes piense en la cantidad y ritmo de trabajo que tiene.', colSpan: 6 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          next_y3 = 30;
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S3(aux_answers),
            head: [[{ content: 'Las preguntas siguientes están relacionadas con el esfuerzo mental que le exige su trabajo.', colSpan: 6 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 135,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S4(aux_answers),
            head: [[{ content: 'Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las responsabilidades que tiene.', colSpan: 6 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 30
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S5(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con su jornada de trabajo.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 30
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S6(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con las decisiones que puede tomar en su trabajo.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 30
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S7(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con cualquier tipo de cambio que ocurra en su trabajo (considere los últimos cambios realizados).', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 110,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S8(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con la capacitación e información que se le proporciona sobre su trabajo.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 30,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S9(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con el o los jefes con quien tiene contacto.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 30
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S10(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes se refieren a las relaciones con sus compañeros.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          next_y3 = 30
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S11(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con la información que recibe sobre su rendimiento en el trabajo, el reconocimiento, el sentido de pertenencia y la estabilidad que le ofrece su trabajo.', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          next_y3 = 30
  
          doc.autoTable({
            margin: { top: 10 },
            startY: next_y3 + 25,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
            columnStyles: {
              r1: { halign: 'center', minCellWidth: 27 },
              r2: { halign: 'center', minCellWidth: 25 },
              r3: { halign: 'center', minCellWidth: 24 },
              r4: { halign: 'center', minCellWidth: 20 },
              r5: { halign: 'center', minCellWidth: 21 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
            { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
            body: this.getAnswersQ3S12(aux_answers),
            head: [[{
              content: 'Las preguntas siguientes están relacionadas con actos de violencia laboral (malos tratos, acoso, hostigamiento, acoso psicológico).', colSpan: 6
            }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
  
          next_y3 = 60
  
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para Identificar los Factores de', 105, 35, null, null, 'center');
          doc.text('Riesgo Psicosocial en los centros de trabajo', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
  
          doc.text('En mi trabajo debo brindar servicio a clientes o usuario', 20, next_y3);
          doc.rect(125, next_y3 - 6, 10, 10);
          doc.text(`${aux_answers.sec1 ? 'Si' : 'No'}`, 127, next_y3);
  
          if (aux_answers.sec1) {
            doc.autoTable({
              margin: { top: 10 },
              startY: next_y3 + 13,
              theme: "grid",
              tableLineColor: 0,
              headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
              columnStyles: {
                r1: { halign: 'center', minCellWidth: 27 },
                r2: { halign: 'center', minCellWidth: 25 },
                r3: { halign: 'center', minCellWidth: 24 },
                r4: { halign: 'center', minCellWidth: 20 },
                r5: { halign: 'center', minCellWidth: 21 },
              },
              bodyStyles: { valign: 'middle', cellPadding: 5 },
              columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
              { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
              body: this.getAnswersQ3S13(aux_answers),
              head: [[{
                content: 'Las preguntas siguientes están relacionadas con la atención a clientes y usuarios.', colSpan: 6
              }]],
              didParseCell: (data) => {
                if (data.row.index === 0) {
                  data.cell.styles.fillColor = [40, 170, 100];
                  data.cell.styles.textColor = [255, 255, 255]
                  data.cell.styles.fontStyle = 'bold';
                }
              }
            });
  
            next_y3 += 120;
          }
  
          next_y3 += 20;
  
          doc.text('Soy jefe de otros trabajadores', 20, next_y3);
          doc.rect(80, next_y3 - 6, 10, 10);
          doc.text(`${aux_answers.sec2 ? 'Si' : 'No'}`, 82, next_y3);
  
          if (aux_answers.sec2) {
            doc.autoTable({
              margin: { top: 10 },
              startY: next_y3 + 13,
              theme: "grid",
              tableLineColor: 0,
              headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 30 },
              columnStyles: {
                r1: { halign: 'center', minCellWidth: 27 },
                r2: { halign: 'center', minCellWidth: 25 },
                r3: { halign: 'center', minCellWidth: 24 },
                r4: { halign: 'center', minCellWidth: 20 },
                r5: { halign: 'center', minCellWidth: 21 },
              },
              bodyStyles: { valign: 'middle', cellPadding: 5 },
              columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Siempre', dataKey: 'r1' }, { header: 'Casi siempre', dataKey: 'r2' },
              { header: 'Algunas veces', dataKey: 'r3' }, { header: 'Casi nunca', dataKey: 'r4' }, { header: 'Nunca', dataKey: 'r5' }],
              body: this.getAnswersQ3S14(aux_answers),
              head: [[{
                content: 'Las preguntas siguientes están relacionadas con las actitudes de las personas que supervisa.', colSpan: 6
              }]],
              didParseCell: (data) => {
                if (data.row.index === 0) {
                  data.cell.styles.fillColor = [40, 170, 100];
                  data.cell.styles.textColor = [255, 255, 255]
                  data.cell.styles.fontStyle = 'bold';
                }
              }
            });
          } else {
            doc.text('Si su respuesta fue No, ha concluido el cuestionario.', 20, next_y3 + 15);
          }
        }
  
        // Form DATA
        doc.addPage('a4', 'p');
        doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
        doc.setFontStyle("bold");
        doc.setFontSize(14);
        doc.text('Proporciona tus datos:', 20, 30);
        doc.setFontStyle("normal");
        doc.setFontSize(12);
        let y = 45;
        if (employee.name !== null) {
          doc.text('Nombre: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.name, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.application_email !== null) {
          doc.text('Email: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.application_email, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.cellphone !== null) {
          doc.text('Télefono: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(`${employee.cellphone_prefix} ${employee.cellphone}`, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.area !== null) {
          doc.text('Área: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.area, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.gender !== null) {
          doc.text('Sexo: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.gender, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.state) {
          doc.text('Estado: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.state, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.age_range !== null) {
          doc.text('Edad en años: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.age_range, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.age !== null) {
          doc.text('Edad: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.age.toString(), 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.education_level !== null) {
          doc.text('Nivel de Estudios: ', 40, y);
          doc.rect(80, y - 6, 105, 10);
          doc.text(employee.education_level, 82, y);
          y += 14;
          indexData += 1;
        }
        if (employee.civil_status !== null) {
          doc.text('Estado civil: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.civil_status, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.occupation !== null) {
          doc.text('Puesto: ', 40, y);
          doc.rect(70, y - 6, 115, 10);
          doc.text(employee.occupation, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.position_type !== null) {
          doc.text('Tipo de Puesto: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.position_type, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.recruitment_type !== null) {
          doc.text('Contratación: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.recruitment_type, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.employee_type !== null) {
          doc.text('Tipo de Empleado: ', 40, y);
          doc.rect(80, y - 6, 105, 10);
          doc.text(employee.employee_type, 82, y);
          y += 14;
          indexData += 1;
        }
  
        if (employee.working_hours_type !== null) {
          doc.text('Tipo de jornada de trabajo: ', 40, y);
          doc.rect(92, y - 6, 93, 10);
          doc.text(employee.working_hours_type, 95, y);
          y += 14;
          indexData += 1;
        }
  
        if (employee.working_hours_rotation !== null) {
          doc.text('Realiza rotación de turnos: ', 40, y);
          doc.rect(92, y - 6, 93, 10);
          doc.text(employee.working_hours_rotation, 95, y);
          y += 14;
          indexData += 1;
        }
  
        if (indexData >= 15) {
          indexData = 0;
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          y = 40;
        }
  
        if (employee.work_experience !== null) {
          doc.text('Tiempo experiencia laboral: ', 40, y);
          doc.rect(93, y - 6, 93, 10);
          doc.text(employee.work_experience, 96, y);
          y += 14;
          indexData += 1;
        }
  
        if (indexData >= 15) {
          indexData = 0;
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          y = 40;
        }
  
        if (employee.current_position_time !== null) {
          doc.text('Tiempo en puesto actual: ', 40, y);
          doc.rect(90, y - 6, 95, 10);
          doc.text(employee.current_position_time, 92, y);
          y += 14;
        }
  
        if (i < res.data.count) {
          doc.addPage('a4', 'p');
        }
      }

      doc.save(`Reporte ${name}-${new Date().getTime()}.pdf`);
      this.loadingPDF = false;

    }catch(error){
      this.notifierService.notify('error', 'Ocurrio un problema al descargar'); 
    }
    
  }

  async downloadReportQ1(id, name: string, type: number) {
    this.loadingPDF = true;
    const res = await this.questionnaireService.getFullResults(id, type);
    const emplooyees = type === 1 ? res.data.employees : res.data.responses;
    let doc = new jsPDF();
    let i = 0;
    try{
      for (let employee of emplooyees) {
        i += 1;
        let indexData = 0;
        // Main Page - Form Employee Data
        doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
        doc.setFontStyle("bold");
        doc.setFontSize(18);
        doc.text(`${name}`, 105, 30, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(10);
        doc.text('Cuestionario para identificar a los trabajadores que', 105, 40, null, null, 'center');
        doc.text('fueron sujetos a acontecimientos traumáticos severos', 105, 44, null, null, 'center');
        doc.setFontSize(12);
        doc.text('El objetivo es identificar a aquellas personas que han sido sujetas a acontecimientos', 20, 55);
        doc.text('traumático severos durante o con motivo de trabajo y canalizarlos para su atención', 20, 60);
        doc.text('clínica.', 20, 65);
        doc.setFontStyle("bold");
        doc.setFontSize(15);
  
        doc.text('Proporciona tus datos:', 20, 77);
        doc.setFontStyle("normal");
        doc.setFontSize(12);
        let y = 89;
        if (employee.name !== null) {
          doc.text('Nombre: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.name, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.application_email !== null) {
          doc.text('Email: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.application_email, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.cellphone !== null) {
          doc.text('Télefono: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(`${employee.cellphone_prefix} ${employee.cellphone}`, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.area !== null) {
          doc.text('Área: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.area, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.gender !== null) {
          doc.text('Sexo: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.gender, 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.state) {
          doc.text('Estado: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.state, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.age_range !== null) {
          doc.text('Edad en años: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.age_range, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.age !== null) {
          doc.text('Edad: ', 40, y);
          doc.rect(60, y - 6, 125, 10);
          doc.text(employee.age.toString(), 62, y);
          y += 14;
          indexData += 1;
        }
        if (employee.education_level !== null) {
          doc.text('Nivel de Estudios: ', 40, y);
          doc.rect(80, y - 6, 105, 10);
          doc.text(employee.education_level, 82, y);
          y += 14;
          indexData += 1;
        }
        if (employee.civil_status !== null) {
          doc.text('Estado civil: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.civil_status, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.occupation !== null) {
          doc.text('Puesto: ', 40, y);
          doc.rect(70, y - 6, 115, 10);
          doc.text(employee.occupation, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.position_type !== null) {
          doc.text('Tipo de Puesto: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.position_type, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.recruitment_type !== null) {
          doc.text('Contratación: ', 40, y);
          doc.rect(75, y - 6, 110, 10);
          doc.text(employee.recruitment_type, 77, y);
          y += 14;
          indexData += 1;
        }
        if (employee.employee_type !== null) {
          doc.text('Tipo de Empleado: ', 40, y);
          doc.rect(80, y - 6, 105, 10);
          doc.text(employee.employee_type, 82, y);
          y += 14;
          indexData += 1;
        }
        
        if (indexData >= 13) {
          indexData = 0;
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          y = 40;
        }
  
        if (employee.working_hours_type !== null) {
          doc.text('Tipo de jornada de trabajo: ', 40, y);
          doc.rect(92, y - 6, 93, 10);
          doc.text(employee.working_hours_type, 95, y);
          y += 14;
          indexData += 1;
        }
  
        if (indexData >= 13) {
          indexData = 0;
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          y = 40;
        }
  
        if (employee.working_hours_rotation !== null) {
          doc.text('Realiza rotación de turnos: ', 40, y);
          doc.rect(80, y - 6, 105, 10);
          doc.text(employee.working_hours_rotation, 92, y);
          y += 14;
          indexData += 1;
        }
  
        if (indexData >= 13) {
          indexData = 0;
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          y = 40;
        }
  
        if (employee.work_experience !== null) {
          doc.text('Tiempo experiencia laboral: ', 40, y);
          doc.rect(80, y - 6, 105, 10);
          doc.text(employee.work_experience, 92, y);
          y += 14;
          indexData += 1;
        }
  
        if (indexData >= 13) {
          indexData = 0;
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          y = 40;
        }
  
        if (employee.current_position_time !== null) {
          doc.text('Tiempo en puesto actual: ', 40, y);
          doc.rect(90, y - 6, 95, 10);
          doc.text(employee.current_position_time, 92, y);
          y += 14;
        }
        
        // Validate Form
        doc.addPage('a4', 'p');
        doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
        let next_y2 = 30;
        doc.setFontStyle("bold");
        doc.setFontSize(18);
        doc.text('Verifica tu identidad', 105, next_y2, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(12);
        next_y2 += 20;
        doc.text('Este cuestionario es personal y debe ser contestado por la persona a la que va dirigido.', 20, next_y2)
        doc.text('Por favor valida tus datos:', 20, next_y2 + 5);
        next_y2 += 30;
        doc.text('Nombre: ', 40, next_y2);
        doc.rect(60, next_y2 - 6, 125, 10);
        doc.text(employee.name, 62, next_y2);
        next_y2 += 14;
        doc.text('Email: ', 40, next_y2);
        doc.rect(60, next_y2 - 6, 125, 10);
        doc.text(employee.application_email, 62, next_y2);
        next_y2 += 25;
        doc.setFontStyle("bold");
        doc.setFontSize(13);
        doc.text(`Confirmo que soy ${employee.name}.`, 105, next_y2, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(14);
        doc.rect(100, next_y2 + 4, 10, 10);
        doc.setTextColor(255, 0, 0);
        doc.text('X', 105, next_y2 + 11, null, null, 'center');
        doc.setTextColor(0, 0, 0);
  
        // Instrucction Page - First Question
        doc.addPage('a4','p');
        doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
        let next_y = 30;
        doc.setFontStyle("bold");
        doc.setFontSize(18);
        doc.text('Ten en Cuenta', 105, next_y, null, null, 'center');
        doc.setFontStyle("normal");
        doc.setFontSize(12);
        next_y += 12;
        doc.text('Responde el siguiente cuestionario teniendo en cuenta las características de un', 20, next_y);
        doc.text('acontecimiento traumático severo:', 20, next_y + 5);
        doc.text('- El acontecimiento debió ser experimentado durante o con motivo del trabajo', 35, next_y + 15);
        doc.text('  donde laboras actualmente.', 35, next_y + 20);
        doc.text('- El acontecimiento puede generar transtorno de estrés postraumático para', 35, next_y + 27);
        doc.text('  quien lo sufre o lo presencia.', 35, next_y + 32);
        doc.text('- Algunos ejemplos de acontecimiento traumático severo son: explosiones, ', 35, next_y + 39);
        doc.text('  derrumbes, incendios de gran magnitud, accidentes graves o mortales, ', 35, next_y + 44);
        doc.text('  asaltos con violencia, secuestros y homicidios, entre otros.', 35, next_y + 49);
  
        let aux_answers = type === 1 ? JSON.parse(employee.answers) : JSON.parse(employee.ats_answers);

        let q1 = [
          {
            question: 'Pregunta',
            yes: 'Si',
            no: 'No'
          },
          {
            question: `¿Ha presenciado o sufrido alguna vez, durante o con motivo del trabajo un acontecimiento como los siguientes?
        - Accidente que tenga como consecuencia la muerte, 
          la pérdida de un miembro o una lesión grave?
        - Asaltos?
        - Actos violentos que derivaron en lesiones graves?
        - Secuestro?
        - Amenazas?, o
        - Cualquier otro que ponga en riesgo su vida o salud, 
          y/o la de otras personas?`,
            yes: aux_answers.s1 === 1 ? 'X' : '',
            no: aux_answers.s1 === 0 ? 'X' : ''
          }
        ];
  
        let isV2 = aux_answers.s1_2 !== undefined;
  
        doc.autoTable({
          margin: { top: 10 },
          startY: next_y + 62,
          theme: "grid",
          tableLineColor: 0,
          headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 20 },
          columnStyles: {
            yes: { halign: 'center', minCellWidth: 20 },
            no: { halign: 'center', minCellWidth: 20 },
          },
          bodyStyles: { valign: 'middle', cellPadding: 5 },
          columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Si', dataKey: 'yes' }, { header: 'No', dataKey: 'no' }],
          body: isV2 ? this.getAnswersS1(aux_answers) : q1,
          head: [[{ content: 'I.- Acontecimiento traumático severo', colSpan: 3 }]],
          didParseCell: (data) => {
            if (data.row.index === 0) {
              data.cell.styles.fillColor = [40, 170, 100];
              data.cell.styles.textColor = [255, 255, 255]
              data.cell.styles.fontStyle = 'bold';
            }
            if (data.row.index === 1 && data.column.index === 0 && isV2) {
              data.cell.colSpan = 3;
            }
          }
        });
  
        doc.setFontStyle("bold");
        doc.text('*Nota: Si tu respuesta fue no, el cuestionario ha concluido, de lo contrario continúa', 20, next_y + 215);
        doc.text('respondiendo las preguntas.', 20, next_y + 220);
  
        if ( aux_answers.s1 === 1 || this.getResultS1(aux_answers) ) {
          // Questions 
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para identificar a los trabajadores que', 105, 35, null, null, 'center');
          doc.text('fueron sujetos a acontecimientos traumáticos severos', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
          doc.autoTable({
            margin: { top: 10 },
            startY: 55,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 20 },
            columnStyles: {
              yes: { halign: 'center', minCellWidth: 20 },
              no: { halign: 'center', minCellWidth: 20 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Si', dataKey: 'yes' }, { header: 'No', dataKey: 'no' }],
            body: this.getAnswersS2(aux_answers),
            head: [[{ content: 'II.- Recuerdos persistentes sobre el acontecimiento (durante el último mes):', colSpan: 3 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
          doc.autoTable({
            margin: { top: 10 },
            startY: 125,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 20 },
            columnStyles: {
              yes: { halign: 'center', minCellWidth: 20 },
              no: { halign: 'center', minCellWidth: 20 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Si', dataKey: 'yes' }, { header: 'No', dataKey: 'no' }],
            body: this.getAnswersS3(aux_answers),
            head: [[{ content: 'III.- Esfuerzo por evitar circunstancias parecidas o asociadas al acontecimiento (durante el último mes):', colSpan: 3 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
  
          doc.addPage('a4', 'p');
          doc.addImage(logo1, 'PNG', 20, 10, 30, 10);
          doc.setFontStyle("bold");
          doc.setFontSize(16);
          doc.text('Cuestionario para identificar a los trabajadores que', 105, 35, null, null, 'center');
          doc.text('fueron sujetos a acontecimientos traumáticos severos', 105, 42, null, null, 'center');
          doc.setFontStyle("normal");
          doc.setFontSize(12);
          doc.autoTable({
            margin: { top: 10 },
            startY: 55,
            theme: "grid",
            tableLineColor: 0,
            headStyles: { fillColor: 0, valign: 'middle', minCellHeight: 12, minCellWidth: 20 },
            columnStyles: {
              yes: { halign: 'center', minCellWidth: 20 },
              no: { halign: 'center', minCellWidth: 20 },
            },
            bodyStyles: { valign: 'middle', cellPadding: 5 },
            columns: [{ header: 'Pregunta', dataKey: 'question' }, { header: 'Si', dataKey: 'yes' }, { header: 'No', dataKey: 'no' }],
            body: this.getAnswersS4(aux_answers),
            head: [[{ content: 'IV Afectación (durante el último mes):', colSpan: 3 }]],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.fillColor = [40, 170, 100];
                data.cell.styles.textColor = [255, 255, 255]
                data.cell.styles.fontStyle = 'bold';
              }
            }
          });
        }
        
        if (i < res.data.count) {
          doc.addPage('a4', 'p');
        }
      }
  
      doc.save(`Reporte ${name}.pdf`);
      this.loadingPDF = false;
    }catch(error){
      this.notifierService.notify('error', 'Ocurrio un problema al descargar'); 
    }
  }

  getResultS1(answers) {
    return answers.s1_1 === 1 || answers.s1_2 === 1 ||
      answers.s1_3 === 1 || answers.s1_4 === 1 ||
      answers.s1_5 === 1 || answers.s1_6 === 1;
  }

  getAnswersQ2S1(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `1. Mi trabajo me exige hacer mucho esfuerzo físico:`,
        r1: answers.q1 === 4 ? 'X' : '',
        r2: answers.q1 === 3 ? 'X' : '',
        r3: answers.q1 === 2 ? 'X' : '',
        r4: answers.q1 === 1 ? 'X' : '',
        r5: answers.q1 === 0 ? 'X' : '',
      },
      {
        question: `2. Me preocupa sufrir un accidente en mi trabajo:`,
        r1: answers.q2 === 4 ? 'X' : '',
        r2: answers.q2 === 3 ? 'X' : '',
        r3: answers.q2 === 2 ? 'X' : '',
        r4: answers.q2 === 1 ? 'X' : '',
        r5: answers.q2 === 0 ? 'X' : '',
      },
      {
        question: `3. Considero que las actividades que realizo son peligrosas:`,
        r1: answers.q3 === 4 ? 'X' : '',
        r2: answers.q3 === 3 ? 'X' : '',
        r3: answers.q3 === 2 ? 'X' : '',
        r4: answers.q3 === 1 ? 'X' : '',
        r5: answers.q3 === 0 ? 'X' : '',
      },
      {
        question: `4. Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno:`,
        r1: answers.q4 === 4 ? 'X' : '',
        r2: answers.q4 === 3 ? 'X' : '',
        r3: answers.q4 === 2 ? 'X' : '',
        r4: answers.q4 === 1 ? 'X' : '',
        r5: answers.q4 === 0 ? 'X' : '',
      },
      {
        question: `5. Por la cantidad de trabajo que tengo debo trabajar sin parar:`,
        r1: answers.q5 === 4 ? 'X' : '',
        r2: answers.q5 === 3 ? 'X' : '',
        r3: answers.q5 === 2 ? 'X' : '',
        r4: answers.q5 === 1 ? 'X' : '',
        r5: answers.q5 === 0 ? 'X' : '',
      },
      {
        question: `6. Considero que es necesario mantener un ritmo de trabajo acelerado:`,
        r1: answers.q6 === 4 ? 'X' : '',
        r2: answers.q6 === 3 ? 'X' : '',
        r3: answers.q6 === 2 ? 'X' : '',
        r4: answers.q6 === 1 ? 'X' : '',
        r5: answers.q6 === 0 ? 'X' : '',
      },
      {
        question: `7. Mi trabajo exige que esté muy concentrado:`,
        r1: answers.q7 === 4 ? 'X' : '',
        r2: answers.q7 === 3 ? 'X' : '',
        r3: answers.q7 === 2 ? 'X' : '',
        r4: answers.q7 === 1 ? 'X' : '',
        r5: answers.q7 === 0 ? 'X' : '',
      },
      {
        question: `8. Mi trabajo requiere que memorice mucha información:`,
        r1: answers.q8 === 4 ? 'X' : '',
        r2: answers.q8 === 3 ? 'X' : '',
        r3: answers.q8 === 2 ? 'X' : '',
        r4: answers.q8 === 1 ? 'X' : '',
        r5: answers.q8 === 0 ? 'X' : '',
      },
      {
        question: `9. Mi trabajo exige que atienda varios asuntos al mismo tiempo:`,
        r1: answers.q9 === 4 ? 'X' : '',
        r2: answers.q9 === 3 ? 'X' : '',
        r3: answers.q9 === 2 ? 'X' : '',
        r4: answers.q9 === 1 ? 'X' : '',
        r5: answers.q9 === 0 ? 'X' : '',
      }
    ];
    

    return res;
  }

  getAnswersQ2S2(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `10. En mi trabajo soy responsable de cosas de mucho valor:`,
        r1: answers.q10 === 4 ? 'X' : '',
        r2: answers.q10 === 3 ? 'X' : '',
        r3: answers.q10 === 2 ? 'X' : '',
        r4: answers.q10 === 1 ? 'X' : '',
        r5: answers.q10 === 0 ? 'X' : '',
      },
      {
        question: `11. Respondo ante mi jefe por los resultados de toda mi área de trabajo:`,
        r1: answers.q11 === 4 ? 'X' : '',
        r2: answers.q11 === 3 ? 'X' : '',
        r3: answers.q11 === 2 ? 'X' : '',
        r4: answers.q11 === 1 ? 'X' : '',
        r5: answers.q11 === 0 ? 'X' : '',
      },
      {
        question: `12. En mi trabajo me dan órdenes contradictorias:`,
        r1: answers.q12 === 4 ? 'X' : '',
        r2: answers.q12 === 3 ? 'X' : '',
        r3: answers.q12 === 2 ? 'X' : '',
        r4: answers.q12 === 1 ? 'X' : '',
        r5: answers.q12 === 0 ? 'X' : '',
      },
      {
        question: `13. Considero que en mi trabajo me piden hacer cosas innecesarias:`,
        r1: answers.q13 === 4 ? 'X' : '',
        r2: answers.q13 === 3 ? 'X' : '',
        r3: answers.q13 === 2 ? 'X' : '',
        r4: answers.q13 === 1 ? 'X' : '',
        r5: answers.q13 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ2S3(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `14. Trabajo horas extras más de tres veces a la semana:`,
        r1: answers.q14 === 4 ? 'X' : '',
        r2: answers.q14 === 3 ? 'X' : '',
        r3: answers.q14 === 2 ? 'X' : '',
        r4: answers.q14 === 1 ? 'X' : '',
        r5: answers.q14 === 0 ? 'X' : '',
      },
      {
        question: `15. Mi trabajo me exige laborar en días de descanso, festivos o fines de semana:`,
        r1: answers.q15 === 4 ? 'X' : '',
        r2: answers.q15 === 3 ? 'X' : '',
        r3: answers.q15 === 2 ? 'X' : '',
        r4: answers.q15 === 1 ? 'X' : '',
        r5: answers.q15 === 0 ? 'X' : '',
      },
      {
        question: `16. Considero que el tiempo en el trabajo es mucho y perjudica mis actividades familiares o personales:`,
        r1: answers.q16 === 4 ? 'X' : '',
        r2: answers.q16 === 3 ? 'X' : '',
        r3: answers.q16 === 2 ? 'X' : '',
        r4: answers.q16 === 1 ? 'X' : '',
        r5: answers.q16 === 0 ? 'X' : '',
      },
      {
        question: `17. Pienso en las actividades familiares o personales cuando estoy en mi trabajo:`,
        r1: answers.q17 === 4 ? 'X' : '',
        r2: answers.q17 === 3 ? 'X' : '',
        r3: answers.q17 === 2 ? 'X' : '',
        r4: answers.q17 === 1 ? 'X' : '',
        r5: answers.q17 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ2S4(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `18. Mi trabajo permite que desarrolle nuevas habilidades:`,
        r1: answers.q18 === 0 ? 'X' : '',
        r2: answers.q18 === 1 ? 'X' : '',
        r3: answers.q18 === 2 ? 'X' : '',
        r4: answers.q18 === 3 ? 'X' : '',
        r5: answers.q18 === 4 ? 'X' : '',
      },
      {
        question: `19. En mi trabajo puedo aspirar a un mejor puesto:`,
        r1: answers.q19 === 0 ? 'X' : '',
        r2: answers.q19 === 1 ? 'X' : '',
        r3: answers.q19 === 2 ? 'X' : '',
        r4: answers.q19 === 3 ? 'X' : '',
        r5: answers.q19 === 4 ? 'X' : '',
      },
      {
        question: `20. Durante mi jornada de trabajo puedo tomar pausas cuando las necesito:`,
        r1: answers.q20 === 0 ? 'X' : '',
        r2: answers.q20 === 1 ? 'X' : '',
        r3: answers.q20 === 2 ? 'X' : '',
        r4: answers.q20 === 3 ? 'X' : '',
        r5: answers.q20 === 4 ? 'X' : '',
      },
      {
        question: `21. Puedo decidir la velocidad a la que realizo mis actividades en mi trabajo:`,
        r1: answers.q21 === 0 ? 'X' : '',
        r2: answers.q21 === 1 ? 'X' : '',
        r3: answers.q21 === 2 ? 'X' : '',
        r4: answers.q21 === 3 ? 'X' : '',
        r5: answers.q21 === 4 ? 'X' : '',
      },
      {
        question: `22. Puedo cambiar el orden de las actividades que realizo en mi trabajo:`,
        r1: answers.q22 === 0 ? 'X' : '',
        r2: answers.q22 === 1 ? 'X' : '',
        r3: answers.q22 === 2 ? 'X' : '',
        r4: answers.q22 === 3 ? 'X' : '',
        r5: answers.q22 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ2S5(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `23. Me informan con claridad cuáles son mis funciones:`,
        r1: answers.q23 === 0 ? 'X' : '',
        r2: answers.q23 === 1 ? 'X' : '',
        r3: answers.q23 === 2 ? 'X' : '',
        r4: answers.q23 === 3 ? 'X' : '',
        r5: answers.q23 === 4 ? 'X' : '',
      },
      {
        question: `24. Me explican claramente los resultados que debo obtener en mi trabajo:`,
        r1: answers.q24 === 0 ? 'X' : '',
        r2: answers.q24 === 1 ? 'X' : '',
        r3: answers.q24 === 2 ? 'X' : '',
        r4: answers.q24 === 3 ? 'X' : '',
        r5: answers.q24 === 4 ? 'X' : '',
      },
      {
        question: `25. Me informan con quién puedo resolver problemas o asuntos de trabajo:`,
        r1: answers.q25 === 0 ? 'X' : '',
        r2: answers.q25 === 1 ? 'X' : '',
        r3: answers.q25 === 2 ? 'X' : '',
        r4: answers.q25 === 3 ? 'X' : '',
        r5: answers.q25 === 4 ? 'X' : '',
      },
      {
        question: `26. Me permiten asistir a capacitaciones relacionadas con mi trabajo:`,
        r1: answers.q26 === 0 ? 'X' : '',
        r2: answers.q26 === 1 ? 'X' : '',
        r3: answers.q26 === 2 ? 'X' : '',
        r4: answers.q26 === 3 ? 'X' : '',
        r5: answers.q26 === 4 ? 'X' : '',
      },
      {
        question: `27. Recibo capacitación útil para hacer mi trabajo:`,
        r1: answers.q27 === 0 ? 'X' : '',
        r2: answers.q27 === 1 ? 'X' : '',
        r3: answers.q27 === 2 ? 'X' : '',
        r4: answers.q27 === 3 ? 'X' : '',
        r5: answers.q27 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ2S6(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `28. Mi jefe tiene en cuenta mis puntos de vista y opiniones:`,
        r1: answers.q28 === 0 ? 'X' : '',
        r2: answers.q28 === 1 ? 'X' : '',
        r3: answers.q28 === 2 ? 'X' : '',
        r4: answers.q28 === 3 ? 'X' : '',
        r5: answers.q28 === 4 ? 'X' : '',
      },
      {
        question: `29. Mi jefe ayuda a solucionar los problemas que se presentan en el trabajo:`,
        r1: answers.q29 === 0 ? 'X' : '',
        r2: answers.q29 === 1 ? 'X' : '',
        r3: answers.q29 === 2 ? 'X' : '',
        r4: answers.q29 === 3 ? 'X' : '',
        r5: answers.q29 === 4 ? 'X' : '',
      },
      {
        question: `30. Puedo confiar en mis compañeros de trabajo:`,
        r1: answers.q30 === 0 ? 'X' : '',
        r2: answers.q30 === 1 ? 'X' : '',
        r3: answers.q30 === 2 ? 'X' : '',
        r4: answers.q30 === 3 ? 'X' : '',
        r5: answers.q30 === 4 ? 'X' : '',
      },
      {
        question: `31. Cuando tenemos que realizar trabajo de equipo los compañeros colaboran:`,
        r1: answers.q31 === 0 ? 'X' : '',
        r2: answers.q31 === 1 ? 'X' : '',
        r3: answers.q31 === 2 ? 'X' : '',
        r4: answers.q31 === 3 ? 'X' : '',
        r5: answers.q31 === 4 ? 'X' : '',
      },
      {
        question: `32. Mis compañeros de trabajo me ayudan cuando tengo dificultades:`,
        r1: answers.q32 === 0 ? 'X' : '',
        r2: answers.q32 === 1 ? 'X' : '',
        r3: answers.q32 === 2 ? 'X' : '',
        r4: answers.q32 === 3 ? 'X' : '',
        r5: answers.q32 === 4 ? 'X' : '',
      },
      {
        question: `33. En mi trabajo puedo expresarme libremente sin interrupciones:`,
        r1: answers.q33 === 0 ? 'X' : '',
        r2: answers.q33 === 1 ? 'X' : '',
        r3: answers.q33 === 2 ? 'X' : '',
        r4: answers.q33 === 3 ? 'X' : '',
        r5: answers.q33 === 4 ? 'X' : '',
      },
      {
        question: `34. Recibo críticas constantes a mi persona y/o trabajo:`,
        r1: answers.q34 === 4 ? 'X' : '',
        r2: answers.q34 === 3 ? 'X' : '',
        r3: answers.q34 === 2 ? 'X' : '',
        r4: answers.q34 === 1 ? 'X' : '',
        r5: answers.q34 === 0 ? 'X' : '',
      },
      {
        question: `35. Recibo burlas, calumnias, difamaciones, humillaciones o ridiculizaciones:`,
        r1: answers.q35 === 4 ? 'X' : '',
        r2: answers.q35 === 3 ? 'X' : '',
        r3: answers.q35 === 2 ? 'X' : '',
        r4: answers.q35 === 1 ? 'X' : '',
        r5: answers.q35 === 0 ? 'X' : '',
      },
      {
        question: `36. Se ignora mi presencia o se me excluye de las reuniones de trabajo y en la toma de decisiones:`,
        r1: answers.q36 === 4 ? 'X' : '',
        r2: answers.q36 === 3 ? 'X' : '',
        r3: answers.q36 === 2 ? 'X' : '',
        r4: answers.q36 === 1 ? 'X' : '',
        r5: answers.q36 === 0 ? 'X' : '',
      },
      {
        question: `37. Se manipulan las situaciones de trabajo para hacerme parecer un mal trabajador:`,
        r1: answers.q37 === 4 ? 'X' : '',
        r2: answers.q37 === 3 ? 'X' : '',
        r3: answers.q37 === 2 ? 'X' : '',
        r4: answers.q37 === 1 ? 'X' : '',
        r5: answers.q37 === 0 ? 'X' : '',
      },
      {
        question: `38. Se ignoran mis éxitos laborales y se atribuyen a otros trabajadores:`,
        r1: answers.q38 === 4 ? 'X' : '',
        r2: answers.q38 === 3 ? 'X' : '',
        r3: answers.q38 === 2 ? 'X' : '',
        r4: answers.q38 === 1 ? 'X' : '',
        r5: answers.q38 === 0 ? 'X' : '',
      },
      {
        question: `39. Me bloquean o impiden las oportunidades que tengo para obtener ascenso o mejora en mi trabajo:`,
        r1: answers.q39 === 4 ? 'X' : '',
        r2: answers.q39 === 3 ? 'X' : '',
        r3: answers.q39 === 2 ? 'X' : '',
        r4: answers.q39 === 1 ? 'X' : '',
        r5: answers.q39 === 0 ? 'X' : '',
      },
      {
        question: `40. He presenciado actos de violencia en mi centro de trabajo:`,
        r1: answers.q40 === 4 ? 'X' : '',
        r2: answers.q40 === 3 ? 'X' : '',
        r3: answers.q40 === 2 ? 'X' : '',
        r4: answers.q40 === 1 ? 'X' : '',
        r5: answers.q40 === 0 ? 'X' : '',
      }
    ];


    return res;
  }

  getAnswersQ2S7(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `41. Atiendo clientes o usuarios muy enojados:`,
        r1: answers.q41 === 4 ? 'X' : '',
        r2: answers.q41 === 3 ? 'X' : '',
        r3: answers.q41 === 2 ? 'X' : '',
        r4: answers.q41 === 1 ? 'X' : '',
        r5: answers.q41 === 0 ? 'X' : '',
      },
      {
        question: `42. Mi trabajo me exige atender personas muy necesitadas de ayuda o enfermas:`,
        r1: answers.q42 === 4 ? 'X' : '',
        r2: answers.q42 === 3 ? 'X' : '',
        r3: answers.q42 === 2 ? 'X' : '',
        r4: answers.q42 === 1 ? 'X' : '',
        r5: answers.q42 === 0 ? 'X' : '',
      },
      {
        question: `43. Para hacer mi trabajo debo demostrar sentimientos distintos a los míos:`,
        r1: answers.q43 === 4 ? 'X' : '',
        r2: answers.q43 === 3 ? 'X' : '',
        r3: answers.q43 === 2 ? 'X' : '',
        r4: answers.q43 === 1 ? 'X' : '',
        r5: answers.q43 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ2S8(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `44. Comunican tarde los asuntos de trabajo:`,
        r1: answers.q44 === 4 ? 'X' : '',
        r2: answers.q44 === 3 ? 'X' : '',
        r3: answers.q44 === 2 ? 'X' : '',
        r4: answers.q44 === 1 ? 'X' : '',
        r5: answers.q44 === 0 ? 'X' : '',
      },
      {
        question: `45. Dificultan el logro de los resultados del trabajo:`,
        r1: answers.q45 === 4 ? 'X' : '',
        r2: answers.q45 === 3 ? 'X' : '',
        r3: answers.q45 === 2 ? 'X' : '',
        r4: answers.q45 === 1 ? 'X' : '',
        r5: answers.q45 === 0 ? 'X' : '',
      },
      {
        question: `46. Ignoran las sugerencias para mejorar su trabajo:`,
        r1: answers.q46 === 4 ? 'X' : '',
        r2: answers.q46 === 3 ? 'X' : '',
        r3: answers.q46 === 2 ? 'X' : '',
        r4: answers.q46 === 1 ? 'X' : '',
        r5: answers.q46 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S1(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `1. El espacio donde trabajo me permite realizar mis actividades de manera segura e higiénica:`,
        r1: answers.q1 === 0 ? 'X' : '',
        r2: answers.q1 === 1 ? 'X' : '',
        r3: answers.q1 === 2 ? 'X' : '',
        r4: answers.q1 === 3 ? 'X' : '',
        r5: answers.q1 === 4 ? 'X' : '',
      },
      {
        question: `2. Mi trabajo me exige hacer mucho esfuerzo físico:`,
        r1: answers.q2 === 4 ? 'X' : '',
        r2: answers.q2 === 3 ? 'X' : '',
        r3: answers.q2 === 2 ? 'X' : '',
        r4: answers.q2 === 1 ? 'X' : '',
        r5: answers.q2 === 0 ? 'X' : '',
      },
      {
        question: `3. Me preocupa sufrir un accidente en mi trabajo:`,
        r1: answers.q3 === 4 ? 'X' : '',
        r2: answers.q3 === 3 ? 'X' : '',
        r3: answers.q3 === 2 ? 'X' : '',
        r4: answers.q3 === 1 ? 'X' : '',
        r5: answers.q3 === 0 ? 'X' : '',
      },
      {
        question: `4. Considero que en mi trabajo se aplican las normas de seguridad y salud en el trabajo:`,
        r1: answers.q4 === 0 ? 'X' : '',
        r2: answers.q4 === 1 ? 'X' : '',
        r3: answers.q4 === 2 ? 'X' : '',
        r4: answers.q4 === 3 ? 'X' : '',
        r5: answers.q4 === 4 ? 'X' : '',
      },
      {
        question: `5. Considero que las actividades que realizo son peligrosas:`,
        r1: answers.q5 === 4 ? 'X' : '',
        r2: answers.q5 === 3 ? 'X' : '',
        r3: answers.q5 === 2 ? 'X' : '',
        r4: answers.q5 === 1 ? 'X' : '',
        r5: answers.q5 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S2(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `6. Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno:`,
        r1: answers.q6 === 4 ? 'X' : '',
        r2: answers.q6 === 3 ? 'X' : '',
        r3: answers.q6 === 2 ? 'X' : '',
        r4: answers.q6 === 1 ? 'X' : '',
        r5: answers.q6 === 0 ? 'X' : '',
      },
      {
        question: `7. Por la cantidad de trabajo que tengo debo trabajar sin parar:`,
        r1: answers.q7 === 4 ? 'X' : '',
        r2: answers.q7 === 3 ? 'X' : '',
        r3: answers.q7 === 2 ? 'X' : '',
        r4: answers.q7 === 1 ? 'X' : '',
        r5: answers.q7 === 0 ? 'X' : '',
      },
      {
        question: `8. Considero que es necesario mantener un ritmo de trabajo acelerado:`,
        r1: answers.q8 === 4 ? 'X' : '',
        r2: answers.q8 === 3 ? 'X' : '',
        r3: answers.q8 === 2 ? 'X' : '',
        r4: answers.q8 === 1 ? 'X' : '',
        r5: answers.q8 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S3(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `9. Mi trabajo exige que esté muy concentrado:`,
        r1: answers.q9 === 4 ? 'X' : '',
        r2: answers.q9 === 3 ? 'X' : '',
        r3: answers.q9 === 2 ? 'X' : '',
        r4: answers.q9 === 1 ? 'X' : '',
        r5: answers.q9 === 0 ? 'X' : '',
      },
      {
        question: `10. Mi trabajo requiere que memorice mucha información:`,
        r1: answers.q10 === 4 ? 'X' : '',
        r2: answers.q10 === 3 ? 'X' : '',
        r3: answers.q10 === 2 ? 'X' : '',
        r4: answers.q10 === 1 ? 'X' : '',
        r5: answers.q10 === 0 ? 'X' : '',
      },
      {
        question: `11. En mi trabajo tengo que tomar decisiones difíciles muy rápido:`,
        r1: answers.q11 === 4 ? 'X' : '',
        r2: answers.q11 === 3 ? 'X' : '',
        r3: answers.q11 === 2 ? 'X' : '',
        r4: answers.q11 === 1 ? 'X' : '',
        r5: answers.q11 === 0 ? 'X' : '',
      },
      {
        question: `12. Mi trabajo exige que atienda varios asuntos al mismo tiempo:`,
        r1: answers.q12 === 4 ? 'X' : '',
        r2: answers.q12 === 3 ? 'X' : '',
        r3: answers.q12 === 2 ? 'X' : '',
        r4: answers.q12 === 1 ? 'X' : '',
        r5: answers.q12 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S4(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `13. En mi trabajo soy responsable de cosas de mucho valor:`,
        r1: answers.q13 === 4 ? 'X' : '',
        r2: answers.q13 === 3 ? 'X' : '',
        r3: answers.q13 === 2 ? 'X' : '',
        r4: answers.q13 === 1 ? 'X' : '',
        r5: answers.q13 === 0 ? 'X' : '',
      },
      {
        question: `14. Respondo ante mi jefe por los resultados de toda mi área de trabajo:`,
        r1: answers.q14 === 4 ? 'X' : '',
        r2: answers.q14 === 3 ? 'X' : '',
        r3: answers.q14 === 2 ? 'X' : '',
        r4: answers.q14 === 1 ? 'X' : '',
        r5: answers.q14 === 0 ? 'X' : '',
      },
      {
        question: `15. En el trabajo me dan órdenes contradictorias:`,
        r1: answers.q15 === 4 ? 'X' : '',
        r2: answers.q15 === 3 ? 'X' : '',
        r3: answers.q15 === 2 ? 'X' : '',
        r4: answers.q15 === 1 ? 'X' : '',
        r5: answers.q15 === 0 ? 'X' : '',
      },
      {
        question: `16. Considero que en mi trabajo me piden hacer cosas innecesarias:`,
        r1: answers.q16 === 4 ? 'X' : '',
        r2: answers.q16 === 3 ? 'X' : '',
        r3: answers.q16 === 2 ? 'X' : '',
        r4: answers.q16 === 1 ? 'X' : '',
        r5: answers.q16 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S5(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `17. Trabajo horas extras más de tres veces a la semana:`,
        r1: answers.q17 === 4 ? 'X' : '',
        r2: answers.q17 === 3 ? 'X' : '',
        r3: answers.q17 === 2 ? 'X' : '',
        r4: answers.q17 === 1 ? 'X' : '',
        r5: answers.q17 === 0 ? 'X' : '',
      },
      {
        question: `18. Mi trabajo me exige laborar en días de descanso, festivos o fines de semana:`,
        r1: answers.q18 === 4 ? 'X' : '',
        r2: answers.q18 === 3 ? 'X' : '',
        r3: answers.q18 === 2 ? 'X' : '',
        r4: answers.q18 === 1 ? 'X' : '',
        r5: answers.q18 === 0 ? 'X' : '',
      },
      {
        question: `19. Considero que el tiempo en el trabajo es mucho y perjudica mis actividades familiares o personales:`,
        r1: answers.q19 === 4 ? 'X' : '',
        r2: answers.q19 === 3 ? 'X' : '',
        r3: answers.q19 === 2 ? 'X' : '',
        r4: answers.q19 === 1 ? 'X' : '',
        r5: answers.q19 === 0 ? 'X' : '',
      },
      {
        question: `20. Debo atender asuntos de trabajo cuando estoy en casa:`,
        r1: answers.q20 === 4 ? 'X' : '',
        r2: answers.q20 === 3 ? 'X' : '',
        r3: answers.q20 === 2 ? 'X' : '',
        r4: answers.q20 === 1 ? 'X' : '',
        r5: answers.q20 === 0 ? 'X' : '',
      },
      {
        question: `21. Pienso en las actividades familiares o personales cuando estoy en mi trabajo:`,
        r1: answers.q21 === 4 ? 'X' : '',
        r2: answers.q21 === 3 ? 'X' : '',
        r3: answers.q21 === 2 ? 'X' : '',
        r4: answers.q21 === 1 ? 'X' : '',
        r5: answers.q21 === 0 ? 'X' : '',
      },
      {
        question: `22. Pienso que mis responsabilidades familiares afectan mi trabajo:`,
        r1: answers.q22 === 4 ? 'X' : '',
        r2: answers.q22 === 3 ? 'X' : '',
        r3: answers.q22 === 2 ? 'X' : '',
        r4: answers.q22 === 1 ? 'X' : '',
        r5: answers.q22 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S6(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `23. Mi trabajo permite que desarrolle nuevas habilidades:`,
        r1: answers.q23 === 0 ? 'X' : '',
        r2: answers.q23 === 1 ? 'X' : '',
        r3: answers.q23 === 2 ? 'X' : '',
        r4: answers.q23 === 3 ? 'X' : '',
        r5: answers.q23 === 4 ? 'X' : '',
      },
      {
        question: `24. En mi trabajo puedo aspirar a un mejor puesto:`,
        r1: answers.q24 === 0 ? 'X' : '',
        r2: answers.q24 === 1 ? 'X' : '',
        r3: answers.q24 === 2 ? 'X' : '',
        r4: answers.q24 === 3 ? 'X' : '',
        r5: answers.q24 === 4 ? 'X' : '',
      },
      {
        question: `25. Durante mi jornada de trabajo puedo tomar pausas cuando las necesito:`,
        r1: answers.q25 === 0 ? 'X' : '',
        r2: answers.q25 === 1 ? 'X' : '',
        r3: answers.q25 === 2 ? 'X' : '',
        r4: answers.q25 === 3 ? 'X' : '',
        r5: answers.q25 === 4 ? 'X' : '',
      },
      {
        question: `26. Puedo decidir cuánto trabajo realizo durante la jornada laboral:`,
        r1: answers.q26 === 0 ? 'X' : '',
        r2: answers.q26 === 1 ? 'X' : '',
        r3: answers.q26 === 2 ? 'X' : '',
        r4: answers.q26 === 3 ? 'X' : '',
        r5: answers.q26 === 4 ? 'X' : '',
      },
      {
        question: `27. Puedo decidir la velocidad a la que realizo mis actividades en mi trabajo:`,
        r1: answers.q27 === 0 ? 'X' : '',
        r2: answers.q27 === 1 ? 'X' : '',
        r3: answers.q27 === 2 ? 'X' : '',
        r4: answers.q27 === 3 ? 'X' : '',
        r5: answers.q27 === 4 ? 'X' : '',
      },
      {
        question: `28. Puedo cambiar el orden de las actividades que realizo en mi trabajo:`,
        r1: answers.q28 === 0 ? 'X' : '',
        r2: answers.q28 === 1 ? 'X' : '',
        r3: answers.q28 === 2 ? 'X' : '',
        r4: answers.q28 === 3 ? 'X' : '',
        r5: answers.q28 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S7(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `29. Los cambios que se presentan en mi trabajo dificultan mi labor:`,
        r1: answers.q29 === 4 ? 'X' : '',
        r2: answers.q29 === 3 ? 'X' : '',
        r3: answers.q29 === 2 ? 'X' : '',
        r4: answers.q29 === 1 ? 'X' : '',
        r5: answers.q29 === 0 ? 'X' : '',
      },
      {
        question: `30. Cuando se presentan cambios en mi trabajo se tienen en cuenta mis ideas o aportaciones:`,
        r1: answers.q30 === 0 ? 'X' : '',
        r2: answers.q30 === 1 ? 'X' : '',
        r3: answers.q30 === 2 ? 'X' : '',
        r4: answers.q30 === 3 ? 'X' : '',
        r5: answers.q30 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S8(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `31. Me informan con claridad cuáles son mis funciones:`,
        r1: answers.q31 === 0 ? 'X' : '',
        r2: answers.q31 === 1 ? 'X' : '',
        r3: answers.q31 === 2 ? 'X' : '',
        r4: answers.q31 === 3 ? 'X' : '',
        r5: answers.q31 === 4 ? 'X' : '',
      },
      {
        question: `32. Me explican claramente los resultados que debo obtener en mi trabajo:`,
        r1: answers.q32 === 0 ? 'X' : '',
        r2: answers.q32 === 1 ? 'X' : '',
        r3: answers.q32 === 2 ? 'X' : '',
        r4: answers.q32 === 3 ? 'X' : '',
        r5: answers.q32 === 4 ? 'X' : '',
      },
      {
        question: `33. Me explican claramente los objetivos de mi trabajo:`,
        r1: answers.q33 === 0 ? 'X' : '',
        r2: answers.q33 === 1 ? 'X' : '',
        r3: answers.q33 === 2 ? 'X' : '',
        r4: answers.q33 === 3 ? 'X' : '',
        r5: answers.q33 === 4 ? 'X' : '',
      },
      {
        question: `34. Me informan con quién puedo resolver problemas o asuntos de trabajo:`,
        r1: answers.q34 === 0 ? 'X' : '',
        r2: answers.q34 === 1 ? 'X' : '',
        r3: answers.q34 === 2 ? 'X' : '',
        r4: answers.q34 === 3 ? 'X' : '',
        r5: answers.q34 === 4 ? 'X' : '',
      },
      {
        question: `35. Me permiten asistir a capacitaciones relacionadas con mi trabajo:`,
        r1: answers.q35 === 0 ? 'X' : '',
        r2: answers.q35 === 1 ? 'X' : '',
        r3: answers.q35 === 2 ? 'X' : '',
        r4: answers.q35 === 3 ? 'X' : '',
        r5: answers.q35 === 4 ? 'X' : '',
      },
      {
        question: `36. Recibo capacitación útil para hacer mi trabajo:`,
        r1: answers.q36 === 0 ? 'X' : '',
        r2: answers.q36 === 1 ? 'X' : '',
        r3: answers.q36 === 2 ? 'X' : '',
        r4: answers.q36 === 3 ? 'X' : '',
        r5: answers.q36 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S9(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `37. Mi jefe ayuda a organizar mejor el trabajo:`,
        r1: answers.q37 === 0 ? 'X' : '',
        r2: answers.q37 === 1 ? 'X' : '',
        r3: answers.q37 === 2 ? 'X' : '',
        r4: answers.q37 === 3 ? 'X' : '',
        r5: answers.q37 === 4 ? 'X' : '',
      },
      {
        question: `38. Mi jefe tiene en cuenta mis puntos de vista y opiniones:`,
        r1: answers.q38 === 0 ? 'X' : '',
        r2: answers.q38 === 1 ? 'X' : '',
        r3: answers.q38 === 2 ? 'X' : '',
        r4: answers.q38 === 3 ? 'X' : '',
        r5: answers.q38 === 4 ? 'X' : '',
      },
      {
        question: `39. Mi jefe me comunica a tiempo la información relacionada con el trabajo:`,
        r1: answers.q39 === 0 ? 'X' : '',
        r2: answers.q39 === 1 ? 'X' : '',
        r3: answers.q39 === 2 ? 'X' : '',
        r4: answers.q39 === 3 ? 'X' : '',
        r5: answers.q39 === 4 ? 'X' : '',
      },
      {
        question: `40. La orientación que me da mi jefe me ayuda a realizar mejor mi trabajo:`,
        r1: answers.q40 === 0 ? 'X' : '',
        r2: answers.q40 === 1 ? 'X' : '',
        r3: answers.q40 === 2 ? 'X' : '',
        r4: answers.q40 === 3 ? 'X' : '',
        r5: answers.q40 === 4 ? 'X' : '',
      },
      {
        question: `41. Mi jefe me ayuda a solucionar los problemas que se presentan en el trabajo:`,
        r1: answers.q41 === 0 ? 'X' : '',
        r2: answers.q41 === 1 ? 'X' : '',
        r3: answers.q41 === 2 ? 'X' : '',
        r4: answers.q41 === 3 ? 'X' : '',
        r5: answers.q41 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S10(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `42. Puedo confiar en mis compañeros de trabajo:`,
        r1: answers.q42 === 0 ? 'X' : '',
        r2: answers.q42 === 1 ? 'X' : '',
        r3: answers.q42 === 2 ? 'X' : '',
        r4: answers.q42 === 3 ? 'X' : '',
        r5: answers.q42 === 4 ? 'X' : '',
      },
      {
        question: `43. Entre compañeros solucionamos los problemas de trabajo de forma respetuosa:`,
        r1: answers.q43 === 0 ? 'X' : '',
        r2: answers.q43 === 1 ? 'X' : '',
        r3: answers.q43 === 2 ? 'X' : '',
        r4: answers.q43 === 3 ? 'X' : '',
        r5: answers.q43 === 4 ? 'X' : '',
      },
      {
        question: `44. En mi trabajo me hacen sentir parte del grupo:`,
        r1: answers.q44 === 0 ? 'X' : '',
        r2: answers.q44 === 1 ? 'X' : '',
        r3: answers.q44 === 2 ? 'X' : '',
        r4: answers.q44 === 3 ? 'X' : '',
        r5: answers.q44 === 4 ? 'X' : '',
      },
      {
        question: `45. Cuando tenemos que realizar trabajo de equipo los compañeros colaboran:`,
        r1: answers.q45 === 0 ? 'X' : '',
        r2: answers.q45 === 1 ? 'X' : '',
        r3: answers.q45 === 2 ? 'X' : '',
        r4: answers.q45 === 3 ? 'X' : '',
        r5: answers.q45 === 4 ? 'X' : '',
      },
      {
        question: `46. Mis compañeros de trabajo me ayudan cuando tengo dificultades:`,
        r1: answers.q46 === 0 ? 'X' : '',
        r2: answers.q46 === 1 ? 'X' : '',
        r3: answers.q46 === 2 ? 'X' : '',
        r4: answers.q46 === 3 ? 'X' : '',
        r5: answers.q46 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S11(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `47. Me informan sobre lo que hago bien en mi trabajo:`,
        r1: answers.q47 === 0 ? 'X' : '',
        r2: answers.q47 === 1 ? 'X' : '',
        r3: answers.q47 === 2 ? 'X' : '',
        r4: answers.q47 === 3 ? 'X' : '',
        r5: answers.q47 === 4 ? 'X' : '',
      },
      {
        question: `48. La forma como evalúan mi trabajo en mi centro de trabajo me ayuda a mejorar mi desempeño:`,
        r1: answers.q48 === 0 ? 'X' : '',
        r2: answers.q48 === 1 ? 'X' : '',
        r3: answers.q48 === 2 ? 'X' : '',
        r4: answers.q48 === 3 ? 'X' : '',
        r5: answers.q48 === 4 ? 'X' : '',
      },
      {
        question: `49. En mi centro de trabajo me pagan a tiempo mi salario:`,
        r1: answers.q49 === 0 ? 'X' : '',
        r2: answers.q49 === 1 ? 'X' : '',
        r3: answers.q49 === 2 ? 'X' : '',
        r4: answers.q49 === 3 ? 'X' : '',
        r5: answers.q49 === 4 ? 'X' : '',
      },
      {
        question: `50. El pago que recibo es el que merezco por el trabajo que realizo:`,
        r1: answers.q50 === 0 ? 'X' : '',
        r2: answers.q50 === 1 ? 'X' : '',
        r3: answers.q50 === 2 ? 'X' : '',
        r4: answers.q50 === 3 ? 'X' : '',
        r5: answers.q50 === 4 ? 'X' : '',
      },
      {
        question: `51. Si obtengo los resultados esperados en mi trabajo me recompensan o reconocen:`,
        r1: answers.q51 === 0 ? 'X' : '',
        r2: answers.q51 === 1 ? 'X' : '',
        r3: answers.q51 === 2 ? 'X' : '',
        r4: answers.q51 === 3 ? 'X' : '',
        r5: answers.q51 === 4 ? 'X' : '',
      },
      {
        question: `52. Las personas que hacen bien el trabajo pueden crecer laboralmente:`,
        r1: answers.q52 === 0 ? 'X' : '',
        r2: answers.q52 === 1 ? 'X' : '',
        r3: answers.q52 === 2 ? 'X' : '',
        r4: answers.q52 === 3 ? 'X' : '',
        r5: answers.q52 === 4 ? 'X' : '',
      },
      {
        question: `53. Considero que mi trabajo es estable:`,
        r1: answers.q53 === 0 ? 'X' : '',
        r2: answers.q53 === 1 ? 'X' : '',
        r3: answers.q53 === 2 ? 'X' : '',
        r4: answers.q53 === 3 ? 'X' : '',
        r5: answers.q53 === 4 ? 'X' : '',
      },
      {
        question: `54. En mi trabajo existe continua rotación de personal:`,
        r1: answers.q54 === 0 ? 'X' : '',
        r2: answers.q54 === 1 ? 'X' : '',
        r3: answers.q54 === 2 ? 'X' : '',
        r4: answers.q54 === 3 ? 'X' : '',
        r5: answers.q54 === 4 ? 'X' : '',
      },
      {
        question: `55. Siento orgullo de laborar en este centro de trabajo:`,
        r1: answers.q55 === 0 ? 'X' : '',
        r2: answers.q55 === 1 ? 'X' : '',
        r3: answers.q55 === 2 ? 'X' : '',
        r4: answers.q55 === 3 ? 'X' : '',
        r5: answers.q55 === 4 ? 'X' : '',
      },
      {
        question: `56. Me siento comprometido con mi trabajo:`,
        r1: answers.q56 === 0 ? 'X' : '',
        r2: answers.q56 === 1 ? 'X' : '',
        r3: answers.q56 === 2 ? 'X' : '',
        r4: answers.q56 === 3 ? 'X' : '',
        r5: answers.q56 === 4 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S12(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `57. En mi trabajo puedo expresarme libremente sin interrupciones:`,
        r1: answers.q57 === 0 ? 'X' : '',
        r2: answers.q57 === 1 ? 'X' : '',
        r3: answers.q57 === 2 ? 'X' : '',
        r4: answers.q57 === 3 ? 'X' : '',
        r5: answers.q57 === 4 ? 'X' : '',
      },
      {
        question: `58. Recibo críticas constantes a mi persona y/o trabajo:`,
        r1: answers.q58 === 4 ? 'X' : '',
        r2: answers.q58 === 3 ? 'X' : '',
        r3: answers.q58 === 2 ? 'X' : '',
        r4: answers.q58 === 1 ? 'X' : '',
        r5: answers.q58 === 0 ? 'X' : '',
      },
      {
        question: `59. Recibo burlas, calumnias, difamaciones, humillaciones o ridiculizaciones:`,
        r1: answers.q59 === 4 ? 'X' : '',
        r2: answers.q59 === 3 ? 'X' : '',
        r3: answers.q59 === 2 ? 'X' : '',
        r4: answers.q59 === 1 ? 'X' : '',
        r5: answers.q59 === 0 ? 'X' : '',
      },
      {
        question: `60. Se ignora mi presencia o se me excluye de las reuniones de trabajo y en la toma de decisiones:`,
        r1: answers.q60 === 4 ? 'X' : '',
        r2: answers.q60 === 3 ? 'X' : '',
        r3: answers.q60 === 2 ? 'X' : '',
        r4: answers.q60 === 1 ? 'X' : '',
        r5: answers.q60 === 0 ? 'X' : '',
      },
      {
        question: `61. Se manipulan las situaciones de trabajo para hacerme parecer un mal trabajador:`,
        r1: answers.q61 === 4 ? 'X' : '',
        r2: answers.q61 === 3 ? 'X' : '',
        r3: answers.q61 === 2 ? 'X' : '',
        r4: answers.q61 === 1 ? 'X' : '',
        r5: answers.q61 === 0 ? 'X' : '',
      },
      {
        question: `62. Se ignoran mis éxitos laborales y se atribuyen a otros trabajadores:`,
        r1: answers.q62 === 4 ? 'X' : '',
        r2: answers.q62 === 3 ? 'X' : '',
        r3: answers.q62 === 2 ? 'X' : '',
        r4: answers.q62 === 1 ? 'X' : '',
        r5: answers.q62 === 0 ? 'X' : '',
      },
      {
        question: `63. Me bloquean o impiden las oportunidades que tengo para obtener ascenso o mejora en mi trabajo:`,
        r1: answers.q63 === 4 ? 'X' : '',
        r2: answers.q63 === 3 ? 'X' : '',
        r3: answers.q63 === 2 ? 'X' : '',
        r4: answers.q63 === 1 ? 'X' : '',
        r5: answers.q63 === 0 ? 'X' : '',
      },
      {
        question: `64. He presenciado actos de violencia en mi centro de trabajo:`,
        r1: answers.q64 === 4 ? 'X' : '',
        r2: answers.q64 === 3 ? 'X' : '',
        r3: answers.q64 === 2 ? 'X' : '',
        r4: answers.q64 === 1 ? 'X' : '',
        r5: answers.q64 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S13(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `65. Atiendo clientes o usuarios muy enojados:`,
        r1: answers.q65 === 4 ? 'X' : '',
        r2: answers.q65 === 3 ? 'X' : '',
        r3: answers.q65 === 2 ? 'X' : '',
        r4: answers.q65 === 1 ? 'X' : '',
        r5: answers.q65 === 0 ? 'X' : '',
      },
      {
        question: `66. Mi trabajo me exige atender personas muy necesitadas de ayuda o enfermas:`,
        r1: answers.q66 === 4 ? 'X' : '',
        r2: answers.q66 === 3 ? 'X' : '',
        r3: answers.q66 === 2 ? 'X' : '',
        r4: answers.q66 === 1 ? 'X' : '',
        r5: answers.q66 === 0 ? 'X' : '',
      },
      {
        question: `67. Para hacer mi trabajo debo demostrar sentimientos distintos a los míos:`,
        r1: answers.q67 === 4 ? 'X' : '',
        r2: answers.q67 === 3 ? 'X' : '',
        r3: answers.q67 === 2 ? 'X' : '',
        r4: answers.q67 === 1 ? 'X' : '',
        r5: answers.q67 === 0 ? 'X' : '',
      },
      {
        question: `68. Mi trabajo me exige atender situaciones de violencia:`,
        r1: answers.q68 === 4 ? 'X' : '',
        r2: answers.q68 === 3 ? 'X' : '',
        r3: answers.q68 === 2 ? 'X' : '',
        r4: answers.q68 === 1 ? 'X' : '',
        r5: answers.q68 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersQ3S14(answers) {
    let res = [
      {
        question: `Pregunta`,
        r1: 'Siempre',
        r2: 'Casi siempre',
        r3: 'Algunas veces',
        r4: 'Casi nunca',
        r5: 'Nunca',
      },
      {
        question: `69. Comunican tarde los asuntos de trabajo:`,
        r1: answers.q69 === 4 ? 'X' : '',
        r2: answers.q69 === 3 ? 'X' : '',
        r3: answers.q69 === 2 ? 'X' : '',
        r4: answers.q69 === 1 ? 'X' : '',
        r5: answers.q69 === 0 ? 'X' : '',
      },
      {
        question: `70. Dificultan el logro de los resultados del trabajo:`,
        r1: answers.q70 === 4 ? 'X' : '',
        r2: answers.q70 === 3 ? 'X' : '',
        r3: answers.q70 === 2 ? 'X' : '',
        r4: answers.q70 === 1 ? 'X' : '',
        r5: answers.q70 === 0 ? 'X' : '',
      },
      {
        question: `71. Cooperan poco cuando se necesita:`,
        r1: answers.q71 === 4 ? 'X' : '',
        r2: answers.q71 === 3 ? 'X' : '',
        r3: answers.q71 === 2 ? 'X' : '',
        r4: answers.q71 === 1 ? 'X' : '',
        r5: answers.q71 === 0 ? 'X' : '',
      },
      {
        question: `72. Ignoran las sugerencias para mejorar su trabajo:`,
        r1: answers.q72 === 4 ? 'X' : '',
        r2: answers.q72 === 3 ? 'X' : '',
        r3: answers.q72 === 2 ? 'X' : '',
        r4: answers.q72 === 1 ? 'X' : '',
        r5: answers.q72 === 0 ? 'X' : '',
      },
    ];


    return res;
  }

  getAnswersS1(answers) {
    let res = [];
    res.push({
      question: 'Pregunta',
      yes: 'Si',
      no: 'No'
    });
    res.push({
      question: `¿Ha presenciado o sufrido alguna vez, durante o con motivo del trabajo un acontecimiento como los siguientes?`,
      yes: '',
      no: ''
    });
    res.push({
      question: `¿Accidente que tenga como consecuencia la muerte, la pérdida de un miembro o una lesión grave?`,
      yes: answers.s1_1 === 1 ? 'X' : '',
      no: answers.s1_1 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Asaltos?`,
      yes: answers.s1_2 === 1 ? 'X' :  '',
      no: answers.s1_2 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Actos violentos que derivaron en lesiones graves?`,
      yes: answers.s1_3 === 1 ? 'X' : '',
      no: answers.s1_3 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Secuestro?`,
      yes: answers.s1_4 === 1 ? 'X' : '',
      no: answers.s1_4 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Amenazas?`,
      yes: answers.s1_5 === 1 ? 'X' : '',
      no: answers.s1_5 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Cualquier otro que ponga en riesgo su vida o salud, y/o la de otras personas?`,
      yes: answers.s1_6 === 1 ? 'X' : '',
      no: answers.s1_6 === 0 ? 'X' : '',
    });
    return res;
  }

  getAnswersS2(answers) {
    let res = [];
    res.push({
      question: 'Pregunta',
      yes: 'Si',
      no: 'No'
    });
    res.push({
      question: `¿Ha tenido recuerdos recurrentes sobre el acontecimiento 
  que le provocan malestares?`,
      yes: answers.s2_1 === 1 ? 'X' : '',
      no: answers.s2_1 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha tenido sueños de carácter recurrente sobre el acontecimiento, que le producen malestar?`,
      yes: answers.s2_2 === 1 ? 'X' : '',
      no: answers.s2_2 === 0 ? 'X' : '',
    });
    return res;
  }

  getAnswersS3(answers) {
    let res = [];
    res.push({
      question: 'Pregunta',
      yes: 'Si',
      no: 'No'
    });
    res.push({
      question: `¿Se ha esforzado por evitar todo tipo de sentimientos, conversaciones o situaciones que le puedan recordar el acontecimiento?`,
      yes: answers.s3_1 === 1 ? 'X' : '',
      no: answers.s3_1 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Se ha esforzado por evitar todo tipo de actividades, 
  lugares o personas que motivan recuerdos del acontecimiento?`,
      yes: answers.s3_2 === 1 ? 'X' : '',
      no: answers.s3_2 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha tenido dificultad para recordar alguna parte importante del evento?`,
      yes: answers.s3_3 === 1 ? 'X' : '',
      no: answers.s3_3 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha disminuido su interés en sus actividades cotidianas?`,
      yes: answers.s3_4 === 1 ? 'X' : '',
      no: answers.s3_4 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Se ha sentido usted alejado o distante de los demás?`,
      yes: answers.s3_5 === 1 ? 'X' : '',
      no: answers.s3_5 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha notado que tiene dificultad para expresar sus sentimientos?`,
      yes: answers.s3_6 === 1 ? 'X' : '',
      no: answers.s3_6 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha tenido la impresión de que su vida se va a acortar, 
  que va a morir antes que otras personas o que tiene un futuro limitado?`,
      yes: answers.s3_7 === 1 ? 'X' : '',
      no: answers.s3_7 === 0 ? 'X' : '',
    });
    return res;
  }

  getAnswersS4(answers) {
    let res = [];
    res.push({
      question: 'Pregunta',
      yes: 'Si',
      no: 'No'
    });
    res.push({
      question: `¿Ha tenido usted dificultades para dormir?`,
      yes: answers.s4_1 === 1 ? 'X' : '',
      no: answers.s4_1 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha estado particularmente irritable o le han dado arranques de coraje?`,
      yes: answers.s4_2 === 1 ? 'X' : '',
      no: answers.s4_2 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha tenido dificultad para concentrarse?`,
      yes: answers.s4_3 === 1 ? 'X' : '',
      no: answers.s4_3 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Ha estado nervioso o constantemente en alerta?`,
      yes: answers.s4_4 === 1 ? 'X' : '',
      no: answers.s4_4 === 0 ? 'X' : '',
    });
    res.push({
      question: `¿Se ha sobresaltado fácilmente por cualquier cosa?`,
      yes: answers.s4_5 === 1 ? 'X' : '',
      no: answers.s4_5 === 0 ? 'X' : '',
    });

    return res;
  }

  evaluateFinal(score: Number) {
    if (this.qType== 2) {
      if (score < 20) {
        return 'Nulo';
      } else if (score < 45) {
        return 'Bajo';
      } else if (score < 70) {
        return 'Medio';
      } else if (score < 90) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 50) {
        return 'Nulo';
      } else if (score < 75) {
        return 'Bajo';
      } else if (score < 99) {
        return 'Medio';
      } else if (score < 140) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkAmbient(score: Number) {
    if (this.qType== 2) {
      if (score < 3) {
        return 'Nulo';
      } else if (score < 5) {
        return 'Bajo';
      } else if (score < 7) {
        return 'Medio';
      } else if (score < 9) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 9) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkActivityF(score: Number) {
    if (this.qType== 2) {
      if (score < 10) {
        return 'Nulo';
      } else if (score < 20) {
        return 'Bajo';
      } else if (score < 30) {
        return 'Medio';
      } else if (score < 40) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 15) {
        return 'Nulo';
      } else if (score < 30) {
        return 'Bajo';
      } else if (score < 45) {
        return 'Medio';
      } else if (score < 60) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkTime(score: Number) {
    if (this.qType== 2) {
      if (score < 4) {
        return 'Nulo';
      } else if (score < 6) {
        return 'Bajo';
      } else if (score < 9) {
        return 'Medio';
      } else if (score < 12) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 7) {
        return 'Bajo';
      } else if (score < 10) {
        return 'Medio';
      } else if (score < 13) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatWorkRelations(score: Number) {
    if (this.qType== 2) {
      if (score < 10) {
        return 'Nulo';
      } else if (score < 18) {
        return 'Bajo';
      } else if (score < 28) {
        return 'Medio';
      } else if (score < 38) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 14) {
        return 'Nulo';
      } else if (score < 29) {
        return 'Bajo';
      } else if (score < 42) {
        return 'Medio';
      } else if (score < 58) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateCatOrganization(score: Number) {
    if (score < 10) {
      return 'Nulo';
    } else if (score < 14) {
      return 'Bajo';
    } else if (score < 18) {
      return 'Medio';
    } else if (score < 23) {
      return 'Alto';
    } else {
      return 'Muy alto';
    }
  }

  evaluateDomWorkConditions(score: Number) {
    if (this.qType== 2) {
      if (score < 3) {
        return 'Nulo';
      } else if (score < 5) {
        return 'Bajo';
      } else if (score < 7) {
        return 'Medio';
      } else if (score < 9) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 9) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomWorkLoad(score: Number) {
    if (this.qType== 2) {
      if (score < 12) {
        return 'Nulo';
      } else if (score < 16) {
        return 'Bajo';
      } else if (score < 20) {
        return 'Medio';
      } else if (score < 24) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 15) {
        return 'Nulo';
      } else if (score < 21) {
        return 'Bajo';
      } else if (score < 27) {
        return 'Medio';
      } else if (score < 37) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomMissingControl(score: Number) {
    if (this.qType== 2) {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 8) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 11) {
        return 'Nulo';
      } else if (score < 16) {
        return 'Bajo';
      } else if (score < 21) {
        return 'Medio';
      } else if (score < 25) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomWorkHours(score: Number) {
    if (this.qType== 2) {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 2) {
        return 'Bajo';
      } else if (score < 4) {
        return 'Medio';
      } else if (score < 6) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 2) {
        return 'Bajo';
      } else if (score < 4) {
        return 'Medio';
      } else if (score < 6) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomFamily(score: Number) {
    if (this.qType== 2) {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 2) {
        return 'Bajo';
      } else if (score < 4) {
        return 'Medio';
      } else if (score < 6) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 4) {
        return 'Nulo';
      } else if (score < 6) {
        return 'Bajo';
      } else if (score < 8) {
        return 'Medio';
      } else if (score < 10) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomLeadership(score: Number) {
    if (this.qType== 2) {
      if (score < 1) {
        return 'Nulo';
      } else if (score < 5) {
        return 'Bajo';
      } else if (score < 8) {
        return 'Medio';
      } else if (score < 11) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 9) {
        return 'Nulo';
      } else if (score < 12) {
        return 'Bajo';
      } else if (score < 16) {
        return 'Medio';
      } else if (score < 20) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomWorkRelations(score: Number) {
    if (this.qType== 2) {
      if (score < 5) {
        return 'Nulo';
      } else if (score < 8) {
        return 'Bajo';
      } else if (score < 11) {
        return 'Medio';
      } else if (score < 14) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 10) {
        return 'Nulo';
      } else if (score < 13) {
        return 'Bajo';
      } else if (score < 17) {
        return 'Medio';
      } else if (score < 21) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomViolence(score: Number) {
    if (this.qType== 2) {
      if (score < 7) {
        return 'Nulo';
      } else if (score < 10) {
        return 'Bajo';
      } else if (score < 13) {
        return 'Medio';
      } else if (score < 16) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    } else {
      if (score < 7) {
        return 'Nulo';
      } else if (score < 10) {
        return 'Bajo';
      } else if (score < 13) {
        return 'Medio';
      } else if (score < 16) {
        return 'Alto';
      } else {
        return 'Muy alto';
      }
    }
  }

  evaluateDomRecognitions(score: Number) {
    if (score < 6) {
      return 'Nulo';
    } else if (score < 10) {
      return 'Bajo';
    } else if (score < 14) {
      return 'Medio';
    } else if (score < 18) {
      return 'Alto';
    } else {
      return 'Muy alto';
    }
  }

  evaluateDomInstability(score: Number) {
    if (score < 4) {
      return 'Nulo';
    } else if (score < 6) {
      return 'Bajo';
    } else if (score < 8) {
      return 'Medio';
    } else if (score < 10) {
      return 'Alto';
    } else {
      return 'Muy alto';
    }
  }
}
