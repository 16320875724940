import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  getError(code) {
    let strError = 'Algo salió mal';
    switch(code) {
      case 101:
        strError = 'No hay token';
        break;
      case 102:
        strError = 'Token expirado';
        break;
      case 103:
        strError = 'Token invalido';
        break;
      case 301:
        strError = 'Error en la base de datos';
        break;
      case 401:
        strError = 'Usuario no encontrado';
        break;
      case 402:
        strError = 'Cliente no encontrado';
        break;
      case 403:
        strError = 'Tipo de usuario no encontrado';
        break;
      case 404:
        strError = 'Email en uso';
        break;
      case 405:
        strError = 'Género no encontrado';
        break;
      case 406:
        strError = 'Área no encontrada';
        break;
      case 407:
        strError = 'El Area ya existe';
        break;
      case 408:
        strError = 'El Cliente ya existe';
        break;
      case 409:
        strError = 'Aplicación de Cuestionario no encontrada';
        break;
      case 410:
        strError = 'La Aplicación de Cuestionario ya existe';
        break;
      case 411:
        strError = 'Cuestionario no encontrado';
        break;
      case 412:
        strError = 'Tipo de Aplicación no encontrada';
        break;
      case 413:
        strError = 'Subtipo de Aplicación no encontrada';
        break;
      case 414:
        strError = 'Estado de Aplicación Cuestionario no encontrada';
        break;
      case 415:
        strError = 'Estado de la Aplicación no encontrado';
        break;
      case 416:
        strError = 'Sociodemográfico no encontrado';
        break;
      case 417:
        strError = 'Area de Aplicación no encontrada';
        break;
      case 418:
        strError = 'Colaborador no encontrado';
        break;
      case 419:
        strError = 'Estado de respuesta no encontrado';
        break;
      case 420:
        strError = 'El Email ya esta en uso';
        break;
      case 421:
        strError = 'El usuario ya esta en uso';
        break;
      case 422:
        strError = 'Edad no encontrada';
        break;
      case 423:
        strError = 'Estado civil no encontrado';
        break;
      case 424:
        strError = 'Nivel de estudios no encontrado';
        break;
      case 425:
        strError = 'Tipo de puesto no encontrado';
        break;
      case 426:
        strError = 'Tipo de contratación no encontrado';
        break;
      case 427:
        strError = 'Tipo de colaborador no encontrado';
        break;
      case 428:
        strError = 'Horario no encontrado';
        break;
      case 429:
        strError = 'Rotación de horario no encontrado';
        break;
      case 430:
        strError = 'Experiencia laboral no encontrada';
        break;
      case 431:
        strError = 'Tiempo en puesto actual no encontrado';
        break;
      case 432:
        strError = 'Respuesta del cuestionario 1 no encontrada';
        break;
      case 433:
        strError = 'La respuesta del cuestionario ya se envió';
        break;
      case 434:
        strError = 'Tipo de cuestionario incorrecto';
        break;
      case 435:
        strError = 'El cuestionario ya fue contestado';
        break;
      case 436:
        strError = 'Link expirado';
        break;
      case 437:
        strError = 'EL cuestionario esta cerrado';
        break;
      case 438:
        strError = 'Usuario incorrecto';
        break;
      case 439:
        strError = 'Contraseña incorrecta';
        break;
      case 440:
        strError = 'Estado no encontrado';
        break;
    }
    return strError;
  }
}
