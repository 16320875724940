import { Component, OnInit, Input } from '@angular/core';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { NotifierService } from 'angular-notifier';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-sociodemographics',
  templateUrl: './sociodemographics.component.html'
})
export class SociodemographicsComponent implements OnInit {
  
  @Input() idQuestionnaireApplication;
  questionnaireSocioDemographic: any;
  aux_user: any;
  editSocioDemographicForm: FormGroup;
  canEdit = false;
  loadingUpdate = false;

  constructor(private questionnarieService: QuestionnaireService, private notifierService: NotifierService,
    private authService: AuthService, private errService: ErrorService) {
    this.editSocioDemographicForm = new FormGroup({
      email: new FormControl(''),
      cellphone: new FormControl(''),
      name: new FormControl(''),
      gender: new FormControl(''),
      business: new FormControl(''),
      age: new FormControl(''),
      age_segmented: new FormControl(''),
      civil_status: new FormControl(''),
      education_level: new FormControl(''),
      occupation: new FormControl(''),
      position_type: new FormControl(''),
      recruitment_type: new FormControl(''),
      employee_type: new FormControl(''),
      working_hours_type: new FormControl(''),
      working_hours_rotation: new FormControl(''),
      work_experience: new FormControl(''),
      current_position_time: new FormControl(''),
      state: new FormControl('')
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.disableForm();
    this.getSocio();
  }

  async getSocio() {
    const res = await this.questionnarieService.getSocioDemographics(this.idQuestionnaireApplication);
    if (res.success) {
      this.questionnaireSocioDemographic = res.data.application_sociodemographic;
      this.setValues();
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  setValues() {
    this.editSocioDemographicForm.controls['email'].setValue(this.questionnaireSocioDemographic.email === 1);
    this.editSocioDemographicForm.controls['cellphone'].setValue(this.questionnaireSocioDemographic.cellphone === 1);
    this.editSocioDemographicForm.controls['name'].setValue(this.questionnaireSocioDemographic.name === 1);
    this.editSocioDemographicForm.controls['gender'].setValue(this.questionnaireSocioDemographic.gender === 1);
    this.editSocioDemographicForm.controls['age'].setValue(this.questionnaireSocioDemographic.age === 1);
    this.editSocioDemographicForm.controls['business'].setValue(this.questionnaireSocioDemographic.business === 1);
    this.editSocioDemographicForm.controls['age_segmented'].setValue(this.questionnaireSocioDemographic.age_segmented === 1);
    this.editSocioDemographicForm.controls['civil_status'].setValue(this.questionnaireSocioDemographic.civil_status === 1);
    this.editSocioDemographicForm.controls['education_level'].setValue(this.questionnaireSocioDemographic.education_level === 1);
    this.editSocioDemographicForm.controls['occupation'].setValue(this.questionnaireSocioDemographic.occupation === 1);
    this.editSocioDemographicForm.controls['position_type'].setValue(this.questionnaireSocioDemographic.position_type === 1);
    this.editSocioDemographicForm.controls['recruitment_type'].setValue(this.questionnaireSocioDemographic.recruitment_type === 1);
    this.editSocioDemographicForm.controls['employee_type'].setValue(this.questionnaireSocioDemographic.employee_type === 1);
    this.editSocioDemographicForm.controls['working_hours_type'].setValue(this.questionnaireSocioDemographic.working_hours_type === 1);
    this.editSocioDemographicForm.controls['working_hours_rotation'].setValue(this.questionnaireSocioDemographic.working_hours_rotation === 1);
    this.editSocioDemographicForm.controls['work_experience'].setValue(this.questionnaireSocioDemographic.work_experience === 1);
    this.editSocioDemographicForm.controls['current_position_time'].setValue(this.questionnaireSocioDemographic.current_position_time === 1);
    this.editSocioDemographicForm.controls['state'].setValue(this.questionnaireSocioDemographic.state === 1);
  }

  disableForm() {
    this.editSocioDemographicForm.controls['email'].disable();
    this.editSocioDemographicForm.controls['cellphone'].disable();
    this.editSocioDemographicForm.controls['name'].disable();
    this.editSocioDemographicForm.controls['gender'].disable();
    this.editSocioDemographicForm.controls['business'].disable();
    this.editSocioDemographicForm.controls['age'].disable();
    this.editSocioDemographicForm.controls['age_segmented'].disable();
    this.editSocioDemographicForm.controls['civil_status'].disable();
    this.editSocioDemographicForm.controls['education_level'].disable();
    this.editSocioDemographicForm.controls['occupation'].disable();
    this.editSocioDemographicForm.controls['position_type'].disable();
    this.editSocioDemographicForm.controls['recruitment_type'].disable();
    this.editSocioDemographicForm.controls['employee_type'].disable();
    this.editSocioDemographicForm.controls['working_hours_type'].disable();
    this.editSocioDemographicForm.controls['working_hours_rotation'].disable();
    this.editSocioDemographicForm.controls['work_experience'].disable();
    this.editSocioDemographicForm.controls['current_position_time'].disable();
    this.editSocioDemographicForm.controls['state'].disable();
  }

  enableForm() {
    this.editSocioDemographicForm.controls['email'].enable();
    this.editSocioDemographicForm.controls['cellphone'].enable();
    this.editSocioDemographicForm.controls['name'].enable();
    this.editSocioDemographicForm.controls['gender'].enable();
    this.editSocioDemographicForm.controls['business'].enable();
    this.editSocioDemographicForm.controls['age'].enable();
    this.editSocioDemographicForm.controls['age_segmented'].enable();
    this.editSocioDemographicForm.controls['civil_status'].enable();
    this.editSocioDemographicForm.controls['education_level'].enable();
    this.editSocioDemographicForm.controls['occupation'].enable();
    this.editSocioDemographicForm.controls['position_type'].enable();
    this.editSocioDemographicForm.controls['recruitment_type'].enable();
    this.editSocioDemographicForm.controls['employee_type'].enable();
    this.editSocioDemographicForm.controls['working_hours_type'].enable();
    this.editSocioDemographicForm.controls['working_hours_rotation'].enable();
    this.editSocioDemographicForm.controls['work_experience'].enable();
    this.editSocioDemographicForm.controls['current_position_time'].enable();
    this.editSocioDemographicForm.controls['state'].enable();
  }

  async editSocioDemographics() {
    
    this.loadingUpdate = true;
    const data = {
      email: this.editSocioDemographicForm.controls['email'].value ? 1 : 0,
      cellphone: this.editSocioDemographicForm.controls['cellphone'].value ? 1 : 0,
      name: this.editSocioDemographicForm.controls['name'].value ? 1 : 0,
      gender: this.editSocioDemographicForm.controls['gender'].value ? 1 : 0,
      business: this.editSocioDemographicForm.controls['business'].value ? 1 : 0,
      age: this.editSocioDemographicForm.controls['age'].value ? 1 : 0,
      age_segmented: this.editSocioDemographicForm.controls['age_segmented'].value ? 1 : 0,
      civil_status: this.editSocioDemographicForm.controls['civil_status'].value ? 1 : 0,
      education_level: this.editSocioDemographicForm.controls['education_level'].value ? 1 : 0,
      occupation: this.editSocioDemographicForm.controls['occupation'].value ? 1 : 0,
      position_type: this.editSocioDemographicForm.controls['position_type'].value ? 1 : 0,
      recruitment_type: this.editSocioDemographicForm.controls['recruitment_type'].value ? 1 : 0,
      employee_type: this.editSocioDemographicForm.controls['employee_type'].value ? 1 : 0,
      working_hours_type: this.editSocioDemographicForm.controls['working_hours_type'].value ? 1 : 0,
      working_hours_rotation: this.editSocioDemographicForm.controls['working_hours_rotation'].value ? 1 : 0,
      work_experience: this.editSocioDemographicForm.controls['work_experience'].value ? 1 : 0,
      current_position_time: this.editSocioDemographicForm.controls['current_position_time'].value ? 1 : 0,
      state: this.editSocioDemographicForm.controls['state'].value ? 1 : 0,
    }
    
    const res = await this.questionnarieService.patchSocioDemographics(this.questionnaireSocioDemographic.id_application_sociodemographic, data);
    this.loadingUpdate = false;
    if (res.success) {
      this.getSocio();
      this.setValues();
      this.disableForm();
      this.canEdit = false;
      this.notifierService.notify('success', 'Datos actualizados');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  cancelEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }
}
