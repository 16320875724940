import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RouterExtService } from '../../services/router-ext.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-questionario-details',
  templateUrl: './questionario-details.component.html'
})
export class QuestionarioDetailsComponent implements OnInit {

  questionnaireApplication: any;
  aux_user: any;
  editQuestionnaireForm: FormGroup;
  canEdit = false;
  idQuestionnaireApplication;
  loadingUpdate = false;
  loadingReady = false;
  loadingMail = false;
  loadingSubSeType = false;
  questionnaires = [];
  appTypes = [];
  customToolBar: any;
  appStatuses = [];
  appQStatuses = [];
  genderStats = [];
  subTypes = [];
  subSetTypeModel;
  @ViewChild('tabs2') tbs: NgbTabset;
  @ViewChild('modalSendMailTemplate') public modalSendMailTemplate: ModalDirective;
  @ViewChild('modalConfirmNow') public modalConfirmNow: ModalDirective;
  @ViewChild('modalConfirmTemplate') public modalConfirmTemplate: ModalDirective;
  @ViewChild('modalConfirmSubSetType') public modalConfirmSubSetType: ModalDirective;
  subjectTemplateMail = '';
  template = '';

  constructor(private activatedRoute: ActivatedRoute, private questionnaireService: QuestionnaireService, private router: Router,
    private notifierService: NotifierService, private routerExt: RouterExtService, private authService: AuthService, private errService: ErrorService) {
    this.editQuestionnaireForm = new FormGroup({
      questionnaire_id: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      details: new FormControl('', Validators.required),
      request_date: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      validate_user: new FormControl('', Validators.required),
      validate_access: new FormControl('', Validators.required),
      user_message: new FormControl('', Validators.required),
      access_message: new FormControl('', Validators.required),
      code_message: new FormControl('', Validators.required),
      application_url: new FormControl('',Validators.required),
      application_type_id: new FormControl('',Validators.required),
      questionnaire_application_status_id: new FormControl('', Validators.required),
      answers_sheets: new FormControl('', Validators.required),
      base_report_sheet: new FormControl('', Validators.required),
      base_report: new FormControl('', Validators.required),
      complete_report_sheet: new FormControl('', Validators.required),
      complete_report: new FormControl('', Validators.required),
      application_status_id: new FormControl('', Validators.required),
      start_message: new FormControl('', Validators.required),
      end_message: new FormControl('', Validators.required),
      monday: new FormControl('', Validators.required),
      tuesday: new FormControl('', Validators.required),
      wednesday: new FormControl('', Validators.required),
      thursday: new FormControl('', Validators.required),
      friday: new FormControl('', Validators.required),
      saturday: new FormControl('', Validators.required),
      sunday: new FormControl('', Validators.required),
      application_hour: new FormControl('', Validators.required),
      first_send_template: new FormControl('', Validators.required),
      first_reminder_template: new FormControl('', Validators.required),
      second_reminder_template: new FormControl('', Validators.required),
      third_reminder_template: new FormControl('', Validators.required),
      show_link_button: new FormControl('', Validators.required),
      link_button_message: new FormControl('', Validators.required),
      link_button_url: new FormControl('', Validators.required),
      first_late_template: new FormControl('', Validators.required),
      second_late_template: new FormControl('', Validators.required),
      third_late_template: new FormControl('', Validators.required),
      late_limit_date: new FormControl('', Validators.required),
    });

    this.customToolBar = [['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript', 'fontSize'], 
                          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                          ['paragraph', 'orderedList', 'unorderedList'], 
                          ['delete', 'removeFormat', 'undo']]

    this.disableForm();
    const id = this.activatedRoute.snapshot.paramMap.get('id_q');
    this.idQuestionnaireApplication = id;
    this.getAppQStatuses();
    this.getAppStatuses();
    this.getApplicationTypes();
    this.getQuestionnaires();
    this.getAppSubSetTypes();
    this.getQuestionnaireApplication(id);
    this.getGenderStats();
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    const prev = this.routerExt.getPreviousUrl();
    if (prev.indexOf('areadeaplicacion') !== -1) {
      setTimeout(() => {
        this.tbs.select('app_areas');
      }, 20);
    } else if (prev.indexOf('colaborador') !== -1) {
      setTimeout(() => {
        this.tbs.select('employees');
      }, 20);
    }
  }

  backQuestionnaire() {
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/', this.questionnaireApplication.client_id]);
    } else {
      this.router.navigate(['/client/cuestionarios/']);
    }
  }

  async changeSubSetType(){
    this.loadingSubSeType = true;
    console.log(this.subSetTypeModel);
    if (this.subSetTypeModel == 1) {
      const res = await this.questionnaireService.setTotalPopulaltion(this.idQuestionnaireApplication);
      this.loadingSubSeType = false;
      if (res.success) {
        this.notifierService.notify('success', 'Operación exitosa');
      } else {
        this.notifierService.notify('error', 'Algo salió mal');
        this.subSetTypeModel = 2;
      }
      this.modalConfirmSubSetType.hide();
    } else {
      const res = await this.questionnaireService.checkSample(this.idQuestionnaireApplication);
      if (res.success) {
        this.setSample();
        this.loadingSubSeType = false
      } else if (res.error.code === 461) {
        this.notifierService.notify('error', 'La aplicación del cuestionario no cumple los requisitos de muestra');
        if (res.error.details.missing_area_employee_count !== 0) {
          this.notifierService.notify('error', `Hay ${res.error.details.missing_area_employee_count} colaborador(es) sin área`);
        }
        if (res.error.details.missing_gender_employee_count !== 0) {
          this.notifierService.notify('error', `Hay ${res.error.details.missing_gender_employee_count} colaborador(es) sin género`);
        }
        this.subSetTypeModel = 1;
      } else {
        this.notifierService.notify('error', 'Algo salió mal');
        this.subSetTypeModel = 1;
      }
      this.modalConfirmSubSetType.hide();
    }
  }

  async setSample() {
    const res = await this.questionnaireService.setSample(this.idQuestionnaireApplication);
    if (res.success) {
      this.notifierService.notify('success', 'Operación exitosa');
    } else {
      this.notifierService.notify('error', 'Algo salió mal');
    }
  }


  async getQuestionnaireApplication(id) {
    this.loadingReady = false;
    const res = await this.questionnaireService.getById(id);
    if (res.success) {
      this.questionnaireApplication = res.data.questionnaire_application;
      this.subSetTypeModel = this.questionnaireApplication.application_subset_type_id;
      this.setValues();
      this.loadingReady = true;
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  async getQuestionnaires() {
    const res = await this.questionnaireService.getQuestionnares();
    if (res.success) {
      this.questionnaires = res.data.questionnaires;
    }
  }

  onTabChange(e) {
    if (e.nextId === 'details') {
      this.getQuestionnaireApplication(this.idQuestionnaireApplication);
    } 
  }

  async getApplicationTypes() {
    const res = await this.questionnaireService.getApplicationTypes();
    if (res.success) {
      this.appTypes = res.data.application_types;
    }
  }

  async getAppStatuses() {
    const res = await this.questionnaireService.getApplicationStatuses();
    if (res.success) {
      this.appStatuses = res.data.application_statuses;
    }
  }

  async getAppQStatuses() {
    const res = await this.questionnaireService.getStatuses();
    if (res.success) {
      this.appQStatuses = res.data.questionnaire_application_statuses;
    }
  }

  async getAppSubSetTypes() {
    const res = await this.questionnaireService.getSubTypes();
    if (res.success) {
      this.subTypes = res.data.application_subset_types;
    }
  }

  async getGenderStats() {
    const res = await this.questionnaireService.getGenderStats(this.idQuestionnaireApplication);
    if (res.success) {
      this.genderStats = res.data.gender_statistics;
    }
  }

  ableToEdit() {
    this.enableForm();
    this.canEdit = true;
  }

  disableToEdit() {
    this.disableForm();
    this.canEdit = false;
  }

  showNowMailModal() {
    this.modalConfirmNow.config = {ignoreBackdropClick: true};
    this.modalConfirmNow.show();
  }

  showTemplateMailModal() {
    this.subjectTemplateMail = '';
    this.template = '';
    this.modalSendMailTemplate.show();
    this.modalConfirmTemplate.config = { ignoreBackdropClick: true, keyboard: false }
  }

  setValues() {
    this.editQuestionnaireForm.controls['questionnaire_id'].setValue(this.questionnaireApplication.questionnaire_id);
    // this.editQuestionnaireForm.controls['application_type_id'].setValue(this.questionnaireApplication.application_type_id);
    this.editQuestionnaireForm.controls['name'].setValue(this.questionnaireApplication.name);
    this.editQuestionnaireForm.controls['details'].setValue(this.questionnaireApplication.details);
    
    if (this.questionnaireApplication.request_date !== '0000-00-00 00:00:00') {
      // tslint:disable-next-line: max-line-length
      this.editQuestionnaireForm.controls['request_date'].setValue(new Date(this.questionnaireApplication.request_date).toISOString().substring(0, 10));
    }
    if (this.questionnaireApplication.start_date !== '0000-00-00 00:00:00') {
      // tslint:disable-next-line: max-line-length
      this.editQuestionnaireForm.controls['start_date'].setValue(new Date(this.questionnaireApplication.start_date).toISOString().substring(0, 10));
    }
    if (this.questionnaireApplication.end_date !== '0000-00-00 00:00:00') {
      // tslint:disable-next-line: max-line-length
      this.editQuestionnaireForm.controls['end_date'].setValue(new Date(this.questionnaireApplication.end_date).toISOString().substring(0, 10));
    }
    this.editQuestionnaireForm.controls['validate_user'].setValue(this.questionnaireApplication.validate_user);
    this.editQuestionnaireForm.controls['validate_access'].setValue(this.questionnaireApplication.validate_access);
    this.editQuestionnaireForm.controls['user_message'].setValue(this.questionnaireApplication.user_message);
    this.editQuestionnaireForm.controls['access_message'].setValue(this.questionnaireApplication.access_message);
    this.editQuestionnaireForm.controls['code_message'].setValue(this.questionnaireApplication.code_message);
    this.editQuestionnaireForm.controls['application_url'].setValue(this.questionnaireApplication.application_url);
    this.editQuestionnaireForm.controls['application_type_id'].setValue(this.questionnaireApplication.application_type_id);
    // tslint:disable-next-line: max-line-length
    this.editQuestionnaireForm.controls['questionnaire_application_status_id'].setValue(this.questionnaireApplication.questionnaire_application_status_id);
    this.editQuestionnaireForm.controls['answers_sheets'].setValue(this.questionnaireApplication.answers_sheets);
    this.editQuestionnaireForm.controls['base_report_sheet'].setValue(this.questionnaireApplication.base_report_sheet);
    this.editQuestionnaireForm.controls['base_report'].setValue(this.questionnaireApplication.base_report);
    this.editQuestionnaireForm.controls['complete_report_sheet'].setValue(this.questionnaireApplication.complete_report_sheet);
    this.editQuestionnaireForm.controls['complete_report'].setValue(this.questionnaireApplication.complete_report);
    this.editQuestionnaireForm.controls['application_status_id'].setValue(this.questionnaireApplication.application_status_id);
    this.editQuestionnaireForm.controls['start_message'].setValue(this.questionnaireApplication.start_message);
    this.editQuestionnaireForm.controls['end_message'].setValue(this.questionnaireApplication.end_message);
    this.editQuestionnaireForm.controls['monday'].setValue(this.questionnaireApplication.monday === 1);
    this.editQuestionnaireForm.controls['tuesday'].setValue(this.questionnaireApplication.tuesday === 1);
    this.editQuestionnaireForm.controls['wednesday'].setValue(this.questionnaireApplication.wednesday === 1);
    this.editQuestionnaireForm.controls['thursday'].setValue(this.questionnaireApplication.thursday === 1);
    this.editQuestionnaireForm.controls['friday'].setValue(this.questionnaireApplication.friday === 1);
    this.editQuestionnaireForm.controls['saturday'].setValue(this.questionnaireApplication.saturday === 1);
    this.editQuestionnaireForm.controls['sunday'].setValue(this.questionnaireApplication.sunday === 1);
    this.editQuestionnaireForm.controls['application_hour'].setValue(this.questionnaireApplication.application_hour.substr(0, this.questionnaireApplication.application_hour.length-3));
    this.editQuestionnaireForm.controls['first_send_template'].setValue(this.questionnaireApplication.first_send_template);
    this.editQuestionnaireForm.controls['first_reminder_template'].setValue(this.questionnaireApplication.first_reminder_template);
    this.editQuestionnaireForm.controls['second_reminder_template'].setValue(this.questionnaireApplication.second_reminder_template);
    this.editQuestionnaireForm.controls['third_reminder_template'].setValue(this.questionnaireApplication.third_reminder_template);
    this.editQuestionnaireForm.controls['show_link_button'].setValue(this.questionnaireApplication.show_link_button);
    this.editQuestionnaireForm.controls['link_button_message'].setValue(this.questionnaireApplication.link_button_message);
    this.editQuestionnaireForm.controls['link_button_url'].setValue(this.questionnaireApplication.link_button_url);
    this.editQuestionnaireForm.controls['first_late_template'].setValue(this.questionnaireApplication.first_late_template);
    this.editQuestionnaireForm.controls['second_late_template'].setValue(this.questionnaireApplication.second_late_template);
    this.editQuestionnaireForm.controls['third_late_template'].setValue(this.questionnaireApplication.third_late_template);
    if (this.questionnaireApplication.late_limit_date !== '0000-00-00 00:00:00') {
      // tslint:disable-next-line: max-line-length
      this.editQuestionnaireForm.controls['late_limit_date'].setValue(new Date(this.questionnaireApplication.late_limit_date).toISOString().substring(0, 10));
    }
  }

  disableForm() {
    this.editQuestionnaireForm.controls['questionnaire_id'].disable();
    // this.editQuestionnaireForm.controls['application_type_id'].disable();
    this.editQuestionnaireForm.controls['name'].disable();
    this.editQuestionnaireForm.controls['details'].disable();
    this.editQuestionnaireForm.controls['request_date'].disable();
    this.editQuestionnaireForm.controls['start_date'].disable();
    this.editQuestionnaireForm.controls['end_date'].disable();
    this.editQuestionnaireForm.controls['validate_user'].disable();
    this.editQuestionnaireForm.controls['validate_access'].disable();
    this.editQuestionnaireForm.controls['user_message'].disable();
    this.editQuestionnaireForm.controls['access_message'].disable();
    this.editQuestionnaireForm.controls['code_message'].disable();
    this.editQuestionnaireForm.controls['application_url'].disable();
    this.editQuestionnaireForm.controls['application_type_id'].disable();
    this.editQuestionnaireForm.controls['questionnaire_application_status_id'].disable();
    this.editQuestionnaireForm.controls['answers_sheets'].disable();
    this.editQuestionnaireForm.controls['base_report_sheet'].disable();
    this.editQuestionnaireForm.controls['base_report'].disable();
    this.editQuestionnaireForm.controls['complete_report_sheet'].disable();
    this.editQuestionnaireForm.controls['complete_report'].disable();
    this.editQuestionnaireForm.controls['application_status_id'].disable();
    this.editQuestionnaireForm.controls['start_message'].disable();
    this.editQuestionnaireForm.controls['end_message'].disable();
    this.editQuestionnaireForm.controls['monday'].disable();
    this.editQuestionnaireForm.controls['tuesday'].disable();
    this.editQuestionnaireForm.controls['wednesday'].disable();
    this.editQuestionnaireForm.controls['thursday'].disable();
    this.editQuestionnaireForm.controls['friday'].disable();
    this.editQuestionnaireForm.controls['saturday'].disable();
    this.editQuestionnaireForm.controls['sunday'].disable();
    this.editQuestionnaireForm.controls['application_hour'].disable();
    this.editQuestionnaireForm.controls['first_send_template'].disable();
    this.editQuestionnaireForm.controls['first_reminder_template'].disable();
    this.editQuestionnaireForm.controls['second_reminder_template'].disable();
    this.editQuestionnaireForm.controls['third_reminder_template'].disable();
    this.editQuestionnaireForm.controls['show_link_button'].disable();
    this.editQuestionnaireForm.controls['link_button_message'].disable();
    this.editQuestionnaireForm.controls['link_button_url'].disable();
    this.editQuestionnaireForm.controls['first_late_template'].disable();
    this.editQuestionnaireForm.controls['second_late_template'].disable();
    this.editQuestionnaireForm.controls['third_late_template'].disable();
    this.editQuestionnaireForm.controls['late_limit_date'].disable();
  }

  enableForm() {
    this.editQuestionnaireForm.controls['questionnaire_id'].enable();
    // this.editQuestionnaireForm.controls['application_type_id'].enable();
    this.editQuestionnaireForm.controls['name'].enable();
    this.editQuestionnaireForm.controls['details'].enable();
    this.editQuestionnaireForm.controls['request_date'].enable();
    this.editQuestionnaireForm.controls['start_date'].enable();
    this.editQuestionnaireForm.controls['end_date'].enable();
    this.editQuestionnaireForm.controls['validate_user'].enable();
    this.editQuestionnaireForm.controls['validate_access'].enable();
    this.editQuestionnaireForm.controls['user_message'].enable();
    this.editQuestionnaireForm.controls['access_message'].enable();
    this.editQuestionnaireForm.controls['code_message'].enable();
    this.editQuestionnaireForm.controls['application_url'].enable();
    this.editQuestionnaireForm.controls['application_type_id'].enable();
    this.editQuestionnaireForm.controls['questionnaire_application_status_id'].enable();
    this.editQuestionnaireForm.controls['answers_sheets'].enable();
    this.editQuestionnaireForm.controls['base_report_sheet'].enable();
    this.editQuestionnaireForm.controls['base_report'].enable();
    this.editQuestionnaireForm.controls['complete_report_sheet'].enable();
    this.editQuestionnaireForm.controls['complete_report'].enable();
    this.editQuestionnaireForm.controls['application_status_id'].enable();
    this.editQuestionnaireForm.controls['start_message'].enable();
    this.editQuestionnaireForm.controls['end_message'].enable();
    this.editQuestionnaireForm.controls['monday'].enable();
    this.editQuestionnaireForm.controls['tuesday'].enable();
    this.editQuestionnaireForm.controls['wednesday'].enable();
    this.editQuestionnaireForm.controls['thursday'].enable();
    this.editQuestionnaireForm.controls['friday'].enable();
    this.editQuestionnaireForm.controls['saturday'].enable();
    this.editQuestionnaireForm.controls['sunday'].enable();
    this.editQuestionnaireForm.controls['application_hour'].enable();
    this.editQuestionnaireForm.controls['first_send_template'].enable();
    this.editQuestionnaireForm.controls['first_reminder_template'].enable();
    this.editQuestionnaireForm.controls['second_reminder_template'].enable();
    this.editQuestionnaireForm.controls['third_reminder_template'].enable();
    this.editQuestionnaireForm.controls['show_link_button'].enable();
    this.editQuestionnaireForm.controls['link_button_message'].enable();
    this.editQuestionnaireForm.controls['link_button_url'].enable();
    this.editQuestionnaireForm.controls['first_late_template'].enable();
    this.editQuestionnaireForm.controls['second_late_template'].enable();
    this.editQuestionnaireForm.controls['third_late_template'].enable();
    this.editQuestionnaireForm.controls['late_limit_date'].enable();
  }

  async editQuestionnaireApplication() {
    this.loadingUpdate = true;

    const data = this.getPatchData();
    const res = await this.questionnaireService.patchById(this.idQuestionnaireApplication, data);
    this.loadingUpdate = false;
    if (res.success) {
      this.getQuestionnaireApplication(this.idQuestionnaireApplication);
      this.setValues();
      this.disableForm();
      this.canEdit = false;
      this.notifierService.notify('success', 'Datos actualizados');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  cancelEdit() {
    this.setValues();
    this.disableForm();
    this.canEdit = false;
  }

  getPatchData() {

    const data = {};

    if ( this.editQuestionnaireForm.controls['questionnaire_id'].value !== '' &&
      this.editQuestionnaireForm.controls['questionnaire_id'].value != this.questionnaireApplication.questionnaire_id) {
      data['questionnaire_id'] =  this.editQuestionnaireForm.controls['questionnaire_id'].value;
    }
    if (this.editQuestionnaireForm.controls['application_type_id'].value !== '' &&
      this.editQuestionnaireForm.controls['application_type_id'].value != this.questionnaireApplication.application_type_id) {
      data['application_type_id'] = this.editQuestionnaireForm.controls['application_type_id'].value;
    }
    if (this.editQuestionnaireForm.controls['name'].value !== '' &&
      this.editQuestionnaireForm.controls['name'].value !== this.questionnaireApplication.name) {
      data['name'] = this.editQuestionnaireForm.controls['name'].value;
    }
    if (this.editQuestionnaireForm.controls['details'].value !== '' && 
        this.editQuestionnaireForm.controls['details'].value !== this.questionnaireApplication.details ) {
      data['details'] = this.editQuestionnaireForm.controls['details'].value;
    }
    if (this.editQuestionnaireForm.controls['request_date'].value !== '') {
      if (this.questionnaireApplication.request_date !== '0000-00-00 00:00:00') {
        if (this.editQuestionnaireForm.controls['request_date'].value !== new Date(this.questionnaireApplication.request_date).toISOString().substring(0, 10)) {
          data['request_date'] = this.editQuestionnaireForm.controls['request_date'].value;
        }
      } else {
        data['request_date'] = this.editQuestionnaireForm.controls['request_date'].value;
      }
    }
    if (this.editQuestionnaireForm.controls['start_date'].value !== '') {
      if (this.questionnaireApplication.start_date !== '0000-00-00 00:00:00') {
        if (this.editQuestionnaireForm.controls['start_date'].value !== new Date(this.questionnaireApplication.start_date).toISOString().substring(0, 10)) {
          data['start_date'] = this.editQuestionnaireForm.controls['start_date'].value;
        }
      } else {
        data['start_date'] = this.editQuestionnaireForm.controls['start_date'].value;
      }
    }
    if (this.editQuestionnaireForm.controls['end_date'].value !== '') {
      if (this.questionnaireApplication.end_date !== '0000-00-00 00:00:00') {
        if (this.editQuestionnaireForm.controls['end_date'].value !== new Date(this.questionnaireApplication.end_date).toISOString().substring(0, 10)) {
          data['end_date'] = this.editQuestionnaireForm.controls['end_date'].value;
        }
      } else {
        data['end_date'] = this.editQuestionnaireForm.controls['end_date'].value;
      }
    }
    if (this.editQuestionnaireForm.controls['validate_user'].value !== '' && 
        this.editQuestionnaireForm.controls['validate_user'].value !== this.questionnaireApplication.validate_user ) {
      data['validate_user'] = this.editQuestionnaireForm.controls['validate_user'].value;
    }
    if (this.editQuestionnaireForm.controls['validate_access'].value !== '' && 
        this.editQuestionnaireForm.controls['validate_access'].value !== this.questionnaireApplication.validate_access ) {
      data['validate_access'] = this.editQuestionnaireForm.controls['validate_access'].value;
    }
    if (this.editQuestionnaireForm.controls['user_message'].value !== '' && 
        this.editQuestionnaireForm.controls['user_message'].value !== this.questionnaireApplication.user_message ) {
      data['user_message'] = this.editQuestionnaireForm.controls['user_message'].value;
    }
    if (this.editQuestionnaireForm.controls['access_message'].value !== '' && 
        this.editQuestionnaireForm.controls['access_message'].value !== this.questionnaireApplication.access_message ) {
      data['access_message'] = this.editQuestionnaireForm.controls['access_message'].value;
    }
    if (this.editQuestionnaireForm.controls['code_message'].value !== '' && 
        this.editQuestionnaireForm.controls['code_message'].value !== this.questionnaireApplication.code_message ) {
      data['code_message'] = this.editQuestionnaireForm.controls['code_message'].value;
    }
    if (this.editQuestionnaireForm.controls['application_url'].value !== '' && 
        this.editQuestionnaireForm.controls['application_url'].value !== this.questionnaireApplication.application_url ) {
      data['application_url'] = this.editQuestionnaireForm.controls['application_url'].value;
    } 
    if (this.editQuestionnaireForm.controls['questionnaire_application_status_id'].value !== '' && 
      this.editQuestionnaireForm.controls['questionnaire_application_status_id'].value != this.questionnaireApplication.questionnaire_application_status_id ) {
      data['questionnaire_application_status_id'] = parseInt(this.editQuestionnaireForm.controls['questionnaire_application_status_id'].value);
    }
    if (this.editQuestionnaireForm.controls['application_status_id'].value !== '' && 
      this.editQuestionnaireForm.controls['application_status_id'].value != this.questionnaireApplication.application_status_id ) {
      data['application_status_id'] = this.editQuestionnaireForm.controls['application_status_id'].value;
    }
    if (this.editQuestionnaireForm.controls['start_message'].value !== '' &&
      this.editQuestionnaireForm.controls['start_message'].value != this.questionnaireApplication.start_message) {
      data['start_message'] = this.editQuestionnaireForm.controls['start_message'].value;
    }
    if (this.editQuestionnaireForm.controls['end_message'].value !== '' &&
      this.editQuestionnaireForm.controls['end_message'].value != this.questionnaireApplication.end_message) {
      data['end_message'] = this.editQuestionnaireForm.controls['end_message'].value;
    }
    if (this.editQuestionnaireForm.controls['monday'].value !== (this.questionnaireApplication.monday === 1) ) {
      data['monday'] = this.editQuestionnaireForm.controls['monday'].value ? 1 : 0;
    }
    if (this.editQuestionnaireForm.controls['tuesday'].value !== (this.questionnaireApplication.tuesday === 1)) {
      data['tuesday'] = this.editQuestionnaireForm.controls['tuesday'].value ? 1 : 0;
    }
    if (this.editQuestionnaireForm.controls['wednesday'].value !== (this.questionnaireApplication.wednesday === 1)) {
      data['wednesday'] = this.editQuestionnaireForm.controls['wednesday'].value ? 1 : 0;
    }
    if (this.editQuestionnaireForm.controls['thursday'].value !== (this.questionnaireApplication.thursday === 1)) {
      data['thursday'] = this.editQuestionnaireForm.controls['thursday'].value ? 1 : 0;
    }
    if (this.editQuestionnaireForm.controls['friday'].value !== (this.questionnaireApplication.friday === 1)) {
      data['friday'] = this.editQuestionnaireForm.controls['friday'].value ? 1 : 0;
    }
    if (this.editQuestionnaireForm.controls['saturday'].value !== (this.questionnaireApplication.saturday === 1)) {
      data['saturday'] = this.editQuestionnaireForm.controls['saturday'].value ? 1 : 0;
    }
    if (this.editQuestionnaireForm.controls['sunday'].value !== (this.questionnaireApplication.sunday === 1)) {
      data['sunday'] = this.editQuestionnaireForm.controls['sunday'].value ? 1 : 0;
    }
    if (this.editQuestionnaireForm.controls['application_hour'].value !== '' &&
      this.editQuestionnaireForm.controls['application_hour'].value !== this.questionnaireApplication.application_hour) {
      data['application_hour'] = this.editQuestionnaireForm.controls['application_hour'].value;
    }
    if (this.editQuestionnaireForm.controls['first_send_template'].value !== '' &&
      this.editQuestionnaireForm.controls['first_send_template'].value !== this.questionnaireApplication.first_send_template) {
      data['first_send_template'] = this.editQuestionnaireForm.controls['first_send_template'].value;
    }
    if (this.editQuestionnaireForm.controls['first_reminder_template'].value !== '' &&
      this.editQuestionnaireForm.controls['first_reminder_template'].value !== this.questionnaireApplication.first_reminder_template) {
      data['first_reminder_template'] = this.editQuestionnaireForm.controls['first_reminder_template'].value;
    }
    if (this.editQuestionnaireForm.controls['second_reminder_template'].value !== '' &&
      this.editQuestionnaireForm.controls['second_reminder_template'].value !== this.questionnaireApplication.second_reminder_template) {
      data['second_reminder_template'] = this.editQuestionnaireForm.controls['second_reminder_template'].value;
    }
    if (this.editQuestionnaireForm.controls['third_reminder_template'].value !== '' &&
      this.editQuestionnaireForm.controls['third_reminder_template'].value !== this.questionnaireApplication.third_reminder_template) {
      data['third_reminder_template'] = this.editQuestionnaireForm.controls['third_reminder_template'].value;
    }
    if (this.editQuestionnaireForm.controls['show_link_button'].value !== '' &&
      this.editQuestionnaireForm.controls['show_link_button'].value !== this.questionnaireApplication.show_link_button) {
      data['show_link_button'] = this.editQuestionnaireForm.controls['show_link_button'].value;
    }
    if (this.editQuestionnaireForm.controls['link_button_message'].value !== '' &&
      this.editQuestionnaireForm.controls['link_button_message'].value !== this.questionnaireApplication.link_button_message) {
      data['link_button_message'] = this.editQuestionnaireForm.controls['link_button_message'].value;
    }
    if (this.editQuestionnaireForm.controls['link_button_url'].value !== '' &&
      this.editQuestionnaireForm.controls['link_button_url'].value !== this.questionnaireApplication.link_button_url) {
      data['link_button_url'] = this.editQuestionnaireForm.controls['link_button_url'].value;
    }
    if (this.editQuestionnaireForm.controls['first_late_template'].value !== '' &&
      this.editQuestionnaireForm.controls['first_late_template'].value !== this.questionnaireApplication.first_late_template) {
      data['first_late_template'] = this.editQuestionnaireForm.controls['first_late_template'].value;
    }
    if (this.editQuestionnaireForm.controls['second_late_template'].value !== '' &&
      this.editQuestionnaireForm.controls['second_late_template'].value !== this.questionnaireApplication.second_late_template) {
      data['second_late_template'] = this.editQuestionnaireForm.controls['second_late_template'].value;
    }
    if (this.editQuestionnaireForm.controls['third_late_template'].value !== '' &&
      this.editQuestionnaireForm.controls['third_late_template'].value !== this.questionnaireApplication.third_late_template) {
      data['third_late_template'] = this.editQuestionnaireForm.controls['third_late_template'].value;
    }
    if (this.editQuestionnaireForm.controls['late_limit_date'].value !== '') {
      if (this.questionnaireApplication.late_limit_date !== '0000-00-00 00:00:00') {
        if (this.editQuestionnaireForm.controls['late_limit_date'].value !== new Date(this.questionnaireApplication.late_limit_date).toISOString().substring(0, 10)) {
          data['late_limit_date'] = this.editQuestionnaireForm.controls['late_limit_date'].value;
        }
      } else {
        data['late_limit_date'] = this.editQuestionnaireForm.controls['late_limit_date'].value;
      }
    }
    return data;
  }

  async sendNowMail() {
    this.loadingMail = true;
    const res = await this.questionnaireService.sendMail(this.idQuestionnaireApplication, {});
    this.loadingMail = false;
    this.modalConfirmNow.hide();
    if (res.success) {
      this.notifierService.notify('success', 'Correo envíado');
    } else {
      this.notifierService.notify('error', 'No se pudo enviar el correo');
    }
  }

  async sendTemplateMail() {
    this.loadingMail = true;
    const res = await this.questionnaireService.sendMail(this.idQuestionnaireApplication, {subject: this.subjectTemplateMail, tempalte: this.template });
    this.loadingMail = false;
    this.closeTemplateModals();
    if (res.success) {
      this.notifierService.notify('success', 'Correo envíado');
    } else {
      this.notifierService.notify('error', 'No se pudo enviar el correo');
    }
  }

  closeTemplateModals() {
    this.modalConfirmTemplate.hide();
    this.modalSendMailTemplate.hide();
  }

}
