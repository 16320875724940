import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { AreaService } from 'src/app/services/area.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html'
})
export class AreasComponent implements OnInit {

  @Input() idClient;
  showSpinner = false;
  newAreaForm: FormGroup;
  @ViewChild('modalAreaNew') public modalAreaNew: ModalDirective;
  @ViewChild('modalAreaDelete') public modalAreaDelete: ModalDirective;
  deleteArea = {
    name: '',
    id: 0
  };
  loadingDelete = false;
  loadingNew = false;
  areas = [];
  limit = 5;
  offset = 0;
  limitDown = 1;
  limitUp = this.limit;
  count = 0;
  aux_user: any;

  constructor(private areaService: AreaService, private router: Router, private notifierService: NotifierService,
    private authService: AuthService, private errService: ErrorService) {
    this.newAreaForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.aux_user = this.authService.getUser();
    this.getAreas(1);
  }

  async getAreas(type) {
    this.areas = [];
    this.showSpinner = true;
    let res;
    if (this.aux_user.user_type_id === 1) {
      res = await this.areaService.getByClient(this.idClient, this.limit, this.offset);
    } else {
      res = await this.areaService.getByClient(this.aux_user.client_id, this.limit, this.offset);
    }
    this.showSpinner = false;
    if (res.success) {
      this.areas = res.data.areas;
      this.count = res.data.count;
      if (type === 1) {
        this.limitUp = this.limit >= this.count ? this.count : this.limit;
      }
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
  }

  areaDetails(id) {
    if (this.aux_user.user_type_id === 1) {
      this.router.navigate(['/admin/clientes/area/', id]);
    } else {
      this.router.navigate(['/client/areas/', id]);
    }
  }

  openDeleteAreaModal(id, name) {
    this.deleteArea.id = id;
    this.deleteArea.name = `${name}`;
    this.modalAreaDelete.show();
  }

  changeStep() {
    this.offset = 0;
    this.limitDown = 1;
    this.getAreas(1);
  }

  nextPage() {
    this.offset += this.limit;
    this.getAreas(2);
    this.limitDown += this.limit;
    this.limitUp = (this.limitDown + this.limit) <= this.count ? this.limitUp + this.limit : this.count;
  }

  previusPage() {
    this.offset -= this.limit;
    this.getAreas(2);
    this.limitDown -= this.limit;
    this.limitUp = this.offset + this.limit;
  }

  validPrevius() {
    return this.limitDown === 1;
  }

  validNext() {
    return this.limitUp === this.count;
  }

  async removeArea() {
    this.loadingDelete = true;
    const res = await this.areaService.deleteById(this.deleteArea.id);
    if (res.success) {
      this.offset = 0;
      this.getAreas(1);
      this.notifierService.notify('success', 'Área eliminada');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalAreaDelete.hide();
    this.loadingDelete = false;
  }

  showNewAreaModal() {
    this.clearValues();
    this.modalAreaNew.show();
  }

  clearValues() {
    this.newAreaForm.controls.name.setValue('');
  }

  async addArea() {
    this.loadingNew = true;
    const data = {
      name: this.newAreaForm.controls['name'].value,
      client_id: this.idClient
    };
    const res = await this.areaService.post(data);
    if (res.success) {
      this.offset = 0;
      this.getAreas(1);
      this.notifierService.notify('success', 'Área añadida');
    } else {
      this.notifierService.notify('error', this.errService.getError(res.error.code));
    }
    this.modalAreaNew.hide();
    this.loadingNew = false;
  }

}
